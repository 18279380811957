import { once } from "underscore";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { EmployerLogo } from "@js/apps/employer/components/employer-logo";
import { DEFAULT_LISTING_BG_COLOR } from "@js/apps/jobs/constants";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import type { Job } from "@js/types/jobs";

type BookmarkJobSnackbarContentProps = {
  message: string;
  action: JSX.Element;
  job: Pick<Job, "title" | "employer">;
};

const BookmarkJobSnackbarContent = ({
  message,
  action,
  job,
}: BookmarkJobSnackbarContentProps) => {
  const { logo_background_color } = job.employer;

  return (
    <>
      <Box
        sx={{
          bgcolor: logo_background_color
            ? `var(${logo_background_color})`
            : DEFAULT_LISTING_BG_COLOR,
          borderRadius: "50%",
          overflow: "hidden",
          flexShrink: 0,
        }}
      >
        <EmployerLogo
          listingStyle={{ width: "48px", height: "48px" }}
          variant="listing"
          employer={job.employer}
        />
      </Box>
      <Box overflow="hidden">
        <Typography
          component="p"
          variant="label"
          ellipsis
          size="small"
          fontWeight={400}
        >
          {message}
        </Typography>
        <Typography component="p" ellipsis className="capitalize-first-letter">
          {job.title}
        </Typography>
      </Box>
      <Box marginLeft="auto">{action}</Box>
    </>
  );
};

export const showSnackbarOnSaveBookmark = (
  job: Pick<Job, "title" | "employer">,
) => {
  Snackbar.black(
    <BookmarkJobSnackbarContent
      job={job}
      message="Job saved!"
      action={
        <Button
          variant="tertiary"
          href="/bookmarks/jobs"
          inverse
          RouterLink={RouterLink}
        >
          Saved Jobs
        </Button>
      }
    />,
  );
};

export const showSnackbarOnRemoveBookmark = (
  job: Pick<Job, "title" | "employer" | "id">,
  bookmarkJob: (jobId: number) => void,
) => {
  Snackbar.black(
    <BookmarkJobSnackbarContent
      job={job}
      message="Removed from saved jobs"
      action={
        <Button
          variant="tertiary"
          inverse
          onClick={once(() => {
            bookmarkJob(job.id);
          })}
        >
          Undo
        </Button>
      }
    />,
  );
};
