import { ModalInstance } from "@js/components/modal";

import { ApplicationOnrampModal, type ApplicationOnrampModalProps } from ".";

type OpenApplicationOnrampModalArgs = ApplicationOnrampModalProps;

export const openApplicationOnrampModal = ({
  onSuccessPurchaseBtrst,
}: OpenApplicationOnrampModalArgs) => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    children: (
      <ApplicationOnrampModal onSuccessPurchaseBtrst={onSuccessPurchaseBtrst} />
    ),
  });
};
