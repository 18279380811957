import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useNavigate } from "@js/hooks";
import { useLatestCallback } from "@js/hooks/use-latest-callback";
import { LocalStorage } from "@js/services";

type UseLoadSessionSavedFiltersOnMountParams = {
  isAnyFilterApplied?: boolean;
  filtersStorageKey?: string;
  processSearchParams?: (params: URLSearchParams) => URLSearchParams;
};

const defaultProcessSearchParams = (params: URLSearchParams) => params;

export const useLoadSessionSavedFiltersOnMount = ({
  isAnyFilterApplied,
  filtersStorageKey = LocalStorage.keys.JOB_LISTING_FILTERS,
  processSearchParams = defaultProcessSearchParams,
}: UseLoadSessionSavedFiltersOnMountParams) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [isReadyToFetchListing, setIsReadyToFetchListing] = useState(
    Boolean(isAnyFilterApplied),
  );

  const processSearchParamsCallback = useLatestCallback(processSearchParams);
  useEffect(() => {
    const savedFilters = LocalStorage.getItem(filtersStorageKey);
    if (
      !savedFilters ||
      isAnyFilterApplied ||
      savedFilters === params.toString()
    ) {
      setIsReadyToFetchListing(true);
      return;
    }

    const savedFiltersParams = new URLSearchParams(savedFilters);
    const processedParams = processSearchParamsCallback(savedFiltersParams);
    navigate(
      { search: processedParams.toString() },
      {
        replace: true,
        state: { disableRemountIfTheSameLocation: true, noScroll: true },
      },
    );

    setIsReadyToFetchListing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isReadyToFetchListing };
};
