import { Box, Button } from "@braintrust/braintrust-ui-components";
import { LogOutIcon } from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { Logo } from "@js/components/logo";

export const OnboardingPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: {
            xs: 2,
            sm: 4,
            md: "calc(35vh - 12rem)",
          },
        }}
      >
        <Box marginLeft="20px" marginTop="20px">
          <Logo alt="Braintrust" />
        </Box>
        <Box marginRight="4px" marginTop="13px">
          <Button
            RouterLink={RouterLink}
            to="/auth/logout/"
            variant="transparent"
            fontWeight={400}
            endIcon={<LogOutIcon style={{ fontSize: 24 }} />}
          >
            Logout
          </Button>
        </Box>
      </Box>
      <Box margin="auto" maxWidth="712px" padding={3}>
        {children}
      </Box>
    </Box>
  );
};
