import React from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

type CantManageMessageProps = {
  title: string;
  to: string;
};

export const CantManageMessage = ({ title, to }: CantManageMessageProps) => {
  return (
    <div className="cant-manage-message" data-testid="cant-manage-message">
      <Typography mb={4} component="h2">
        {title}
      </Typography>
      <div>
        <Button variant="primary" to={to} RouterLink={RouterLink}>
          Go to job details page
        </Button>
      </div>
    </div>
  );
};
