import { useState } from "react";

import { TokenFeatureManagementFeatureFlagWrapper } from "@js/apps/jobs/components/paid-management-feature-flag-wrapper";

import { SettingsLayout } from "../layout";

import { BillingSection } from "./billing/billing";
import { UpgradeSection } from "./upgrades/upgrades";

const PurchasePage = (): JSX.Element => {
  return (
    <SettingsLayout pageTitle="Payments">
      <TokenFeatureManagementFeatureFlagWrapper>
        <FreelancerContent />
      </TokenFeatureManagementFeatureFlagWrapper>
    </SettingsLayout>
  );
};

const FreelancerContent = (): JSX.Element => {
  const [key, setKey] = useState(1);
  return (
    <>
      <UpgradeSection resetBillingHistory={() => setKey((prev) => prev + 1)} />
      <BillingSection key={key} />
    </>
  );
};

export default PurchasePage;
