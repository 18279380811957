import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import type { BoxProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Button,
  Tooltip,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { ArrowTopRightIcon } from "@braintrust/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type PurchaseSummaryProps = {
  isLoadingCoinbase: boolean;
  isWalletReady: boolean;
  isPurchasing: boolean;
  onPurchase: () => void;
  requiredTotalBtrst: number;
  requiredTotalBtrstRaw: string;
  upgradeTypeCost: number;
};

export const PurchaseSummary = ({
  isLoadingCoinbase,
  isWalletReady,
  isPurchasing,
  onPurchase,
  requiredTotalBtrst,
  requiredTotalBtrstRaw,
  upgradeTypeCost,
}: PurchaseSummaryProps) => {
  const formattedRequiredBtrst =
    requiredTotalBtrst < 0 ? requiredTotalBtrst * -1 : requiredTotalBtrst;

  const breakDownItemProps: BoxProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };

  const isTablet = useMediaQuery("lg");
  return (
    <Box
      py={isTablet ? 0 : 4}
      flex={isTablet ? 0 : 1}
      display={isTablet ? "initial" : "flex"}
      justifyContent={isTablet ? "initial" : "center"}
      alignItems={isTablet ? "initial" : "center"}
    >
      <Box
        display="flex"
        gap={isTablet ? 2 : 3}
        maxWidth={isTablet ? "initial" : "361px"}
        flexDirection="column"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "var(--medium-blue)",
            borderRadius: 2,
            height: "200px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "222px",
              height: "200px",
              position: "absolute",
              left: 0,
              background: `url(${`${SETTINGS.STATIC_URL}jobs/usd-to-btrst-bg.svg`})`,
            }}
          />
          <Box zIndex={1} display="flex">
            <img
              src={`${SETTINGS.STATIC_URL}jobs/usd-to-btrst.svg`}
              width="auto"
              height="144px"
              alt="usd-to-btrst"
            />
          </Box>
        </Box>
        <Typography component="h3" size="large">
          Purchase BTRST to unlock Application Insights
        </Typography>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap={1}
          borderTop="1px solid var(--soft-beige)"
          borderBottom="1px solid var(--soft-beige)"
          paddingY={1}
        >
          <Box {...breakDownItemProps}>
            <Typography component="p" variant="paragraph" size="medium">
              30 days of Application Insights
            </Typography>
            <Typography component="p" variant="paragraph" size="medium">
              {upgradeTypeCost} BTRST
            </Typography>
          </Box>
          <Box {...breakDownItemProps}>
            <Typography
              component="p"
              variant="label"
              size="medium"
              fontWeight={500}
            >
              Total you need to buy
            </Typography>
            <Typography
              component="p"
              variant="label"
              size="medium"
              fontWeight={500}
            >
              <Tooltip title={requiredTotalBtrstRaw}>
                <span>{formattedRequiredBtrst}</span>
              </Tooltip>{" "}
              BTRST
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{ backgroundColor: "var(--soft-blue)" }}
          borderRadius={2}
          padding={2}
        >
          <Typography component="p" variant="paragraph" size="small">
            We'll notify you when your BTRST {requiredTotalBtrst ? "are" : "is"}{" "}
            available. This could take up to 3 hours
          </Typography>
        </Box>
        <Box
          sx={{
            "& a": {
              color: "var(--link)",
              textDecoration: "none",
            },
          }}
        >
          <Typography component="p" variant="paragraph" size="small">
            By confirming payment you accept our{" "}
            <Typography
              component="a"
              size="small"
              href={SETTINGS.PRIVACY_DOC_URL}
              target="_blank"
              className="policy-field-link"
              rel="noreferrer"
              RouterLink={RouterLink}
            >
              Privacy Policy
            </Typography>
            , and{" "}
            <Typography
              component="a"
              size="small"
              href={SETTINGS.TERMS_DOC_URL}
              target="_blank"
              className="policy-field-link"
              rel="noreferrer"
              RouterLink={RouterLink}
            >
              Payments Terms & Conditions
            </Typography>
          </Typography>
        </Box>

        <Tooltip
          title="This action is currently unavailable. We are in the process of creating a wallet for you, which should be ready in a few minutes."
          placement="top"
          disabled={isWalletReady}
        >
          <div>
            <Button
              disabled={isPurchasing || isLoadingCoinbase || !isWalletReady}
              variant="positive-2"
              shape="squared"
              sx={{ padding: "15px 0 !important", width: "100%" }}
              onClick={onPurchase}
              endIcon={<ArrowTopRightIcon />}
            >
              Get started with Coinbase
            </Button>
          </div>
        </Tooltip>

        <Box textAlign="center">
          <PoweredByCoinbase />
        </Box>
      </Box>
    </Box>
  );
};
