import { ModalInstance } from "@js/components/modal";

import { ProductCheckoutModal } from "./product-checkout-modal";
import { ProductCheckoutModalContextProvider } from "./product-checkout-modal-context";

import styles from "./styles.module.scss";

type OpenProductCheckoutModalProps = {
  onCloseSuccessPurchaseModal?: () => void;
  onClickBack: () => void;
};

export const openProductCheckoutModal = ({
  onClickBack,
  onCloseSuccessPurchaseModal,
}: OpenProductCheckoutModalProps) => {
  ModalInstance.open({
    padding: false,
    containerScrollableNoMobilePadding: false,
    className: styles.productCheckoutModal,
    children: (
      <ProductCheckoutModalContextProvider
        ModalInstance={ModalInstance}
        onClickBack={onClickBack}
        onCloseSuccessPurchaseModal={onCloseSuccessPurchaseModal}
      >
        <ProductCheckoutModal />
      </ProductCheckoutModalContextProvider>
    ),
  });
};
