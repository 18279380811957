import { useLocation } from "react-router-dom";

import { Button, Stack } from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import { CannotMakeAction } from "@js/apps/jobs/components/cannot-make-action";
import { RouterLink } from "@js/components/link";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { AiInterviewButton, InterviewButton } from "../interview-button";
import type { MatchSummaryUserWithBadgeProps } from "../match-summary";
import {
  MatchSummaryList,
  MatchSummaryListContainer,
  MatchSummaryUserWithBadge,
} from "../match-summary";

export type BidMatchSummaryProps = {
  bid: EmployerBidListBid;
  job: Job;
  closeSummary: () => void;
  userWithBadgeContainerProps?: MatchSummaryUserWithBadgeProps["containerProps"];
};

export const BidMatchSummary = ({
  bid,
  job,
  closeSummary,
  userWithBadgeContainerProps,
}: BidMatchSummaryProps) => {
  const isMobileOrTablet = useMediaQuery("md");
  const { search } = useLocation();
  const linkToSidePanel = `/jobs/${job.id}/proposals/${bid.id}${search}`;
  const showInterviewButton = statusesToShowInterview.includes(bid.status);

  return (
    <Stack sx={{ height: "100%", overflow: "hidden" }}>
      <MatchSummaryUserWithBadge
        matchLabel={bid.match_label}
        freelancerUser={bid.freelancer.user}
        containerProps={userWithBadgeContainerProps}
      />
      <MatchSummaryListContainer>
        <MatchSummaryList matchSummary={bid.match_summary} />
      </MatchSummaryListContainer>
      <Stack
        direction="row"
        sx={{
          gap: 1,
          justifyContent: { xs: "end", md: "start" },
          flexWrap: "wrap",
        }}
      >
        {showInterviewButton && (
          <CannotMakeAction job={job}>
            {job.is_ai_interview_enabled ? (
              <AiInterviewButton
                bid={bid}
                job={job}
                size={isMobileOrTablet ? "small" : "x-small"}
                onOptionClick={closeSummary}
                sx={{ px: "14px !important" }}
              />
            ) : (
              <InterviewButton
                bid={bid}
                job={job}
                size={isMobileOrTablet ? "small" : "x-small"}
                onOptionClick={closeSummary}
              />
            )}
          </CannotMakeAction>
        )}
        <Button
          shape="squared"
          variant="white-border-beige"
          to={linkToSidePanel}
          size={isMobileOrTablet ? "small" : "x-small"}
          state={{ noScroll: true }}
          onClick={closeSummary}
          sx={{ textWrap: "nowrap" }}
          RouterLink={RouterLink}
        >
          View application
        </Button>
      </Stack>
    </Stack>
  );
};

const statusesToShowInterview: Array<EnumType<typeof ENUMS.BidStatus>> = [
  ENUMS.BidStatus.NEW,
  ENUMS.BidStatus.VIEWED,
];
