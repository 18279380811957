import type { TalentFiltersFetchParams } from "@js/apps/common/components/filters/config";

const forbiddenParams: Array<keyof TalentFiltersFetchParams> = [
  "job",
  "invite_to_job",
];

export const processSavedFilters = (searchParams: URLSearchParams) => {
  const searchParamsCopy = new URLSearchParams(searchParams);

  for (const forbiddenParam of forbiddenParams) {
    searchParamsCopy.delete(forbiddenParam);
  }
  return searchParamsCopy;
};
