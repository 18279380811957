import "@js/utils/polyfills";

import DOMPurify from "dompurify";
import { marked } from "marked";
import _ from "underscore";

import type { CamelToKebab } from "@js/utils";

import { validateStyles } from "./utils";

export const IMPLICIT_ANCHOR_WARNING_ATTR = "data-warning";

export type HyphenatedCSSStyleProperties = {
  [K in keyof CSSStyleDeclaration as CamelToKebab<K & string>]: string;
};

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", (domPurifyNode) => {
  const node = domPurifyNode as HTMLElement;
  if ("target" in node || "href" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }

  if (node?.tagName === "A") {
    node.setAttribute(IMPLICIT_ANCHOR_WARNING_ATTR, "true");
    return node;
  }
});

// Add a hook to sanitize css styles
DOMPurify.addHook(
  "afterSanitizeAttributes",
  (domPurifyNode, _hookEvent, config: SanitizeConfig) => {
    const node = domPurifyNode as HTMLElement;
    if (node.hasAttribute("style")) {
      const output = validateStyles(
        (node as HTMLElement).style,
        config.ALLOWED_STYLES,
      );

      // re-add styles in case any are left
      if (output.length) {
        node.setAttribute("style", output.join(""));
      } else {
        node.removeAttribute("style");
      }
    }
  },
);

export type SanitizeConfig = {
  ALLOWED_ATTR?: string[];
  ALLOWED_STYLES?: Array<keyof Partial<HyphenatedCSSStyleProperties>>;
  ALLOWED_TAGS?: string[];
};

const DEFAULT_DOM_SANITIZE_CONFIG: SanitizeConfig = {
  // value and class are mostly used by lists and list items in the editor
  ALLOWED_ATTR: ["href", "style", "class", "value"],
  ALLOWED_STYLES: [
    "padding-left",
    "font-size",
    "text-decoration",
    "text-align",
    "font-family",
    "text-indent",
    "display",
  ],
  ALLOWED_TAGS: [
    "a",
    "p",
    "ul",
    "ol",
    "li",
    "strong",
    "em",
    "i",
    "br",
    "hr",
    "span",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "blockquote",
    "pre",
    "code",
    "div",
    "b",
    "u",
  ],
};

export const sanitize = (
  content: string,
  config: SanitizeConfig = DEFAULT_DOM_SANITIZE_CONFIG,
): string => {
  return DOMPurify.sanitize(content, config);
};

export const unescape = (content: string) => {
  return _.unescape(content);
};

type PrepareDOMInputConfig = {
  markdown?: boolean;
  unescape?: boolean;
  sanitize?: SanitizeConfig;
};

export type prepareDOMInputFun = (
  content: string,
  config?: PrepareDOMInputConfig,
) => string;
export const prepareDOMInput: prepareDOMInputFun = (content, config = {}) => {
  let preparedContent = content;
  const {
    markdown: _markdown = true,
    unescape: _unescape = false,
    sanitize: _sanitize,
  } = config;

  if (_unescape) {
    preparedContent = unescape(preparedContent);
  }

  if (_markdown) {
    preparedContent = marked(preparedContent) as string;
  }

  return sanitize(preparedContent, _sanitize);
};
