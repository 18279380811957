import {
  Box,
  Button,
  IconButton,
  Menu,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { MoreVertIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { insightsBannerClicked } from "@js/apps/jobs/actions";
import { INSIGHTS_DESCRIPTION } from "@js/apps/jobs/components/application-onramp-modal/constants";
import {
  Section,
  SectionSubtitleMiddleAction,
} from "@js/apps/settings/forms/helpers";
import { useAppDispatch } from "@js/hooks";
import { usePurchaseUpgrade } from "@js/hooks/purchase-upgrade";
import type { UpgradePurchaseStatus } from "@js/types/auth";

export const BOOST_DESCRIPTION =
  "Get to the top of the applicant list to increase your chances of getting hired.";

export const UpgradeSection = ({
  resetBillingHistory,
}: {
  resetBillingHistory: () => void;
}) => {
  const { onClickPurchaseUpgrade: handleInsightPurchase } =
    usePurchaseUpgrade();
  const user = useUser();
  const dispatch = useAppDispatch();

  const handleColorOnStatus = (status: string) => {
    if (status === "ACTIVE") {
      return "#447513";
    } else if (status === "WARNING") {
      return "var(--dark-orange)";
    } else if (status === "EXPIRED") {
      return "var(--negative-2)";
    } else {
      return "#676460";
    }
  };

  const handleBgOnStatus = (status: string) => {
    if (status === "ACTIVE") {
      return "var(--soft-green)";
    } else if (status === "WARNING") {
      return "var(--soft-orange)";
    } else if (status === "EXPIRED") {
      return "var(--soft-red)";
    } else {
      return "var(--soft-grey)";
    }
  };

  const availableBoosts = user?.boost_credit ?? 0;
  const hasBoosts = !!availableBoosts;

  const statusBox = (type: keyof UpgradePurchaseStatus) => {
    const data = user?.upgrade_purchase_status?.[type];
    const status: string = data?.status ? data?.status : "";
    const color = handleColorOnStatus(status);
    const bg = handleBgOnStatus(status);

    const statusText = () => {
      if (
        type === "boost" &&
        data?.usage === "No boosts available" &&
        status !== ""
      ) {
        return "0/3 boosts available";
      } else if (type === "insights" && status === "EXPIRED") {
        return "0/30 days remaining";
      } else return data?.usage;
    };
    return (
      <Box bgcolor={bg} px={2} borderRadius={10} py={0.5}>
        <Typography
          component="p"
          sx={{ color: color }}
          size="medium"
          fontWeight={500}
        >
          {statusText()}
        </Typography>
      </Box>
    );
  };

  const rePurchaseHandler = async () => {
    dispatch(insightsBannerClicked());
    handleInsightPurchase({
      onSuccessPurchaseBoost: resetBillingHistory,
      onCloseSuccessPurchaseModal: resetBillingHistory,
    });
  };

  const insightsActionButton = () => {
    return (
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchor={
          <IconButton
            aria-label={`Open insights upgrades menu`}
            aria-controls={`job-action-menu-old`}
            aria-haspopup="true"
            variant="transparent"
            size="small"
            sx={{ padding: "0px !important" }}
          >
            <Box
              bgcolor={"var(--off-white)"}
              borderRadius={"100%"}
              px={1}
              py={0.8}
            >
              <MoreVertIcon />
            </Box>
          </IconButton>
        }
      >
        <Menu.Item
          onClick={() => rePurchaseHandler()}
          key={"label"}
          tooltipText={"reason"}
        >
          Purchase additional days
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      <Section title="Upgrades">
        <SectionSubtitleMiddleAction
          title={"Application Insights"}
          description={INSIGHTS_DESCRIPTION}
          action={
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ minHeight: "41px" }}
            >
              <Box> {statusBox("insights")} </Box>
              <Box padding={"0px 10px"}>{insightsActionButton()}</Box>
            </Box>
          }
          {...(user?.upgrade_purchase_status?.insights?.status !== "ACTIVE" && {
            endButton: (
              <Button
                onClick={() => rePurchaseHandler()}
                shape="squared"
                sx={{ minWidth: "18px !important" }}
                variant={"primary"}
              >
                Purchase Application Insights
              </Button>
            ),
          })}
          divider={hasBoosts}
        />

        {hasBoosts && (
          <SectionSubtitleMiddleAction
            title={"Application Boost"}
            description={BOOST_DESCRIPTION}
            action={
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ minHeight: "41px" }}
              >
                <Box>{statusBox("boost")}</Box>
              </Box>
            }
          />
        )}
      </Section>
    </>
  );
};
