import { useEffect } from "react";
import { type UseFormReturn, useWatch } from "react-hook-form";

import {
  Box,
  Button,
  GraterOrEqualZeroIntegerNumberFormat,
  Grid,
  Loader,
} from "@braintrust/braintrust-ui-components";
import { CommonConfirmationModal } from "@js/components/modal";
import {
  RhfCheckboxField,
  RhfNumberField,
  RhfSelect,
  RhfTextareaField,
  RhfTextField,
} from "@js/rhf/fields";
import { RhfCompaniesField } from "@js/rhf/fields/autocomplete/companies/companies";
import { getMonths } from "@js/utils";

import { ADD_ANOTHER_ITEM, INITIAL_WORK_HISTORY_VALUES } from "../../constants";
import type { WorkHistoryFormData } from "../../hooks/edit-work-history";

type WorkHistoryFormProps = {
  onSubmit: (data: WorkHistoryFormData) => void;
  form: UseFormReturn<WorkHistoryFormData>;
  editMode?: boolean;
  setSubmitType?: React.Dispatch<React.SetStateAction<string | null>>;
  saveButtonText?: string;
  isSaving?: boolean;
};

export const WorkHistoryForm = ({
  onSubmit,
  form,
  editMode = false,
  setSubmitType,
  saveButtonText = "Save",
  isSaving,
}: WorkHistoryFormProps) => {
  const months = getMonths().map((month, i) => ({
    value: i + 1,
    label: month,
  }));

  const { control, handleSubmit, formState, reset, resetField, getValues } =
    form;

  const isCurrentlyWorking = useWatch({
    control,
    name: "is_currently_working",
  });

  const showActionButton = formState.isDirty || !!getValues();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(INITIAL_WORK_HISTORY_VALUES);
    }
  }, [formState, reset]);

  useEffect(() => {
    if (isCurrentlyWorking) {
      resetField("month_to", {
        defaultValue: null,
      });
      resetField("year_to", {
        defaultValue: null,
      });
    }
  }, [isCurrentlyWorking, resetField]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <RhfTextField
            variant="outlined"
            id="title"
            name="title"
            label="Title*"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RhfCompaniesField
            id="new_company"
            name="new_company"
            label="Company*"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <RhfCheckboxField
            id="is_currently_working"
            name="is_currently_working"
            label="I currently work here"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <RhfSelect
                options={months}
                id="month_from"
                name="month_from"
                placeholder="Month"
                label="Start date*"
                control={control}
              />
            </Grid>
            <Grid item sm={6} xs={12} marginTop="34px">
              <RhfNumberField
                NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
                id="year_from"
                name="year_from"
                placeholder="Year"
                control={control}
              />
            </Grid>
            {!isCurrentlyWorking && (
              <>
                <Grid item sm={6} xs={12}>
                  <RhfSelect
                    id="month_to"
                    name="month_to"
                    placeholder="Month"
                    label="End date*"
                    control={control}
                    options={months}
                  />
                </Grid>
                <Grid item sm={6} xs={12} mt="34px">
                  <RhfNumberField
                    NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
                    id="year_to"
                    name="year_to"
                    placeholder="Year"
                    control={control}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} mt={3}>
            <RhfTextareaField
              id="description"
              name="description"
              maxLength={10000}
              label="Description*"
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      {!editMode && showActionButton && (
        <Box mt={3}>
          <Button
            variant="tertiary"
            onClick={() => setSubmitType && setSubmitType(ADD_ANOTHER_ITEM)}
            type="submit"
            disabled={isSaving}
          >
            Save and add another
          </Button>
        </Box>
      )}
      <Box mt={4} gap={2} display="flex" justifyContent="flex-end">
        {editMode && (
          <Button
            variant="black-outlined"
            shape="squared"
            onClick={() => {
              if (setSubmitType) {
                setSubmitType(null);
              }

              CommonConfirmationModal.close();
            }}
            type="submit"
          >
            Discard changes
          </Button>
        )}
        {showActionButton && (
          <Button
            variant="positive"
            shape="squared"
            type="submit"
            disabled={isSaving}
            onClick={() => {
              if (setSubmitType) {
                setSubmitType(null);
              }
            }}
            endIcon={isSaving ? <Loader size={20} /> : undefined}
          >
            {editMode ? "Save changes" : saveButtonText || "Save"}
          </Button>
        )}
      </Box>
    </form>
  );
};
