import type { HelpOffer } from "@js/types/give-and-get-help";
import type { EmployerOffer, InitialOffer } from "@js/types/jobs";

type FailureCallbackType = () => string;

export const LocalStorage = {
  setItem: (key: string, value: string): void => {
    try {
      localStorage.setItem(key, value);
    } catch (_error) {
      // ignore
    }
  },
  getItem: (
    key: string,
    failureCallback?: FailureCallbackType,
  ): string | null | ReturnType<FailureCallbackType> => {
    try {
      return localStorage.getItem(key);
    } catch (_error) {
      // ignore
    }

    if (failureCallback) {
      return failureCallback();
    }

    return null;
  },
  removeItem: (key: string): void => {
    try {
      localStorage.removeItem(key);
    } catch (_error) {
      // ignore
    }
  },
  clear: (): void => {
    try {
      localStorage.clear();
    } catch (_error) {
      // ignore
    }
  },

  keys: {
    PRESELECTED_TALENT_ROLE_LOCAL_STORAGE_KEY: "preselected_talent_role",
    REGISTRATION_EMAIL_LOCAL_STORAGE_KEY: "registration_email",
    rejectOffer: (bidId: number) => `reject-offer__bid-${bidId}`,
    createOffer: (bidId: number) => `create-offer__bid-${bidId}`,
    JOB_LISTING_FILTERS: "JOB_LISTING_FILTERS",
    JOBS_SEARCH_FILTERS: "JOBS_SEARCH_FILTERS",
    JOB_LANDING_FILTERS: "JOB_LANDING_FILTERS",
    TALENT_LISTING_FILTERS: "TALENT_LISTING_FILTERS",
    TALENT_SEARCH_FILTERS: "TALENT_SEARCH_FILTERS",
    ONBOARDING_SAVED_DATA: "onboarding_saved_data",
    SIGN_UP_REFERRER: "referrer",
    HELP_OFFER_REFUND_REQUESTED: "HELP_OFFER_REFUND_REQUESTED",
    HELP_OFFER_REPORTED: "HELP_OFFER_REPORTED",
    HIDDEN_ATS_INTEGRATION_ISSUES: "HIDDEN_ATS_INTEGRATION_ISSUES",
    PROFILE_NUDGE_DIMISS_TIMER: "PROFILE_NUDGE_DIMISS_TIMER",
    ONBOARDING_ORGANIZATION_FORM: "ONBOARDING_ORGANIZATION_FORM",
    LOGGED_IN: "LOGGED_IN",
    VIEWED_PUBLIC_JOBS: "VIEWED_PUBLIC_JOBS",
    MY_TALENT_TABLE_COLUMNS_VISIBILITY: "MY_TALENT_TABLE_COLUMNS_VISIBILITY",
    APPLICATION_INSIGHT_JOB_LIST_BANNER_CLOSE_TIMESTAMP:
      "APPLICATION_INSIGHT_JOB_BANNER_CLOSE_TIMESTAMP",
    APPLICATION_INSIGHT_HOMEPAGE_NEWEST_MATCH_CARD_CLOSE_TIMESTAMP:
      "APPLICATION_INSIGHT_HOMEPAGE_NEWEST_MATCH_CARD_CLOSE_TIMESTAMP",
    APPLICATION_INSIGHT_HOMEPAGE_APPLIED_JOBS_CARD_CLOSE_TIMESTAMP:
      "APPLICATION_INSIGHT_HOMEPAGE_APPLIED_JOBS_CARD_CLOSE_TIMESTAMP",
    EMPLOYER_INVOICES_CUSTOMIZED_COLUMNS:
      "EMPLOYER_INVOICES_CUSTOMIZED_COLUMNS",
    USER_IN_OTHER_TABS_CHANGED_TIMESTAMP:
      "USER_IN_OTHER_TABS_CHANGED_TIMESTAMP",
  },
};

export const getHelpOfferLocalStorageKey = (offer: HelpOffer) => {
  if (offer.reported) {
    return LocalStorage.keys.HELP_OFFER_REPORTED;
  }

  if (offer.refund_requested && !offer.refund_issued) {
    return LocalStorage.keys.HELP_OFFER_REFUND_REQUESTED;
  }

  return `HELP_OFFER_${offer.status}`;
};

export const getMakeOfferMessage = (
  offer: InitialOffer | EmployerOffer | number,
) => {
  if (typeof offer === "number") {
    return LocalStorage.getItem(LocalStorage.keys.createOffer(offer));
  }

  if (!offer.bid?.id) {
    return "";
  }

  return LocalStorage.getItem(LocalStorage.keys.createOffer(offer.bid?.id));
};

export const deleteMakeOfferMessage = (bidId: number) => {
  LocalStorage.removeItem(LocalStorage.keys.createOffer(bidId));
};

export const saveMakeOfferMessage = (bidId: number, message: string) => {
  LocalStorage.setItem(LocalStorage.keys.createOffer(bidId), message);
};

export const getRejectOfferMessage = (
  offer: InitialOffer | EmployerOffer | number | undefined,
) => {
  if (typeof offer === "number") {
    return LocalStorage.getItem(LocalStorage.keys.rejectOffer(offer));
  }

  if (!offer?.bid?.id) {
    return "";
  }

  return LocalStorage.getItem(LocalStorage.keys.rejectOffer(offer.bid?.id));
};

export const deleteRejectOfferMessage = (bidId: number) => {
  LocalStorage.removeItem(LocalStorage.keys.rejectOffer(bidId));
};

export const saveRejectOfferMessage = (bidId: number, message: string) => {
  LocalStorage.setItem(LocalStorage.keys.rejectOffer(bidId), message);
};

export const clearLocalStorageDataRelatedToJobAndTalentFilters = () => {
  LocalStorage.removeItem(LocalStorage.keys.JOB_LISTING_FILTERS);
  LocalStorage.removeItem(LocalStorage.keys.JOB_LANDING_FILTERS);
  LocalStorage.removeItem(LocalStorage.keys.TALENT_LISTING_FILTERS);
};
