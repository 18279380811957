if (!Array.prototype.at) {
  Array.prototype.at = function (index: number) {
    // eslint-disable-next-line eqeqeq
    if (this == null) {
      throw new TypeError("Array.prototype.at called on null or undefined");
    }

    const len = this.length;
    let relativeIndex = Number(index);

    if (Number.isNaN(relativeIndex)) {
      relativeIndex = 0;
    }

    if (relativeIndex < 0) {
      relativeIndex += len;
    }

    if (relativeIndex < 0 || relativeIndex >= len) {
      return undefined;
    }

    return this[relativeIndex];
  };
}

export default {};
