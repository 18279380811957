import React from "react";
import cs from "classnames";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import {
  Button,
  Tooltip,
  Typography,
  VerticalSeparator,
} from "@braintrust/braintrust-ui-components";
import { BraintrustIcon } from "@braintrust/braintrust-ui-components/Icons";
import { ReferGrantJobModalContent } from "@js/apps/common/components/refer-modal-content";
import { useGetExpandedJobDetails } from "@js/apps/jobs/hooks/use-get-expanded-job-details";
import type { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import type { Job, MainJobListingJob } from "@js/types/jobs";

import type { ReferJobModalContentProps } from "../../refer-job-modal-content";
import { ReferJobModalContent } from "../../refer-job-modal-content";
import { LearMoreAboutTokenButton } from "../learn-more-button";

import styles from "./styles.module.scss";

type ReferToJobButtonProps = {
  job: Job | MainJobListingJob;
  className?: string;
  component?: typeof RouterLink | typeof Button;
  variant?: ButtonProps["variant"];
  children?: React.ReactNode;
  style?: Partial<CSSStyleDeclaration>;
  inverse?: boolean;
  size?: ButtonProps["size"];
  showDivider?: boolean;
  tokenReward?: string;
  isEmbeddedView?: boolean;
};

export const ReferToJobButton = ({
  job,
  className,
  component = Button,
  variant = "primary",
  inverse,
  children = "Refer & Earn",
  style,
  size,
  showDivider = false,
  isEmbeddedView,
}: ReferToJobButtonProps): JSX.Element | null => {
  const { jobDetails } = useGetExpandedJobDetails(job.id);
  const Component: React.ElementType | undefined = component;

  if (!Component) return null;

  if (job.job_type === ENUMS.JobType.GRANT) {
    return (
      <Component
        style={style}
        variant={variant}
        inverse={inverse}
        size={size}
        className={cs("refer-to-job-button", className)}
        onClick={() => openReferJobModal({ job, isEmbedded: isEmbeddedView })}
      >
        Share Job
      </Component>
    );
  }

  return (
    <Tooltip
      placement="top"
      title={
        <>
          <Typography variant="title" size="small" component="h5" mb={1}>
            Share to Earn BTRST
          </Typography>
          {tooltipExplanationText}
        </>
      }
    >
      <span>
        <Component
          style={style}
          variant={variant}
          inverse={inverse}
          size={size}
          className={cs(styles.referToJobButtonLink, className)}
          onClick={() =>
            openReferJobModal({
              job,
              isEmbedded: isEmbeddedView,
            })
          }
        >
          {children}
          {showDivider && <VerticalSeparator color="orange" />}
          <BraintrustIcon
            style={{
              margin: "0px 5px",
              width: 16,
              height: 16,
              display: "inline-block",
              verticalAlign: "text-top",
            }}
          />
          <span style={{ fontSize: 14, fontWeight: 600 }}>
            +{jobDetails?.token_reward}
          </span>
        </Component>
      </span>
    </Tooltip>
  );
};

export const openReferJobModal = ({
  job,
  isEmbedded,
}: {
  job: ReferJobModalContentProps["job"];
  isEmbedded?: boolean;
}) => {
  return ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
    },
    className: styles.limitWidth,
    padding: false,
    backdropInvisible: isEmbedded,
    children:
      "job_type" in job && job.job_type === ENUMS.JobType.GRANT ? (
        <ReferGrantJobModalContent
          hideImage
          titleSize="medium"
          descriptionProps={{
            size: "medium",
          }}
          type="job"
          jobId={job.id}
        />
      ) : (
        <ReferJobModalContent job={job} />
      ),
  });
};

const tooltipExplanationText = (
  <>
    <p style={{ margin: "0 0 10px" }}>
      Amount shown is the estimated reward for this job. When talent signs up
      through your referral link, you will receive 1% of the amount invoiced by
      hired talent up to $10k per hire in BTRSTs.
    </p>

    <LearMoreAboutTokenButton />
  </>
);
