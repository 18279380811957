import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { skipToken } from "@reduxjs/toolkit/query";
import * as z from "zod";

import { useUser } from "@js/apps/common/hooks";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import { MAX_MONTH, MAX_YEAR, MIN_YEAR } from "@js/forms/validators";
import { useAppDispatch } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import { sanitize } from "@js/services";
import type { Company, FreelancerWorkExperience } from "@js/types/freelancer";
import { deepClone, getMonthName, typeGuard } from "@js/utils";

import {
  workExperienceAdded,
  workExperienceDeleted,
  workExperienceEdited,
} from "../../actions";
import {
  useCreateCompaniesBulkMutation,
  useCreateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
  useGetFreelancerPublicProfileQuery,
  useUpdateWorkExperienceMutation,
} from "../../api";
import { ADD_ANOTHER_ITEM, INITIAL_WORK_HISTORY_VALUES } from "../../constants";
import type { CreateWorkExperienceParams } from "../../types";

const workHistorySchema = z
  .object({
    id: z.number().optional(),
    title: z.string({
      required_error: "This field is required.",
    }),
    new_company: z.string({
      required_error: "This field is required.",
    }),
    is_currently_working: z.boolean().optional(),
    month_from: z.number({
      required_error: "This field is required.",
    }),
    year_from: z
      .string({
        required_error: "This field is required.",
      })
      .regex(/^\d{4}$/, "Year must be in YYYY format")
      .refine(
        (year) => {
          const numericYear = Number(year);
          return numericYear >= MIN_YEAR && numericYear <= MAX_YEAR;
        },
        { message: "Year must be a valid year not in the future" },
      ),
    month_to: z.number().nullable().optional(),
    year_to: z
      .string()
      .regex(/^\d{4}$/, "Year must be in YYYY format")
      .nullable()
      .optional()
      .refine(
        (year) => {
          if (!year) return true;
          const numericYear = Number(year);
          return numericYear >= MIN_YEAR && numericYear <= MAX_YEAR;
        },
        { message: "Year must be a valid year not in the future" },
      ),
    description: z
      .string({
        required_error: "This field is required.",
      })
      .max(10000, { message: "Description cannot exceed 10,000 characters" }),
  })
  .superRefine((data, ctx) => {
    if (data.is_currently_working) {
      return;
    }

    if (!data.year_to) {
      ctx.addIssue({
        code: "custom",
        path: ["year_to"],
        message: "This field is required.",
      });
    }

    if (!data.month_to) {
      ctx.addIssue({
        code: "custom",
        path: ["month_to"],
        message: `This field is required.`,
      });
    }

    if (
      data.month_to &&
      data.year_to &&
      Number(data.year_to) === MAX_YEAR &&
      data.month_to > MAX_MONTH
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["month_to"],
        message: `Please choose a month before or equal to ${getMonthName(MAX_MONTH)}.`,
      });
    }
  });

export type WorkHistoryFormData = z.infer<typeof workHistorySchema>;

type UseWorkExperienceArgs = {
  persistModalInstance?: boolean;
  onFinished?: () => void;
};

export const useWorkExperience = ({
  persistModalInstance = false,
  onFinished,
}: UseWorkExperienceArgs) => {
  const form = useForm<WorkHistoryFormData>({
    resolver: zodResolver(workHistorySchema),
    mode: "onTouched",
    defaultValues: INITIAL_WORK_HISTORY_VALUES,
  });

  const [createCompaniesBulk, { isLoading: isCreatingCompanies }] =
    useCreateCompaniesBulkMutation();
  const [submitType, setSubmitType] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [createWorkExperience, { isLoading: isCreateWorkExperienceLoading }] =
    useCreateWorkExperienceMutation();
  const [
    updateWorkExperience,
    { isLoading: isUpdateCreateWorkExperienceLoading },
  ] = useUpdateWorkExperienceMutation();
  const [deleteWorkExperience, { isLoading: isDeleteWorkExperienceLoading }] =
    useDeleteWorkExperienceMutation();
  const freelancerId = useUser()?.freelancer;
  const { data: profile } = useGetFreelancerPublicProfileQuery(
    freelancerId ?? skipToken,
  );

  const isSaving =
    isCreatingCompanies ||
    isUpdateCreateWorkExperienceLoading ||
    isDeleteWorkExperienceLoading ||
    isCreateWorkExperienceLoading;

  const onSubmitHandler = async (values: WorkHistoryFormData) => {
    if (isSaving) {
      return;
    }
    const newValue = deepClone(values) as CreateWorkExperienceParams;

    if (newValue.new_company) {
      const companyName = getCompanyName(newValue.new_company);
      const companiesResponse = await createCompaniesBulk([
        { name: companyName },
      ])
        .unwrap()
        .catch(() => null);

      if (
        !companiesResponse ||
        !Array.isArray(companiesResponse.companies) ||
        !companiesResponse.companies.length
      ) {
        form.setError("new_company", {
          message: "Please select a valid company",
        });

        return;
      }

      const company = companiesResponse.companies[0];
      if (!company || !typeGuard<unknown, { id: number }>(company, "id")) {
        return;
      }

      newValue.new_company = company.id;
    }

    if (values.id) {
      return await updateWorkExperience(newValue)
        .unwrap()
        .then(() => {
          dispatch(workExperienceEdited());
          onSubmitSuccess();
          CommonConfirmationModal.close();
        })
        .catch((error) => {
          handleError(error);
        });
    }

    return await createWorkExperience(newValue)
      .unwrap()
      .then(() => {
        dispatch(workExperienceAdded());
        onSubmitSuccess();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // OnSubmit and removeWorkHsitoryHandler are passed as an arg to the modals ...
  // ... which is why they needs to be calling the refs, that get updated on render
  const onSubmitHandlerRef = useEffectRef(onSubmitHandler);
  const onSubmit = useCallback(
    (values: WorkHistoryFormData) => {
      return onSubmitHandlerRef.current(values);
    },
    [onSubmitHandlerRef],
  );

  const onSubmitSuccess = () => {
    if (submitType === ADD_ANOTHER_ITEM) {
      return;
    }

    onFinished?.();

    if (!persistModalInstance) {
      ModalInstance.close();
    }
  };

  const removeWorkHistoryHandler = async (work: { id: number }) => {
    if (isSaving) {
      return;
    }
    await deleteWorkExperience(work.id).then(() => {
      dispatch(workExperienceDeleted());
    });

    CommonConfirmationModal.close();
  };

  const removeWorkHistoryHandlerRef = useEffectRef(removeWorkHistoryHandler);
  const removeWorkHistory = useCallback(
    (work: { id: number }) => {
      return removeWorkHistoryHandlerRef.current(work);
    },
    [removeWorkHistoryHandlerRef],
  );

  const handleSetValues = (initialWork: FreelancerWorkExperience) => {
    const newInitialValues = {
      id: initialWork.id,
      title: initialWork.title,
      new_company: initialWork.company.name,
      month_from: initialWork.month_from,
      month_to: initialWork.month_to,
      year_from: initialWork.year_from && String(initialWork.year_from),
      year_to: initialWork.year_to && String(initialWork.year_to),
      description: sanitize(initialWork.description, { ALLOWED_TAGS: [] }),
      is_currently_working: !initialWork.year_to && !initialWork.month_to,
    } as WorkHistoryFormData;

    form.reset(newInitialValues);
  };

  const handleError = (error: any) => {
    Object.entries(error.data).forEach(([fieldName, errorMessage]) => {
      form.setError(fieldName as keyof WorkHistoryFormData, {
        message: errorMessage as string,
      });
    });
  };

  return {
    form,
    freelancerWork: profile?.freelancer_work_experience,
    handleSetValues,
    onSubmit,
    removeWorkHistory,
    setSubmitType,
    submitType,
    isSaving,
  };
};

const getCompanyName = (company: Company | string | number) => {
  if (typeof company === "string") return company;
  if (typeof company === "object") return company.name;
  return "";
};
