import NewOfferBg from "@static/freelancer/new-offer-bg.svg";

import { Box } from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { BidButton } from "@js/apps/jobs/apps/bids";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button";
import { BoostedJobBadge } from "@js/apps/jobs/components/boosted-job-badge";
import { useApplicationBoost } from "@js/apps/jobs/hooks/application-boost";
import type { FreelancerBid, Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";
import { dateFromNow } from "@js/utils/date";

import {
  BrowseOpenJobs,
  RespondToOfferButton,
  ReviewContractButton,
  ScheduleInterviewButton,
  StartAIInterviewButton,
  StartScreeningButton,
  ViewApplicationButton,
} from "../../job-action-buttons/components";
import { JobStatusBox } from "../components/job-status-box";

import styles from "../styles.module.scss";

type StatusForFreelancerProps = {
  job: Job | null;
  bid?: FreelancerBid;
  className?: string;
  longTile?: boolean;
  statusForFreelancer?: EnumType<typeof ENUMS.JobFreelancerStatus>;
};

export const StatusForFreelancer = ({
  job,
  bid,
  statusForFreelancer,
  ...props
}: StatusForFreelancerProps) => {
  const appliedDateFromNow = dateFromNow(bid?.created);
  const isBoosted = !!bid?.is_boosted;
  const talentId = useUser()?.freelancer;

  if (!job || !statusForFreelancer) return null;

  switch (statusForFreelancer) {
    case ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS:
      return <ApplyTile job={job} bid={bid} {...props} />;
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
      return (
        <InvitedTile job={job} bid={bid} label="Invited by client" {...props} />
      );
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
      return (
        <InvitedTile
          job={job}
          bid={bid}
          label="Invited by matcher"
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
      return <PendingApprovalTile jobId={job.id} bidId={bid?.id} {...props} />;
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
      return (
        <ApplicationSentTile
          statusForFreelancer={statusForFreelancer}
          job={job}
          bid={bid}
          subheader={`Applied ${appliedDateFromNow}`}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
      return (
        <ApplicationSentTile
          subheader="In review"
          statusForFreelancer={statusForFreelancer}
          job={job}
          bid={bid}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.LIVE_INTERVIEWING:
      return (
        <LiveInterviewingTile job={job} isBoosted={isBoosted} {...props} />
      );
    case ENUMS.JobFreelancerStatus.AI_INTERVIEWING:
      if (!talentId) return null;
      return (
        <AIInterviewingTile
          job={job}
          talentId={talentId}
          isBoosted={isBoosted}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
      return (
        <InReviewAfterAIInterviewTile
          jobId={job.id}
          bidId={bid?.id}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      return <NewOfferTile job={job} isBoosted={isBoosted} {...props} />;
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
    case ENUMS.JobFreelancerStatus.OFFER_EXTENDED:
      return <HiredTile job={job} isBoosted={isBoosted} {...props} />;
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
      return (
        <NotSelectedTile
          header="Not selected"
          isBoosted={isBoosted}
          jobId={job.id}
          statusForFreelancer={statusForFreelancer}
          subheader="The client decided to not move forward with your application."
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.JOB_FILLED:
      return (
        <NotSelectedTile
          header="Job filled"
          isBoosted={isBoosted}
          jobId={job.id}
          statusForFreelancer={statusForFreelancer}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
      return (
        <NotSelectedTile
          header="Closed"
          isBoosted={isBoosted}
          jobId={job.id}
          statusForFreelancer={statusForFreelancer}
          {...props}
        />
      );
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
      return (
        <NotSelectedTile
          header="On hold"
          isBoosted={isBoosted}
          jobId={job.id}
          statusForFreelancer={statusForFreelancer}
          {...props}
        />
      );

    default: {
      assertUnreachable(statusForFreelancer);
      return null;
    }
  }
};

type BaseTileProps = {
  className?: string;
  longTile?: boolean;
};

type ApplyTileProps = BaseTileProps & {
  job: Job;
  bid?: FreelancerBid;
};

const ApplyTile = ({ job, bid, ...props }: ApplyTileProps) => {
  const { longTile } = props;
  return (
    <JobStatusBox color="var(--positive-1)" {...props}>
      <JobStatusBox.Header {...props}>
        Accepting applications
      </JobStatusBox.Header>
      <JobStatusBox.Content {...props}>
        <BidButton
          fullWidth={!longTile}
          variant="positive"
          shape="squared"
          job={job}
          bid={bid}
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type InvitedTileProps = ApplyTileProps & {
  label: string;
};

const InvitedTile = ({ label, job, bid, ...props }: InvitedTileProps) => {
  const { longTile } = props;
  return (
    <JobStatusBox color="var(--medium-violet)" {...props}>
      <JobStatusBox.Header color="primary" {...props}>
        {label}
      </JobStatusBox.Header>
      <JobStatusBox.Content {...props}>
        <BidButton
          fullWidth={!longTile}
          variant="primary"
          shape="squared"
          job={job}
          bid={bid}
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type PendingApprovalTileProps = BaseTileProps & {
  bidId?: number;
  jobId: number;
};

const PendingApprovalTile = ({
  bidId,
  jobId,
  ...props
}: PendingApprovalTileProps) => {
  const { longTile } = props;

  return (
    <JobStatusBox color="var(--dark-blue)" {...props}>
      <div>
        <JobStatusBox.Header>Application pending</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          Account is pending approval
        </JobStatusBox.Subheader>
      </div>
      <JobStatusBox.Content {...props}>
        <StartScreeningButton
          fullWidth={!longTile}
          variant="blue"
          shape="squared"
        />
        <ViewApplicationButton
          bidId={bidId}
          jobId={jobId}
          fullWidth={!longTile}
          variant="secondary"
          inverse
          shape="squared"
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type ApplicationSentTileProps = BaseTileProps & {
  subheader: string;
  bid: FreelancerBid | undefined;
  job: Job;
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus>;
};

const ApplicationSentTile = ({
  subheader,
  statusForFreelancer,
  bid,
  job,
  ...props
}: ApplicationSentTileProps) => {
  const { showApplicationBoost } = useApplicationBoost();
  const user = useUser();
  const bidId = bid?.id;
  const jobId = job.id;

  if (!bidId) return null;

  const { longTile } = props;

  return (
    <JobStatusBox color="var(--dark-blue)" {...props}>
      <div>
        <JobStatusBox.Header {...props}>Application sent</JobStatusBox.Header>
        <JobStatusBox.Subheader>{subheader}</JobStatusBox.Subheader>
      </div>
      <JobStatusBox.Content {...props}>
        {showApplicationBoost && (
          <>
            {!bid.is_boosted ? (
              <BoostJobButton
                variant="primary"
                shape="squared"
                boostCredit={user?.boost_credit}
                bidId={bid?.id}
                fullWidth={!longTile}
                statusForFreelancer={statusForFreelancer}
                isOpen={bid?.can_edit || job.is_open}
              />
            ) : (
              <Box className={styles.boostedContainer}>
                <BoostedJobBadge
                  jobId={job.id}
                  statusForFreelancer={statusForFreelancer}
                />
              </Box>
            )}
          </>
        )}
        <ViewApplicationButton
          jobId={jobId}
          bidId={bidId}
          {...(showApplicationBoost
            ? {
                variant: "secondary",
                inverse: true,
              }
            : {
                variant: "blue",
              })}
          fullWidth={!longTile}
          shape="squared"
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

const LiveInterviewingTile = ({
  job,
  longTile,
  isBoosted,
  ...props
}: Omit<ApplyTileProps, "bid"> & { isBoosted?: boolean }) => {
  return (
    <JobStatusBox color="var(--dark-teal)" {...props}>
      <div>
        <JobStatusBox.Header {...props}>Interviewing</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          You’ve been invited to schedule a live video interview.
        </JobStatusBox.Subheader>
      </div>
      <JobStatusBox.Content {...props}>
        {isBoosted && (
          <Box className={styles.boostedContainer}>
            <BoostedJobBadge jobId={job.id} />
          </Box>
        )}
        <ScheduleInterviewButton
          employerUserId={job.creator_user_id}
          fullWidth={!longTile}
          variant="medium-teal"
          shape="squared"
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type AIInterviewingTileProps = ApplyTileProps & {
  talentId: number;
  isBoosted?: boolean;
};

const AIInterviewingTile = ({
  job,
  talentId,
  isBoosted,
  ...props
}: AIInterviewingTileProps) => {
  const { longTile } = props;
  return (
    <JobStatusBox color="var(--dark-teal)" {...props}>
      <div>
        <JobStatusBox.Header {...props}>Interviewing</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          You’ve been invited to an AI interview. You can start it at any time.
        </JobStatusBox.Subheader>
      </div>
      <JobStatusBox.Content {...props}>
        {isBoosted && (
          <Box className={styles.boostedContainer}>
            <BoostedJobBadge jobId={job.id} />
          </Box>
        )}
        <StartAIInterviewButton
          variant="medium-teal"
          jobId={job.id}
          talentId={talentId}
          fullWidth={!longTile}
          shape="squared"
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type NewOfferTileProps = Omit<ApplyTileProps, "bid"> & {
  isBoosted?: boolean;
};

const NewOfferTile = ({ isBoosted, job, ...props }: NewOfferTileProps) => {
  const { longTile } = props;

  if (!job?.offer_id) return null;

  return (
    <div className={styles.newOfferTile}>
      <JobStatusBox
        color="var(--positive-2)"
        border="4px solid var(--white)"
        position="relative"
        overflow="hidden"
        {...props}
      >
        <>
          <NewOfferBg color="var(--soft-green)" className={styles.bg} />
          <Box zIndex={1}>
            <JobStatusBox.Header {...props}>New offer</JobStatusBox.Header>
            <JobStatusBox.Subheader>
              Review your job offer
            </JobStatusBox.Subheader>
          </Box>
          <JobStatusBox.Content {...props}>
            {isBoosted && (
              <Box className={styles.boostedContainer}>
                <BoostedJobBadge
                  jobId={job.id}
                  statusForFreelancer={job?.status_for_freelancer}
                />
              </Box>
            )}
            <RespondToOfferButton
              jobId={job.id}
              offerId={job.offer_id}
              fullWidth={!longTile}
              variant="primary"
              shape="squared"
              inverse
            />
          </JobStatusBox.Content>
        </>
      </JobStatusBox>
    </div>
  );
};

type HiredTileProps = Omit<ApplyTileProps, "bid"> & {
  isBoosted?: boolean;
};

const HiredTile = ({ job, isBoosted, ...props }: HiredTileProps) => {
  const { longTile } = props;

  if (!job?.offer_id) return null;

  return (
    <JobStatusBox color="var(--positive-3)" {...props}>
      <div>
        <JobStatusBox.Header color="positive" {...props}>
          You’re hired 🎉
        </JobStatusBox.Header>
        <JobStatusBox.Subheader color="positive">
          You’ve accepted the offer
        </JobStatusBox.Subheader>
      </div>
      <JobStatusBox.Content {...props}>
        {isBoosted && (
          <Box className={styles.boostedContainer}>
            <BoostedJobBadge
              jobId={job.id}
              statusForFreelancer={job?.status_for_freelancer}
            />
          </Box>
        )}
        <ReviewContractButton
          jobId={job.id}
          offerId={job.offer_id}
          fullWidth={!longTile}
          variant="positive-1"
          shape="squared"
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type NotSelectedTileProps = BaseTileProps & {
  header: string;
  isBoosted: boolean;
  jobId: number;
  statusForFreelancer: StatusForFreelancerProps["statusForFreelancer"];
  subheader?: string;
};

const NotSelectedTile = ({
  header,
  isBoosted,
  jobId,
  subheader,
  statusForFreelancer,
  ...props
}: NotSelectedTileProps) => {
  const { longTile } = props;

  return (
    <JobStatusBox color="var(--black)" {...props}>
      <div>
        <JobStatusBox.Header {...props}>{header}</JobStatusBox.Header>
        <JobStatusBox.Subheader>
          {subheader || "Not accepting applications"}
        </JobStatusBox.Subheader>
      </div>
      <JobStatusBox.Content {...props}>
        {isBoosted && (
          <Box className={styles.boostedContainer}>
            <BoostedJobBadge
              jobId={jobId}
              statusForFreelancer={statusForFreelancer}
            />
          </Box>
        )}
        <BrowseOpenJobs inverse shape="squared" fullWidth={!longTile} />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};

type InReviewAfterAIInterviewTileProps = BaseTileProps & {
  jobId: number;
  bidId?: number;
};

const InReviewAfterAIInterviewTile = ({
  jobId,
  bidId,
  longTile,
  ...props
}: InReviewAfterAIInterviewTileProps) => {
  return (
    <JobStatusBox color="var(--dark-teal)" {...props}>
      <JobStatusBox.Header>Interviewing</JobStatusBox.Header>
      <JobStatusBox.Subheader>
        You’ve completed your AI interview and it is being reviewed.
      </JobStatusBox.Subheader>
      <JobStatusBox.Content>
        <ViewApplicationButton
          variant="medium-teal"
          jobId={jobId}
          bidId={bidId}
          fullWidth={!longTile}
          shape="squared"
        />
      </JobStatusBox.Content>
    </JobStatusBox>
  );
};
