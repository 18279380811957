import { type FC, useCallback, useEffect } from "react";

import { Box } from "@braintrust/braintrust-ui-components";
import { useReserveInsightsMutation } from "@js/apps/auth/api";
import { purchaseInsightsOnrampClicked } from "@js/apps/jobs/actions";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

import { Description } from "./components/description";
import { PurchaseSummary } from "./components/purchase-summary";
import { usePreCheckBalanceUpgrade } from "./hooks";

export type ApplicationOnrampModalProps = {
  onSuccessPurchaseBtrst?: () => void;
};

export const ApplicationOnrampModal: FC<ApplicationOnrampModalProps> = ({
  onSuccessPurchaseBtrst,
}) => {
  const { formattedRequiredTotalBtrst, requiredTotalBtrstRaw, upgradeCost } =
    usePreCheckBalanceUpgrade();
  const [initPayment, { isLoading: isLoadingCoinbase, data }] =
    useLazyInitCoinbasePaymentQuery();
  const { openOnRamp } = useCoinbasePay();
  const [reserveUpgrade, { isLoading: isLoadingReserveUpgrade }] =
    useReserveInsightsMutation();
  const isWalletReady = !data?.wallet_creation_in_progress;

  useEffect(() => {
    initPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();

  const handleClickButton = useCallback(() => {
    dispatch(purchaseInsightsOnrampClicked());
  }, [dispatch]);

  const onPurchase = useCallback(() => {
    openOnRamp({
      /**
       * @note
       *  we use the string version of the result so we could avoid passing results that has exponent(e.g 1.24444444e+12)
       * So that we always ensure we pass to the parameter is accurate with the decimals
       */
      tokensToBuy: requiredTotalBtrstRaw as unknown as number,
      onOpen: async () => {
        handleClickButton();
        reserveUpgrade();
        ModalInstance.close();
      },
    });
    onSuccessPurchaseBtrst?.();
  }, [
    openOnRamp,
    requiredTotalBtrstRaw,
    handleClickButton,
    reserveUpgrade,
    onSuccessPurchaseBtrst,
  ]);

  return (
    <Box
      minWidth={{
        lg: "1248px",
      }}
      width={{
        xs: "90vw",
        sm: "88vw",
        md: "86.5vw",
        lg: "auto",
      }}
      minHeight={{ xs: "100%", lg: "800px" }}
      padding={2}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection={{ xs: "column", lg: "row" }}
    >
      <Description
        requiredTotalBtrst={formattedRequiredTotalBtrst}
        requiredTotalBtrstRaw={requiredTotalBtrstRaw}
      />
      <PurchaseSummary
        isLoadingCoinbase={isLoadingCoinbase}
        isWalletReady={isWalletReady}
        isPurchasing={isLoadingReserveUpgrade}
        requiredTotalBtrst={formattedRequiredTotalBtrst}
        requiredTotalBtrstRaw={requiredTotalBtrstRaw}
        onPurchase={onPurchase}
        upgradeTypeCost={upgradeCost}
      />
    </Box>
  );
};
