import type { ReactNode } from "react";

import {
  ApplicationInsightType,
  useApplicationInsightIntervalAds,
} from "@js/apps/freelancer/hooks/application-insight-interval-ads";
import { JobItem } from "@js/apps/jobs/components/job-item/index";
import type { JobListingJob } from "@js/types/jobs";

import { ApplicationInsightFeatureFlagWrapper } from "../application-insight-feature-flag-wrapper";
import JobInsightsBanner from "../job-insights-banner";

import { JobsContainer } from "./jobs-container";
import { JobsEmptyState } from "./jobs-empty-state";

import style from "./style.module.scss";
type JobsListingProps = {
  jobs: JobListingJob[];
  emptyState?: ReactNode;
  searchResults?: boolean;
};

export const JobsListing = ({
  jobs,
  emptyState,
  searchResults,
}: JobsListingProps) => {
  const { showJobInsightAds, setCloseJobInsightAds } =
    useApplicationInsightIntervalAds(ApplicationInsightType.JOB_LIST_BANNER);

  return (
    <div className={style.jobsListing}>
      {jobs.length ? (
        <JobsContainer>
          <div className={style.jobsListingList}>
            {showJobInsightAds && (
              <ApplicationInsightFeatureFlagWrapper>
                <JobInsightsBanner onClose={setCloseJobInsightAds} />
              </ApplicationInsightFeatureFlagWrapper>
            )}
            {jobs.map((job, index) => (
              <JobItem
                key={job.id}
                job={job}
                resultPosition={index}
                searchResults={searchResults}
              />
            ))}
          </div>
        </JobsContainer>
      ) : (
        emptyState || (
          <div className={style.jobsListingEmptyState}>
            <JobsEmptyState
              title="Sorry, there are no jobs available for that search"
              description="Try removing filters or changing some of your search criteria"
            />
          </div>
        )
      )}
    </div>
  );
};
