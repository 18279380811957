import { useCallback, useLayoutEffect, useRef } from "react";

export const useLatestCallback = <T extends AnyFunction>(fn: T): T => {
  const ref = useRef<T>(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback((...args: Parameters<T>) => ref.current(...args), []) as T;
};

type AnyFunction = (...args: any[]) => any;
