import { createAction } from "@reduxjs/toolkit";

import { Events } from "@js/services/analytics/constants";

import {
  CLIENT_SIGN_UP_SELECTED,
  GOAL_SELECTED,
  INTEREST_SELECTED,
} from "./action-types";
import type { SubmitTypes } from "./hooks";

export const clientSignUpSelected = () => {
  return {
    type: CLIENT_SIGN_UP_SELECTED,
  };
};

export const goalSelected = createAction<{
  joining_reasons: EnumType<typeof ENUMS.JoiningReason>;
}>(GOAL_SELECTED);

export const interestSelected = createAction<{
  interests: EnumType<typeof ENUMS.Interest>;
}>(INTEREST_SELECTED);

export const choosePersonalAccount = createAction(
  Events.CHOOSE_PERSONAL_ACCOUNT,
);

export const viewClientSignUpOrgPage = createAction(
  Events.VIEW_CLIENT_SIGNUP_ORG_PAGE,
);

export const viewClientInviteJoinPage = createAction(
  Events.VIEW_CLIENT_INVITE_JOIN_PAGE,
);

export const viewClientInviteOrgPage = createAction(
  Events.VIEW_CLIENT_INVITE_ORG_PAGE,
);

export const viewClientInviteDashboard = createAction(
  Events.VIEW_CLIENT_INVITE_DASHBOARD,
);

export const clickClientInviteAgreeTerms = createAction(
  Events.CLICK_CLIENT_INVITE_SIGNUP_AGREE_TERMS,
);

export const clickClientInviteJoin = createAction(
  Events.CLICK_CLIENT_INVITE_SIGNUP_JOIN,
);

export const clickClientInviteOrgDetails = createAction(
  Events.CLICK_CLIENT_INVITE_SIGNUP_ORG_DETAILS,
);

export type EmployerOnboardingActionTypes = ReturnType<
  typeof viewClientSignUpOrgPage
>["type"];

export type EmployerInviteActionTypes = ReturnType<
  | typeof viewClientInviteJoinPage
  | typeof viewClientInviteOrgPage
  | typeof viewClientInviteDashboard
  | typeof clickClientInviteAgreeTerms
  | typeof clickClientInviteJoin
  | typeof clickClientInviteOrgDetails
>["type"];

export const onboardingNextStepClicked = createAction<
  SubmitTypes,
  "onboardingNextStepClicked"
>("onboardingNextStepClicked");

export type OnboardingNextStepClicked = ReturnType<
  typeof onboardingNextStepClicked
>["type"];

export type OnboardingChooseAccountType = ReturnType<
  typeof choosePersonalAccount
>["type"];
