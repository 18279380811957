import type { User } from "@sentry/react";

import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { Job } from "@js/types/jobs";
import type { MessageRoom } from "@js/types/messenger";
import { typeGuard } from "@js/utils/typescript";

import type { SelectionBid } from "./components/bulk-select-actions/bid-bulk-selection-slice";

export const getRoomContext = (
  user: User,
  isNodeStaff: boolean,
  room: MessageRoom,
  authorId: number,
) => {
  if (isNodeStaff) {
    const filteredParticipants = room.participants.filter(
      (participant) => participant.id !== user.id,
    );

    return {
      participants: [
        authorId,
        ...filteredParticipants.map((participant) => participant.id),
      ],
    };
  }

  return {
    participants: room.participants.map((participant) => participant.id),
  };
};

export const handleFailureForRestrictedApplications = () => {
  ModalInstance.close();
  Snackbar.info(
    "This action couldn't have been performed on any of the selected applications.",
  );
};

export const getReceiversForBulkMessage = (
  selectedBids: SelectionBid[],
  bulkActionResponse: unknown,
) => {
  if (!Array.isArray(bulkActionResponse)) {
    return selectedBids.map((selected) => selected.freelancerId);
  }

  return selectedBids
    .filter((bid) => {
      const currentBidActionResult: unknown = bulkActionResponse.find(
        (actionResponse: unknown) => {
          if (
            !actionResponse ||
            typeof actionResponse !== "object" ||
            !typeGuard<unknown, { id: number }>(actionResponse, "id")
          ) {
            return false;
          }

          return actionResponse.id === bid.id;
        },
      );

      if (!currentBidActionResult) {
        return true;
      }

      const hasActionResultedInError =
        typeGuard<unknown, { result: string }>(
          currentBidActionResult,
          "result",
        ) && currentBidActionResult.result === "error";

      return !hasActionResultedInError;
    })
    .map((selected) => selected.freelancerId);
};

export const isAIInterviewButtonDisabled = (job: Job): boolean =>
  !!job.completed_at ||
  job.openings_left === 0 ||
  !job.interview_questions?.length;
