import cs from "classnames";

import type { CarouselProps } from "@braintrust/braintrust-ui-components";
import {
  Box,
  Carousel,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useMediaQuery } from "@braintrust/braintrust-ui-components/hooks";
import JobInsightsCard from "@js/apps/jobs/components/job-insights-card";

import type { CarouselHeaderProps } from "./header";
import { CarouselHeader } from "./header";

export * from "./header";

import styles from "./styles.module.scss";

const LARGE_SPACING = 24;
const MOBILE_SPACING = 16;

export type CardCarouselSectionProps<T> = {
  title: string;
  items?: Array<T>;
  //cardOrder starts from 1
  renderItem: (item: T, cardOrder: number, state?: any) => JSX.Element;
  url?: string;
  urlLabel?: string;
  loading: boolean;
  noItemsPlaceholder?: string;
  transitionProps?: any;
  swiperStyle?: CarouselProps["style"];
  carouselHeaderProps?: Partial<CarouselHeaderProps>;
  extendedWidth?: boolean;
  showJobInsightsAds?: boolean;
  onCloseJobInsightAds?: () => void;
};

export const CardCarouselSection = <T,>({
  title,
  items = [],
  renderItem,
  url,
  urlLabel = "View all jobs",
  loading,
  noItemsPlaceholder = "No items yet.",
  transitionProps,
  swiperStyle = { padding: 2 }, // to make focus link visible
  carouselHeaderProps,
  extendedWidth,
  showJobInsightsAds,
  onCloseJobInsightAds,
}: CardCarouselSectionProps<T>) => {
  const isMobile = useMediaQuery(700);

  const itemsSpacing = isMobile ? MOBILE_SPACING : LARGE_SPACING;

  const jobItems = items.map((item, index) => {
    return renderItem(item, index + 1);
  });

  return (
    <Box width={1} {...transitionProps}>
      <CarouselHeader
        title={title}
        url={url}
        urlLabel={urlLabel}
        mb={2}
        hideNavigationButtons
        {...carouselHeaderProps}
      />
      {loading ? (
        <Loader />
      ) : !items.length ? (
        <Box mt={-2}>
          <Typography component="p">{noItemsPlaceholder}</Typography>
        </Box>
      ) : (
        <Carousel
          slides={
            showJobInsightsAds && onCloseJobInsightAds
              ? [
                  <JobInsightsCard key={-1} onClose={onCloseJobInsightAds} />,
                  ...jobItems,
                ]
              : jobItems
          }
          slideProps={{
            style: {
              height: "auto",
              width: "min-content",
              overflow: "hidden",
              borderRadius: "16px",
            },
          }}
          mousewheel={{
            forceToAxis: true,
          }}
          className={cs(extendedWidth && `${styles.homeCarouselExtendedWidth}`)}
          observer
          observeParents
          grabCursor
          threshold={10}
          style={swiperStyle}
          spaceBetween={itemsSpacing}
          slidesPerGroup={1}
          breakpoints={{
            600: {
              slidesPerGroup: 2,
            },
          }}
        />
      )}
    </Box>
  );
};
