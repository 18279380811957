import { Box } from "@braintrust/braintrust-ui-components";

import styles from "./styles.module.scss";

const LEARN_MORE_VIDEO_SRC = `${SETTINGS.STATIC_URL}referral/join-the-braintrust-connector-program.mp4`;

export const ReferAndEarnLearnMore = () => {
  return (
    <Box className={styles.container}>
      <video
        width="1400"
        height="910"
        controls
        className={styles.video}
        playsInline
      >
        <source src={LEARN_MORE_VIDEO_SRC} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};
