import { memoize } from "underscore";

import { getFrontendRoutes } from "@js/routes/get-frontend-routes";

let viewEvents: Set<string>;
export const isValidViewEvent = memoize((event: string) => {
  if (!viewEvents || !viewEvents.size) {
    const frontendRoutes = getFrontendRoutes() || [];
    const viewEventNames = frontendRoutes.map((viewEvent) => viewEvent.name);
    viewEvents = new Set(viewEventNames);
  }

  return viewEvents.has(event);
});
