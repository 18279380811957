import { useCallback, useEffect, useMemo } from "react";

import {
  usePurchaseInsightsMutation,
  useReserveInsightsMutation,
} from "@js/apps/auth/api";
import { purchaseInsightsOnrampClicked } from "@js/apps/jobs/actions";
import { openSuccessPurchaseModal } from "@js/apps/jobs/components/product-checkout-modal/btrst-checkout-form/success-purchase-modal";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { typeGuard } from "@js/utils";

import { useProductCheckoutModalContext } from "../../product-checkout-modal-context";

export const useActionButtons = () => {
  const dispatch = useAppDispatch();
  const {
    isSufficientBtrst,
    onCloseProductCheckoutModal,
    onCloseSuccessPurchaseModal,
    requiredTotalBtrstRaw,
    setModeOfPayment,
    upgradeCost,
  } = useProductCheckoutModalContext();

  const [purchaseUpgrade, { isLoading: isLoadingPurchaseUpgrade }] =
    usePurchaseInsightsMutation();

  const [initPayment, { isLoading: isLoadingCoinbase, data }] =
    useLazyInitCoinbasePaymentQuery();
  const { openOnRamp } = useCoinbasePay();
  const [reserveUpgrade, { isLoading: isLoadingReserveUpgrade }] =
    useReserveInsightsMutation();

  const costInFiat = useMemo(
    () => `$${upgradeCost.toFixed(2)} USD`,
    [upgradeCost],
  );
  const isWalletReady = useMemo(
    () => !data?.wallet_creation_in_progress,
    [data?.wallet_creation_in_progress],
  );

  useEffect(() => {
    if (!isSufficientBtrst) {
      initPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSufficientBtrst]);

  const handleClickCoinbaseButton = useCallback(() => {
    dispatch(purchaseInsightsOnrampClicked());
  }, [dispatch]);

  const onOpenCoinbase = useCallback(() => {
    openOnRamp({
      /**
       * @note
       *  we use the string version of the result so we could avoid passing results that has exponent(e.g 1.24444444e+12)
       * So that we always ensure we pass to the parameter is accurate with the decimals
       */
      tokensToBuy: requiredTotalBtrstRaw as unknown as number,
      onOpen: async () => {
        handleClickCoinbaseButton();
        reserveUpgrade();
        onCloseProductCheckoutModal();
      },
    });
  }, [
    handleClickCoinbaseButton,
    onCloseProductCheckoutModal,
    openOnRamp,
    requiredTotalBtrstRaw,
    reserveUpgrade,
  ]);

  const onPayBTRST = useCallback(async () => {
    try {
      await purchaseUpgrade().unwrap();
      onCloseProductCheckoutModal();
      openSuccessPurchaseModal(dispatch, onCloseSuccessPurchaseModal);
    } catch (error) {
      if (
        error &&
        typeGuard<unknown, { data: { error_message?: string } }>(error, "data")
      ) {
        Snackbar.error(error?.data?.error_message || "Something went wrong!");
      }
    }
  }, [
    dispatch,
    onCloseProductCheckoutModal,
    onCloseSuccessPurchaseModal,
    purchaseUpgrade,
  ]);

  const onPayFiat = useCallback(
    () => setModeOfPayment("stripe"),
    [setModeOfPayment],
  );

  return {
    costInFiat,
    isLoadingCoinbase,
    isLoadingReserveUpgrade,
    isLoadingPurchaseUpgrade,
    isSufficientBtrst,
    isWalletReady,
    onOpenCoinbase,
    onPayBTRST,
    onPayFiat,
  };
};
