import React from "react";

import { useEmailConfirmationModal } from "@js/apps/dashboard/hooks/use-email-confirmation-modal";
import { useShowPurchaseUpgrade } from "@js/hooks/purchase-upgrade";

import { usePollWalletStatus } from "../on-ramp/hooks/use-poll-wallet-status";
import { useShowPendingOnrampTransaction } from "../on-ramp/hooks/use-show-pending-onramp-transaction";
import TaxFormRequiredWarning from "../withdrawal/components/tax-form-required-warning";
import WithdrawalMethodWarning from "../withdrawal/components/withdrawal-method-warning";

import { useProfileCompletionSuccess } from "./hooks/use-profile-completion-success";

export const FreelancerGlobalProvider = () => {
  useEmailConfirmationModal({
    pathToShowConfirmationModal: /^\/talent\/dashboard\/welcome\/?$/,
  });
  useProfileCompletionSuccess();
  usePollWalletStatus();
  useShowPendingOnrampTransaction();
  useShowPurchaseUpgrade();

  return (
    <>
      <WithdrawalMethodWarning />
      <TaxFormRequiredWarning />
    </>
  );
};
