import { ModalInstance } from "@js/components/modal";

import { ProductDescriptionModal } from "./product-description-modal";

import styles from "./styles.module.scss";

type OpenProductDescriptionModalProps = {
  onCancel?: () => void;
  onCloseSuccessPurchaseModal?: () => void;
};

export const openProductDescriptionModal = ({
  onCancel,
  onCloseSuccessPurchaseModal,
}: OpenProductDescriptionModalProps) => {
  ModalInstance.open({
    padding: false,
    containerScrollableNoMobilePadding: false,
    className: styles.productDescriptionModal,
    children: (
      <ProductDescriptionModal
        onCancel={onCancel}
        onCloseSuccessPurchaseModal={onCloseSuccessPurchaseModal}
        openProductDescriptionModal={openProductDescriptionModal}
        ModalInstance={ModalInstance}
      />
    ),
  });
};
