type NavBaseLink = {
  url: string;
  label: string;
  persistUTMSearchParams?: boolean;
};

export type NavSubLink = NavBaseLink;

type NavSubLinkGroup = {
  url: string;
  label: string;
  subLinks: NavSubLink[];
};

// either sublinks or subling groups
export type NavLink = NavBaseLink & {
  subLinks?: NavSubLink[];
  subLinkGroups?: NavSubLinkGroup[];
};

export const NAV_LINKS: NavLink[] = [
  {
    url: "https://www.usebraintrust.com/air",
    label: "AI Recruiter (AIR)",
    subLinks: [
      {
        url: "https://www.usebraintrust.com/air-demo",
        label: "Try AIR For Yourself",
      },
      {
        url: "https://www.usebraintrust.com/contact-sales",
        label: "Contact Sales",
      },
    ],
  },
  {
    label: "Talent Marketplace",
    url: "",
    subLinkGroups: [
      {
        label: "For Companies",
        url: "",
        subLinks: [
          {
            url: "https://www.usebraintrust.com/solutions",
            label: "Solutions",
          },
          {
            url: "https://www.usebraintrust.com/pricing",
            label: "Pricing",
          },
          {
            url: "https://www.usebraintrust.com/why-braintrust",
            label: "Why Braintrust",
          },
          {
            url: "https://www.usebraintrust.com/skills",
            label: "Skills & Capabilities",
          },
          {
            url: "https://www.usebraintrust.com/clients",
            label: "Case Studies",
          },
          {
            url: "https://www.usebraintrust.com/contact-sales",
            label: "Contact Sales",
          },
        ],
      },
      {
        label: "For Talent",
        url: "",
        subLinks: [
          {
            url: "/jobs",
            label: "Jobs",
            persistUTMSearchParams: true,
          },
          {
            url: "https://www.usebraintrust.com/how-it-works-for-talent",
            label: "How it Works",
          },
          {
            url: "https://www.usebraintrust.com/professional-network",
            label: "Professional Network",
          },
          {
            url: "https://www.usebraintrust.com/community",
            label: "Community",
          },
          {
            url: "https://www.usebraintrust.com/benefits",
            label: "Benefits",
          },
          {
            url: "https://www.usebraintrust.com/refer-clients",
            label: "Refer & Earn",
          },
        ],
      },
    ],
  },
  {
    url: "https://www.usebraintrust.com/about",
    label: "Company",
    subLinks: [
      {
        url: "https://www.usebraintrust.com/about",
        label: "About",
      },
      {
        url: "https://www.usebraintrust.com/blog",
        label: "Blog",
      },
      {
        url: "https://www.usebraintrust.com/btrst-token",
        label: "BTRST",
      },
      {
        url: "https://www.usebraintrust.com/press",
        label: "Press",
      },
      {
        url: "https://info.app.usebraintrust.com/",
        label: "Network Dashboard",
      },
    ],
  },
  {
    url: "/auth/login",
    label: "Log in",
    persistUTMSearchParams: true,
  },
];
