import { Field, reduxForm, SubmissionError } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Form } from "@js/forms/components/form";
import { ButtonSelectField } from "@js/forms/fields";
import type { JobForCoreTeam } from "@js/types/jobs";
import { enumToOptions } from "@js/utils";

import { usePatchJobMutation } from "../../api";
import { mapManageJobErrors } from "../../apps/create-job/utils/error-mapping";

const CHANGE_SUPPORT_TYPE = "change-support-type";

export const openJobFillSupportTypeModal = (job: JobForCoreTeam) => {
  ModalInstance.open({
    children: <EditJobFillSupportTypeModalContent job={job} />,
    closeButton: true,
    className: "add-support-type-modal",
  });
};

type EditJobFillSupportTypeModalContentProps = {
  job: Pick<JobForCoreTeam, "id" | "job_fill_support_type">;
};
const EditJobFillSupportTypeModalContent = ({
  job,
}: EditJobFillSupportTypeModalContentProps) => {
  const [patchJob] = usePatchJobMutation();
  const handleSubmit = async (values: FormData) => {
    const { jobId, job_fill_support_type } = values;

    return patchJob({ jobId, data: { job_fill_support_type } })
      .unwrap()
      .catch((error) => {
        if (!error.data) {
          return;
        }

        const newErrors = mapManageJobErrors(values, error.data);
        throw new SubmissionError(newErrors);
      });
  };

  return (
    <JobSupportTypeFormModal
      onCancel={ModalInstance.close}
      onSubmit={handleSubmit}
      onSubmitSuccess={() => {
        ModalInstance.close();
      }}
      initialValues={{
        jobId: job.id,
        job_fill_support_type: job.job_fill_support_type,
      }}
    />
  );
};

type FormData = {
  jobId: number;
  job_fill_support_type: EnumType<typeof ENUMS.JobFillSupportType>;
};

type JobSupportTypeOwnProps = {
  onCancel: () => void;
};

const JobSupportTypeFormModal = reduxForm<FormData, JobSupportTypeOwnProps>({
  form: CHANGE_SUPPORT_TYPE,
})(({ error, submit, submitting, onCancel }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <ModalConfirm confirmText="Save" onConfirm={submit} onCancel={onCancel}>
        <Typography mb={3} component="p" variant="title" size="small">
          Change Job Fill Support Type
        </Typography>
        <Field
          name="job_fill_support_type"
          options={enumToOptions(ENUMS.JobFillSupportTypeLabels)}
          variant="white-violet"
          shape="squared"
          multiple={false}
          component={ButtonSelectField}
        />
      </ModalConfirm>
    </Form>
  );
});
