import { Typography } from "@braintrust/braintrust-ui-components";

export const INSIGHT_BTRST_COST = 20;

export const accordionContainerStyle = {
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "fit-content",
    marginBottom: 0,
  },
};

const coinbaseDescription = {
  title: "Is it safe to use Coinbase?",
  body: (
    <>
      <Typography component="p" size="small">
        Coinbase is the most trusted crypto exchange:
      </Typography>
      <Typography component="a" size="small">
        <a
          href="https://www.coinbase.com/security"
          target="_blank"
          rel="noreferrer"
        >
          https://www.coinbase.com/security
        </a>
      </Typography>
    </>
  ),
};

const btrstAvailabilityDescription = {
  title: "How will I know when my BTRST is available?",
  body: (
    <>
      <Typography component="p" size="small">
        We'll notify you when your BTRST is available. This could take up to 3
        hours. You can view your token balance anytime in your Braintrust&nbsp;
        <Typography component="a" size="small">
          <a
            href="https://app.usebraintrust.com/auth/login/?next=%2Ftalent%2Fdashboard%2Fmy_wallet%2F"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Wallet.
          </a>
        </Typography>
      </Typography>
    </>
  ),
};

export const INSIGHTS_ACCORDION_CONTENT = [
  {
    title: "How does it work?",
    body: (
      <>
        <Typography component="p" size="small">
          Application Insights provides personalized feedback for each job,
          using Braintrust AI.
        </Typography>
        <ul>
          <li>
            <Typography
              component="p"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Find jobs that are a great match for you
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              See how you compare to others
            </Typography>
          </li>
          <li>
            <Typography
              component="p"
              variant="paragraph"
              size="small"
              color="grey-1"
            >
              Discover areas for improvement
            </Typography>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "How much is it?",
    body: `For ${INSIGHT_BTRST_COST} BTRST tokens, unlock 30 days of Application Insights. No recurring charges. Renew at your convenience.`,
  },
  {
    title: "Why do we need to use Coinbase?",
    body: "Coinbase is the largest online exchange that allows people to buy, sell, and trade cryptocurrencies, including BTRST, on the Blockchain. We chose Coinbase for it’s trusted reputation, secure platform, and easy-to-use interface that makes it ideal for beginner to advanced cryptocurrency traders.",
  },
  coinbaseDescription,
  btrstAvailabilityDescription,
];

export const INSIGHTS_DESCRIPTION =
  "Identify promising opportunities and refine your job search with personalized feedback.";
