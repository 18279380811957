import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useConfirmStripeCheckoutInsightsSessionMutation } from "@js/apps/auth/api";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { useUserWalletBalance } from "@js/apps/common/hooks/use-user-wallet-balance";
import { openInsightPurchaseModal } from "@js/apps/jobs/components/application-insight-purchase-modal";
import { usePreCheckBalanceUpgrade } from "@js/apps/jobs/components/application-onramp-modal/hooks";
import { openApplicationOnrampModal } from "@js/apps/jobs/components/application-onramp-modal/utils";
import type { OpenBidCreateSuccessModalArg } from "@js/apps/jobs/components/bid-create-success-modal/types";
import { openSuccessPurchaseModal } from "@js/apps/jobs/components/product-checkout-modal/btrst-checkout-form/success-purchase-modal";
import { openProductDescriptionModal } from "@js/apps/jobs/components/product-description-modal";
import { useStripeFeatureFlag } from "@js/apps/jobs/hooks/stripe-feature-flag";
import { Snackbar } from "@js/components/snackbar";

import { useAppDispatch } from "../redux";

export type OnClickPurchaseUpgradeArg = {
  onCancelProductDescriptionModal?: () => void;
  onCloseSuccessPurchaseModal?: () => void;
  onSuccessPurchaseBtrst?: () => void;
  onSuccessPurchaseBoost?: () => void;
  postAppBoostProps?: OpenBidCreateSuccessModalArg;
};

const usePurchaseUpgrade = () => {
  const { isSufficientBtrst } = usePreCheckBalanceUpgrade();
  const { showStripe } = useStripeFeatureFlag();

  const onClickPurchaseUpgrade = useCallback(
    (args?: OnClickPurchaseUpgradeArg) => {
      if (showStripe) {
        openProductDescriptionModal({
          onCancel: args?.onCancelProductDescriptionModal,
          onCloseSuccessPurchaseModal: args?.onCloseSuccessPurchaseModal,
        });
        return;
      }

      if (isSufficientBtrst) {
        openInsightPurchaseModal();
      } else {
        openApplicationOnrampModal({
          onSuccessPurchaseBtrst: args?.onSuccessPurchaseBtrst,
        });
      }
    },
    [isSufficientBtrst, showStripe],
  );

  return {
    onClickPurchaseUpgrade,
  };
};

const useShowPurchaseUpgrade = () => {
  const user = useUser();
  const { showStripe } = useStripeFeatureFlag();
  const { isFreelancer } = useAccountType();
  const { isLoading: loadingWalletBalance } = useUserWalletBalance();

  const { onClickPurchaseUpgrade: showInsightPurchase } = usePurchaseUpgrade();
  const [searchParams] = useSearchParams();

  const determineUpgradeType = useCallback((params: URLSearchParams) => {
    if (params.get("purchase_insights") === "true") {
      return "insights";
    }

    return null;
  }, []);

  const handleStripeUpgrade = useCallback(() => {
    openProductDescriptionModal({});
  }, []);

  const handleNonStripeUpgrade = useCallback(() => {
    showInsightPurchase();
  }, [showInsightPurchase]);

  useEffect(() => {
    const canShowUpgrade = !loadingWalletBalance && isFreelancer;
    if (!canShowUpgrade) {
      return;
    }

    const upgradeType = determineUpgradeType(searchParams);
    if (!upgradeType) {
      return;
    }

    const handleUpgrade = showStripe
      ? handleStripeUpgrade
      : handleNonStripeUpgrade;

    handleUpgrade();
  }, [
    isFreelancer,
    loadingWalletBalance,
    searchParams,
    showStripe,
    determineUpgradeType,
    handleStripeUpgrade,
    handleNonStripeUpgrade,
    user?.can_view_insights,
  ]);
};

const useShowSuccessFiatPurchaseUpgrade = () => {
  const dispatch = useAppDispatch();

  const { isFreelancer } = useAccountType();
  const [searchParams] = useSearchParams();
  const [confirmStripeCheckoutUpgradeSession] =
    useConfirmStripeCheckoutInsightsSessionMutation();

  useEffect(() => {
    const openSucessFiatPurchaseUpgrade = async () => {
      const upgradeType = searchParams.get("success_fiat_payment");
      const sessionId = searchParams.get("session_id");

      if (!upgradeType || !sessionId || !isFreelancer) {
        return;
      }

      try {
        await confirmStripeCheckoutUpgradeSession({
          session_id: sessionId,
        }).unwrap();

        openSuccessPurchaseModal(dispatch);
      } catch {
        Snackbar.error("Couldn't confirm Fiat payment");
      }
    };

    openSucessFiatPurchaseUpgrade();
  }, [
    confirmStripeCheckoutUpgradeSession,
    dispatch,
    isFreelancer,
    searchParams,
  ]);
};

export {
  usePurchaseUpgrade,
  useShowPurchaseUpgrade,
  useShowSuccessFiatPurchaseUpgrade,
};
