import { CLIENT_REFERRAL_SORT_VALUES_MAP } from "@js/apps/dashboard/constants";
import type { EmployerReferralsOrderByType } from "@js/apps/dashboard/types";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleEmployerAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case Events.VIEW_CLIENT_SIGNUP_JOIN_PAGE: {
      logEvent(Events.VIEW_CLIENT_SIGNUP_JOIN_PAGE);
      break;
    }

    case Events.CLICK_CLIENT_SIGNUP_AGREE_TERMS: {
      logEvent(Events.CLICK_CLIENT_SIGNUP_AGREE_TERMS);
      break;
    }

    case Events.CLICK_CLIENT_SIGNUP_JOIN: {
      logEvent(Events.CLICK_CLIENT_SIGNUP_JOIN);
      break;
    }

    case Events.VIEW_CLIENT_SIGNUP_EMAIL_PAGE: {
      logEvent(Events.VIEW_CLIENT_SIGNUP_EMAIL_PAGE);
      break;
    }

    case Events.VIEW_CLIENT_SIGNUP_ORG_PAGE: {
      logEvent(Events.VIEW_CLIENT_SIGNUP_ORG_PAGE);
      break;
    }

    case Events.CLICK_CLIENT_SIGNUP_ORG_DETAILS: {
      logEvent(Events.CLICK_CLIENT_SIGNUP_ORG_DETAILS);
      break;
    }

    case Events.VIEW_CLIENT_SIGNUP_EXISTING_ERROR: {
      logEvent(Events.VIEW_CLIENT_SIGNUP_EXISTING_ERROR);
      break;
    }

    case Events.VIEW_CLIENT_INVITE_JOIN_PAGE: {
      logEvent(Events.VIEW_CLIENT_INVITE_JOIN_PAGE);
      break;
    }

    case Events.CLICK_CLIENT_INVITE_SIGNUP_AGREE_TERMS: {
      logEvent(Events.CLICK_CLIENT_INVITE_SIGNUP_AGREE_TERMS);
      break;
    }

    case Events.CLICK_CLIENT_INVITE_SIGNUP_JOIN: {
      logEvent(Events.CLICK_CLIENT_INVITE_SIGNUP_JOIN);
      break;
    }

    case Events.VIEW_CLIENT_INVITE_ORG_PAGE: {
      logEvent(Events.VIEW_CLIENT_INVITE_ORG_PAGE);
      break;
    }

    case Events.CLICK_CLIENT_INVITE_SIGNUP_ORG_DETAILS: {
      logEvent(Events.CLICK_CLIENT_INVITE_SIGNUP_ORG_DETAILS);
      break;
    }

    case Events.VIEW_CLIENT_INVITE_DASHBOARD: {
      logEvent(Events.VIEW_CLIENT_INVITE_DASHBOARD);
      break;
    }

    case Events.CLIENT_REFERRAL_COPY_LINK: {
      logEvent(Events.CLIENT_REFERRAL_COPY_LINK);
      break;
    }

    case Events.CLIENT_REFERRAL_EMAIL_LINK: {
      logEvent(Events.CLIENT_REFERRAL_EMAIL_LINK);
      break;
    }

    case Events.CLIENT_REFERRAL_LINKEDIN_LINK: {
      logEvent(Events.CLIENT_REFERRAL_LINKEDIN_LINK);
      break;
    }

    case Events.CLIENT_REFERRAL_TWITTER_LINK: {
      logEvent(Events.CLIENT_REFERRAL_TWITTER_LINK);
      break;
    }

    case Events.REFERRAL_CLIENT_SORT_ORDER: {
      const { sortBy } = action.payload as {
        sortBy: EmployerReferralsOrderByType;
      };
      logEvent(Events.REFERRAL_CLIENT_SORT_ORDER, {
        [EventsProperties.sort_filter]: CLIENT_REFERRAL_SORT_VALUES_MAP[sortBy],
      });
      break;
    }

    case Events.CLICK_REFERRAL_CLIENT_CSV: {
      logEvent(Events.CLICK_REFERRAL_CLIENT_CSV);
      break;
    }

    case Events.CLICK_SHARE_APPLICANT: {
      logEvent(Events.CLICK_SHARE_APPLICANT);
      break;
    }

    case Events.CLICK_SHARE_APPLICANT_BULK: {
      logEvent(Events.CLICK_SHARE_APPLICANT_BULK);
      break;
    }

    case Events.CLICK_SHARE_PROFILE: {
      logEvent(Events.CLICK_SHARE_PROFILE);
      break;
    }

    case Events.MY_TALENT_SORT_ORDER: {
      logEvent(Events.MY_TALENT_SORT_ORDER, action.payload);
      break;
    }

    case Events.FILTER_MY_TALENT_SEARCH: {
      logEvent(Events.FILTER_MY_TALENT_SEARCH, action.payload);
      break;
    }

    case Events.CLICK_MY_TALENT_SEARCH: {
      logEvent(Events.CLICK_MY_TALENT_SEARCH);
      break;
    }

    case Events.CLICK_MY_TALENT_VIEW_JOBS: {
      logEvent(Events.CLICK_MY_TALENT_VIEW_JOBS);
      break;
    }

    case Events.CLICK_MY_TALENT_SHOW_TALENT: {
      logEvent(Events.CLICK_MY_TALENT_SHOW_TALENT);
      break;
    }

    case Events.CLICK_MY_TALENT_SHOW_CONTRACTS: {
      logEvent(Events.CLICK_MY_TALENT_SHOW_CONTRACTS);
      break;
    }

    case Events.CLICK_MY_TALENT_COLUMNS: {
      logEvent(Events.CLICK_MY_TALENT_COLUMNS, action.payload);
      break;
    }

    case Events.CLICK_MY_TALENT_CSV: {
      logEvent(Events.CLICK_MY_TALENT_CSV);
      break;
    }

    case Events.INVOICE_SORT_ORDER: {
      logEvent(Events.INVOICE_SORT_ORDER, action.payload);
      break;
    }

    case Events.FILTER_INVOICES_SEARCH: {
      logEvent(Events.FILTER_INVOICES_SEARCH, action.payload);
      break;
    }

    case Events.CLICK_INVOICES_SEARCH: {
      logEvent(Events.CLICK_INVOICES_SEARCH);
      break;
    }

    case Events.CLICK_INVOICE_CSV: {
      logEvent(Events.CLICK_INVOICE_CSV, action.payload);
      break;
    }

    case Events.CLICK_DOWNLOAD_INVOICES: {
      logEvent(Events.CLICK_DOWNLOAD_INVOICES);
      break;
    }

    case Events.CLICK_INVOICES_COLUMNS: {
      logEvent(Events.CLICK_INVOICES_COLUMNS, action.payload);
      break;
    }

    default: {
      break;
    }
  }
};
