import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { FormValue } from "@braintrust/braintrust-ui-components";
import { transformBudgetOptions } from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import { OptionTileButtons } from "@js/components/option-tile-buttons";
import type { OptionWithPrimitiveValue } from "@js/types/common";

import { CustomRateButton } from "./components";

import styles from "./styles.module.scss";

export type BudgetFieldProps = WrappedFieldProps & {
  options: OptionWithPrimitiveValue[];
  onChange?: (data: FormValue) => void;
  showCheckIcon?: boolean;
};

export const BudgetField = ({
  input,
  meta,
  options,
  onChange,
  showCheckIcon,
}: BudgetFieldProps) => {
  const paymentButtonsDisabled = !SETTINGS.GIVE_GET_HELP_BTRST_BUTTONS_ENABLED;
  const paymentButtonsHidden = SETTINGS.GIVE_GET_HELP_BTRST_BUTTONS_HIDDEN;

  const processedOptions = useMemo(() => {
    const transformedOptions = transformBudgetOptions(
      options,
      paymentButtonsDisabled,
      paymentButtonsHidden,
    );

    return transformedOptions;
  }, [options, paymentButtonsDisabled, paymentButtonsHidden]);

  const handleRateChange = (data: FormValue) => {
    if (data === input.value) {
      input.onChange(null);
    } else {
      input.onChange(data);
      onChange?.(data);
    }
  };

  return (
    <OptionTileButtons
      value={input.value}
      onChange={handleRateChange}
      error={meta.error}
      options={processedOptions}
      className={styles.wrapper}
      selectedClassName="selected"
      selectedVariant="medium-violet"
      showCheckIcon={showCheckIcon}
      renderCustomOptionButton={({
        isSelected,
        onClick,
        itemA11yProps,
        handleChange,
        setActiveIndex,
      }) => {
        if (paymentButtonsDisabled || paymentButtonsHidden) {
          return;
        }

        return (
          <CustomRateButton
            options={options}
            input={input}
            meta={meta}
            onClick={onClick}
            isSelected={isSelected}
            handleChange={handleChange}
            itemA11yProps={itemA11yProps}
            setActiveIndex={setActiveIndex}
            showTooltip
            showCheckIcon={showCheckIcon}
          />
        );
      }}
    />
  );
};
