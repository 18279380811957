import { reducer as formReducer } from "redux-form";

import { API } from "@js/api";
import SavedFiltersReducer from "@js/apps/common/components/save-filters/reducer";
import { profileCompletionSuccessSlice } from "@js/apps/freelancer/hooks/use-profile-completion-success/store/slice";
import { reviewsModalAutoOpenSlice } from "@js/apps/reviews/slice";

import adminReducer from "./apps/admin/reducer";
import AuthReducer from "./apps/auth/reducer";
import BookmarksReducer from "./apps/bookmarks/reducer";
import CommonReducer from "./apps/common/reducer";
import UploadsReducer from "./apps/common/uploads/reducer";
import EmployerReducer from "./apps/employer/reducer";
import FreelancerReducer from "./apps/freelancer/reducer";
import { stickersModuleSlice } from "./apps/give-and-get-help/components/stickers-module/store/stickers-module-slice";
import GghActionMenuReducer from "./apps/give-and-get-help/hooks/post-action-menu-actions/reducer";
import { postsCommentsSlice } from "./apps/give-and-get-help/posts-comments-slice";
import InvoicesReducer from "./apps/invoices/reducer";
import BidBulkSelectionReducer from "./apps/jobs/apps/bids/components/bulk-select-actions/bid-bulk-selection-slice";
import JobsReducer from "./apps/jobs/reducer";
import NotificationsReducer from "./apps/notifications/reducer";
import OnrampReducer from "./apps/on-ramp/reducer";
import { searchEventSlice } from "./apps/tracking/search-event-slice";
import { universalSearchSlice } from "./apps/universal-search/universal-search-slice";
import WithdrawalReducer from "./apps/withdrawal/reducer";
import ModalReducer from "./components/modal/reducer";
import ModalSliderReducer from "./components/modal-slider/reducer";
import RequestLoaderReducer from "./components/request-loader/reducer";
import WebSocketManagerReducer from "./components/websocket/reducer";

export const appReducer = {
  [API.reducerPath]: API.reducer,
  form: formReducer,
  admin: adminReducer,
  auth: AuthReducer,
  bidBulkSelection: BidBulkSelectionReducer,
  common: CommonReducer,
  employer: EmployerReducer,
  freelancer: FreelancerReducer,
  invoices: InvoicesReducer,
  jobs: JobsReducer,
  modal: ModalReducer,
  modalSlider: ModalSliderReducer,
  notifications: NotificationsReducer,
  onramp: OnrampReducer,
  requestLoader: RequestLoaderReducer,
  savedFilters: SavedFiltersReducer,
  uploads: UploadsReducer,
  websocketManager: WebSocketManagerReducer,
  withdrawal: WithdrawalReducer,
  bookmarks: BookmarksReducer,
  gghActionsMenu: GghActionMenuReducer,
  stickersModule: stickersModuleSlice.reducer,
  postsComments: postsCommentsSlice.reducer,
  universalSearch: universalSearchSlice.reducer,
  searchEvent: searchEventSlice.reducer,
  reviewsModals: reviewsModalAutoOpenSlice.reducer,
  profileCompletionSuccessModal: profileCompletionSuccessSlice.reducer,
};
