import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  Button,
  Loader,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";
import { Snackbar } from "@js/components/snackbar";
import type { Job } from "@js/types/jobs";
import { pluralize } from "@js/utils";

import { useUpdateEmployerBidInBulkMutation } from "../../api";
import { expectModal } from "../air-ai-what-to-expect-modal";
import { MessageInput } from "../make-offer-message/input";

export type AiInterviewRequestMessageProps = {
  bids: number[];
  refetchBidList: RefetchBidList;
  closeModal: () => void;
  job: Job;
};

type FormValues = { message: string };

const defaultFormValues: FormValues = {
  message:
    `Hi 👋,\n\n` +
    `Thanks for applying to this role.\n` +
    `We'd love to invite you to a first round interview with Braintrust AIR.`,
};

export const AiInterviewRequestMessage = ({
  bids,
  refetchBidList,
  closeModal,
  job,
}: AiInterviewRequestMessageProps) => {
  const user = useUser();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [updateEmployerBidsInBulk] = useUpdateEmployerBidInBulkMutation();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: defaultFormValues,
  });

  if (!user) return null;

  const handleSendInterviewError = () => {
    Snackbar.error(
      "Updating status failed. Please refresh the page and try again",
    );
  };

  const handleSendInterview = async (message?: string) => {
    if (hasSubmitted) {
      return;
    }

    setHasSubmitted(true);
    try {
      await updateEmployerBidsInBulk({
        status: ENUMS.BidStatus.INTERVIEWING,
        pks: bids,
        ai_interview_note: message,
      }).unwrap();

      Snackbar.success(`AI Interview request${pluralize(bids.length)} sent`, {
        transitionDuration: {
          enter: 200,
          exit: 200,
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      refetchBidList();
      closeModal();

      if (job.bids_count.interviewing_bids_count === 0) {
        expectModal.openAnnouncement();
      }
    } catch (_error) {
      handleSendInterviewError();
      refetchBidList();
      closeModal();
    }
  };

  const onSubmit = async (values: FormValues) => {
    const { message } = values;
    await handleSendInterview(message);
  };

  const handleSendInterviewWithoutMessage = () => {
    handleSendInterview();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack p={3}>
        <Typography component="h2" variant="label">
          Send AI Interview request to Talent
        </Typography>
        <Typography component="p" size="small" mb={2}>
          ✍️ Personalize your message below
        </Typography>
        <Box mb={2}>
          <Controller
            name="message"
            control={control}
            rules={{
              maxLength: {
                value: 600,
                message: "Must be 600 characters or less",
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <MessageInput
                  value={value}
                  onChange={onChange}
                  displayError={!!error?.message}
                  error={error?.message}
                  minRows={4}
                />
              );
            }}
          />
        </Box>

        <Stack
          sx={{ justifyContent: "end", gap: 1, alignItems: "center" }}
          direction="row"
        >
          <Button
            type="button"
            variant="transparent"
            shape="squared"
            onClick={handleSendInterviewWithoutMessage}
          >
            Send without note
          </Button>

          <Button type="submit" variant="medium-green" shape="squared">
            Send
          </Button>
        </Stack>
        {hasSubmitted && <Loader centered />}
      </Stack>
    </form>
  );
};
