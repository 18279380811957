import React from "react";

type OfferPreviewContentLayoutProps = {
  leftSocket: React.ReactNode;
  rightSocket: React.ReactNode;
};

export const OfferPreviewContentLayout = ({
  leftSocket,
  rightSocket,
}: OfferPreviewContentLayoutProps) => {
  return (
    <div className="preview-offer">
      <div className="preview-offer__left-col">{leftSocket}</div>
      <div className="preview-offer__right-col">{rightSocket}</div>
    </div>
  );
};
