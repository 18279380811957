import { useMemo, useState } from "react";
import cs from "classnames";

import { Typography } from "@braintrust/braintrust-ui-components";
import { TextMessageActionMenu } from "@js/apps/messenger/components/message/text-message-action-menu";
import { Linkify } from "@js/components/linkify";
import { IMPLICIT_ANCHOR_WARNING_ATTR } from "@js/services";
import type { Message } from "@js/types/messenger";
import { isValidUrl } from "@js/utils";

import { INVITE_MESSAGE_MATCHERS } from "../../constants";
import { useMessageRedirectWarning } from "../../hooks/use-message-redirect-warning";

import styles from "./styles.module.scss";

type TextMessageVariants = "soft-grey" | "teal" | "medium-red" | "soft-red";

type TextMessageProps = {
  message: Message;
  variant: TextMessageVariants;
  isOwner?: boolean;
  children?: React.ReactNode;
  linkify?: boolean;
  shouldDisplayRedirectWarning?: boolean;
};

export const TextMessage = ({
  message,
  isOwner,
  variant,
  children,
  linkify = true,
  shouldDisplayRedirectWarning,
}: TextMessageProps): JSX.Element | null => {
  const [showMoreButton, setShowMoreButton] = useState(false);

  if (!message.content) {
    return null;
  }

  return (
    <Typography
      component="div"
      className={cs(styles.contentInner, styles[`text-message--${variant}`])}
      onMouseEnter={() => setShowMoreButton(true)}
      onMouseLeave={() => setShowMoreButton(false)}
    >
      <TextMessageActionMenu
        isOwner={isOwner}
        showMoreButton={showMoreButton}
        setShowMoreButton={setShowMoreButton}
        messageId={message.id}
        roomId={message.room}
        messageAuthorName={message.author.public_name}
      />
      <ParsedMessage
        messageData={message}
        linkify={linkify}
        shouldDisplayRedirectWarning={!!shouldDisplayRedirectWarning}
      />

      {children}
    </Typography>
  );
};

const LinkifiedMessage = ({ message }: { message: string }) => {
  return (
    <div>
      <Linkify
        options={{
          attributes: {
            noTextDecoration: false,
            fontWeight: 400,
          },
        }}
      >
        {message}
      </Linkify>
    </div>
  );
};

type ParsedMessageProps = {
  messageData: Message;
  linkify?: boolean;
  shouldDisplayRedirectWarning: boolean;
};

const ParsedMessage = (props: ParsedMessageProps) => {
  const { messageData } = props;
  const message = messageData.content;
  if (!message) {
    return <></>;
  }

  const messageRelatedToJob = convertRelatedToJobStringToLink(message);
  if (messageRelatedToJob) {
    return messageRelatedToJob;
  }

  const messageRelatedToInvite = convertInviteUrlToLink(message);
  if (messageRelatedToInvite) {
    return messageRelatedToInvite;
  }

  return <RegularMessage {...props} />;
};

const convertRelatedToJobStringToLink = (
  messageContent: string,
): string | JSX.Element => {
  const regex = new RegExp(
    SETTINGS.MESSAGE_TO_MATCHER_IN_JOB_CONTEXT_PREFIX + " \\d+",
  );
  const matchedContentWithJobId = messageContent.match(regex);

  if (!matchedContentWithJobId) return "";

  const matchedJobId = matchedContentWithJobId[0].match(/\d+/);
  if (!matchedJobId) return "";

  const jobId = matchedJobId[0];
  const jobTitleString = `${SETTINGS.MESSAGE_TO_MATCHER_IN_JOB_CONTEXT_PREFIX} ${jobId}`;
  const jobLink = `/jobs/${jobId}`;
  const otherPartOfMessage = messageContent.replace(jobTitleString, "");

  return (
    <>
      <a href={jobLink}>{jobTitleString}</a> {otherPartOfMessage}
    </>
  );
};

const convertInviteUrlToLink = (
  messageContent: string,
): string | JSX.Element => {
  if (!messageHasLink(messageContent)) {
    return "";
  }

  const isSystemGeneratedMessage = INVITE_MESSAGE_MATCHERS.some((matcher) =>
    messageContent.includes(matcher),
  );
  if (!isSystemGeneratedMessage) {
    return "";
  }
  return <LinkifiedMessage message={messageContent} />;
};

type LinkifiedMessageProps = {
  messageData: Message;
};

const LinkifiedMessageWithWarning = ({
  messageData,
}: LinkifiedMessageProps) => {
  const { anchorWrapperRef } = useMessageRedirectWarning(messageData);
  const message = messageData.content;
  return (
    <div ref={anchorWrapperRef}>
      <Linkify
        options={{
          attributes: {
            [IMPLICIT_ANCHOR_WARNING_ATTR]: true,
            noTextDecoration: false,
            fontWeight: 400,
          },
        }}
      >
        {message}
      </Linkify>
    </div>
  );
};

const RegularMessage = ({
  messageData,
  linkify,
  shouldDisplayRedirectWarning,
}: ParsedMessageProps) => {
  const message = messageData.content;
  const isLinkIncluded = useMemo(() => {
    if (!message) {
      return false;
    }
    return messageHasLink(message);
  }, [message]);

  if (!linkify || !isLinkIncluded || !message) {
    return message;
  }

  if (shouldDisplayRedirectWarning) {
    return <LinkifiedMessageWithWarning messageData={messageData} />;
  }

  return <LinkifiedMessage message={message} />;
};

const messageHasLink = (message: string) => {
  return message.split(/\s+/).some((word) => isValidUrl(word));
};
