import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import { emailVerified, registerFinish } from "@js/apps/auth/actions";
import { useGetUnfinishedUserQuery } from "@js/apps/auth/api";
import { useSendConnectorSignupData } from "@js/apps/auth/hooks/send-connector-signup-data";
import { useAccountType } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useQueryParams } from "@js/hooks";
import { LocalStorage } from "@js/services";
import { typeGuard } from "@js/utils";

import { useHandleAutoPublishDrafts } from "./use-handle-auto-publish-drafts";
import { useRedirectAfterSignUp } from "./use-redirect-after-signup";

export const useSignUpFinish = () => {
  const dispatch = useAppDispatch();
  const { isEmployer } = useAccountType();
  const { action, key, amplitude_device_id } = useQueryParams();
  const {
    data: unfinishedUser,
    isLoading: isUnfinishedUserLoading,
    error,
  } = useGetUnfinishedUserQuery(
    (action === ENUMS.ConfirmAction.CONFIRM_SIGNUP_EMAIL && key) ?? skipToken,
  );
  const { sendSignupConnectorData } = useSendConnectorSignupData();
  const { handlePublishDrafts } = useHandleAutoPublishDrafts();
  const redirectAfterSignUp = useRedirectAfterSignUp(unfinishedUser);

  const finaliseRegistration = async () => {
    await Promise.all([
      ...(amplitude_device_id
        ? [dispatch(emailVerified(amplitude_device_id))]
        : []),
      dispatch(registerFinish({ confirmation_key: key })),
      sendSignupConnectorData(),
    ]);
    LocalStorage.removeItem(
      LocalStorage.keys.REGISTRATION_EMAIL_LOCAL_STORAGE_KEY,
    );
  };

  useEffect(() => {
    if (!unfinishedUser || unfinishedUser.user_is_already_registered) {
      return;
    }

    const finaliseSignup = async () => {
      try {
        await finaliseRegistration();
        if (isEmployer) {
          await handlePublishDrafts();
        }

        redirectAfterSignUp();
      } catch (_error) {
        Snackbar.error("Something went wrong please try again");
      }
    };

    finaliseSignup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfinishedUser, key, amplitude_device_id, action]);

  return {
    loading: isUnfinishedUserLoading,
    unverifiedUser: unfinishedUser,
    signUpError: formatError(error),
  };
};

const formatError = (error: unknown): undefined | string => {
  if (!error) {
    return;
  }

  if (
    !typeGuard<unknown, { data: unknown }>(error, "data") ||
    !typeGuard<unknown, { confirmation_key: unknown }>(
      error.data,
      "confirmation_key",
    ) ||
    typeof error.data.confirmation_key !== "string"
  ) {
    return "Something went wrong";
  }

  return error.data.confirmation_key;
};
