import { z } from "zod";

import { API } from "@js/api";
import type { Address } from "@js/types/common";
import type {
  EmployerInvoiceMinimal,
  EmployerInvoicesStatusCount,
  FreelancerInvoice,
} from "@js/types/invoices";
import { getOrderingParam } from "@js/utils";

import type {
  CalculateEmployerInvoicesCreditCardFeeParams,
  EmployerInvoicesCreditCardFee,
  GetAllEmployerInvoicesIdsParams,
  GetEmployerConsolidatedInvoicesParams,
  GetEmployerConsolidatedInvoicesResponse,
  GetEmployerInvoicesMinimalParams,
  GetEmployerInvoicesParams,
  GetEmployerInvoicesResponse,
  GetEmployerInvoiceStatisticsResponse,
  GetPurchasesResponse,
} from "./types";
import {
  processEmployerInvoicesCommonParams,
  processEmployerInvoicesParams,
} from "./utils";

const EmployerMinimalSchema = z.object({
  name: z.string(),
  id: z.number(),
});

export const FreelancerJobToIssueInvoiceSchema = z.object({
  employer: EmployerMinimalSchema,
  id: z.number(),
  invoice_can_be_created: z.boolean(),
  invoice_cannot_be_created_reason: z.string(),
  title: z.string(),
});

export type FreelancerJobToIssueInvoice = z.infer<
  typeof FreelancerJobToIssueInvoiceSchema
>;

export const InitialInvoiceDataSchema = z.object({
  company_node: z.object({
    name: z.string(),
    slug: z.string(),
  }),
  new_invoice_number: z.string(),
  po_number_required_since: z.union([z.string(), z.null()]),
  previous_po_numbers: z.array(z.string()),
});

export type InitialInvoiceData = z.infer<typeof InitialInvoiceDataSchema>;

type InitialInvoiceDataRequestPayload = { job: number };

export const invoiceApi = API.injectEndpoints({
  endpoints: (build) => ({
    getFreelancerInvoice: build.query<FreelancerInvoice, number>({
      query: (id) => ({
        url: `/freelancer_invoices/${id}/`,
        method: "GET",
      }),
    }),

    getFreelancerInvoices: build.query({
      query: ({ page }) => ({
        url: `/freelancer_invoices/?page_size=5&page=${page}`,
        method: "GET",
      }),
    }),

    getPurchases: build.query<
      GetPurchasesResponse,
      { page?: number; page_size?: number }
    >({
      query: ({ page, page_size }) => ({
        url: `/user/purchases/?page=${page ?? 1}&page_size=${page_size ?? 5}`,
        method: "GET",
      }),
      providesTags: ["UpgradePurchases"],
    }),
    getFreelancerJobsToIssueInvoice: build.query<
      FreelancerJobToIssueInvoice[],
      void
    >({
      query: () => ({
        url: "/freelancer_jobs_select_project/",
        method: "GET",
      }),
    }),
    getInitialInvoiceData: build.query<
      InitialInvoiceData,
      InitialInvoiceDataRequestPayload
    >({
      query: ({ job }) => ({
        url: `/freelancer_invoices/initial_invoice_data/`,
        params: { job },
        method: "GET",
      }),
    }),
    getEmployerAddress: build.query<Address, number | "all">({
      query: (id) => ({
        url: `/freelancer_employers/${id === "all" ? "" : `${id}/`}`,
        method: "GET",
      }),
      transformResponse(response: { address: Address }) {
        return response.address;
      },
    }),
  }),
});

export const {
  useGetFreelancerInvoiceQuery,
  useGetFreelancerInvoicesQuery,
  useGetPurchasesQuery,
  useGetFreelancerJobsToIssueInvoiceQuery,
  useLazyGetInitialInvoiceDataQuery,
  useGetInitialInvoiceDataQuery,
  useGetEmployerAddressQuery,
  useLazyGetEmployerAddressQuery,
} = invoiceApi;

export const employerInvoicesApi = API.injectEndpoints({
  endpoints: (build) => ({
    getAllEmployerInvoicesIds: build.query<
      number[],
      GetAllEmployerInvoicesIdsParams | void
    >({
      query: (params) => {
        const processedParams = processEmployerInvoicesCommonParams(
          params ?? {},
        );

        return {
          url: `/employer_invoices/all_invoices_ids/`,
          method: "GET",
          params: processedParams,
        };
      },
      providesTags: ["EmployerInvoicesIds"],
    }),

    getEmployerInvoicesMinimal: build.query<
      EmployerInvoiceMinimal[],
      GetEmployerInvoicesMinimalParams
    >({
      query: (params) => ({
        url: `/employer_invoices_minimal/`,
        method: "GET",
        params: {
          ids: params.ids.join(","),
        },
      }),
      providesTags: ["EmployerInvoicesMinimal"],
    }),

    getEmployerInvoiceStatistics: build.query<
      GetEmployerInvoiceStatisticsResponse,
      void
    >({
      query: () => ({
        url: `/invoice_statistics/employer/`,
        method: "GET",
      }),
      providesTags: ["EmployerInvoiceStatistics"],
    }),

    getEmployerInvoices: build.query<
      GetEmployerInvoicesResponse,
      GetEmployerInvoicesParams
    >({
      query: (params) => {
        const processedParams = processEmployerInvoicesParams(params);

        return {
          url: `/employer_invoices/`,
          method: "GET",
          params: processedParams,
        };
      },
      providesTags: [{ type: "EmployerInvoices", id: "LIST" }],
    }),

    calculateEmployerInvoicesCreditCardFee: build.mutation<
      EmployerInvoicesCreditCardFee,
      CalculateEmployerInvoicesCreditCardFeeParams
    >({
      query: (data) => ({
        url: `/employer_invoices/calculate_credit_card_fee/`,
        method: "POST",
        data,
      }),
    }),

    getEmployerInvoicesStatusCounts: build.query<
      EmployerInvoicesStatusCount[],
      void
    >({
      query: () => ({
        url: `/employer_invoices/status_counts/`,
        method: "GET",
      }),
      providesTags: ["EmployerInvoiceStatusCounts"],
    }),

    getEmployerConsolidatedInvoices: build.query<
      GetEmployerConsolidatedInvoicesResponse,
      GetEmployerConsolidatedInvoicesParams
    >({
      query: (params) => {
        const { order_by, order_dir, ...rest } = params;
        const ordering = getOrderingParam({ order_by, order_dir });

        return {
          url: `/employer_consolidated_invoices/`,
          method: "GET",
          params: { ordering, ...rest },
        };
      },
      providesTags: [{ type: "EmployerConsolidatedInvoices", id: "LIST" }],
    }),
  }),
});

export const {
  useLazyGetAllEmployerInvoicesIdsQuery,
  useGetEmployerInvoicesMinimalQuery,
  useLazyGetEmployerInvoicesMinimalQuery,
  useGetEmployerInvoiceStatisticsQuery,
  useGetEmployerInvoicesQuery,
  useGetEmployerInvoicesStatusCountsQuery,
  useGetEmployerConsolidatedInvoicesQuery,
  useCalculateEmployerInvoicesCreditCardFeeMutation,
} = employerInvoicesApi;
