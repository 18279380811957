import { useCallback, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import { useAIRInterview } from "@js/apps/jobs/hooks/use-air-interview";
import { JobMessenger } from "@js/apps/messenger/messengers/jobs";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { JobBidResumeViewer } from "../../bid-resume-viewer";

import { BidDetailsApplicationTab } from "./bid-details-application-tab";
import { BidInterviewTab } from "./bid-interview-tab";

const TABS = {
  APPLICATION: {
    label: "Application",
    param: "application",
    component: BidDetailsApplicationTab,
  },
  INTERVIEW: {
    label: "Interview",
    param: "interview",
    component: BidInterviewTab,
  },
  RESUME: {
    label: "Resume",
    param: "resume",
    component: JobBidResumeViewer,
  },
  MESSAGES: {
    label: "Messages",
    param: "messages",
    component: JobMessenger,
  },
} as const;

type TabData = ObjectValues<typeof TABS>;

export const BidTabs = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid;
  job: Job;
}) => {
  const { showAIRInterview } = useAIRInterview();
  const [params, setParams] = useSearchParams();
  const { state } = useLocation();
  const currentTabParam = params.get("tab");

  const updateTabParam = useCallback(
    (tabParam: string) => {
      const current = params.get("tab");
      if (current === tabParam) {
        return;
      }
      params.delete("tab");
      const prevParams = params.toString();

      const newParams = !!prevParams.length
        ? `?${prevParams}&tab=${tabParam}`
        : `?tab=${tabParam}`;

      setParams(newParams, {
        replace: true,
        state: { ...state, noScroll: true },
      });
    },
    [params, setParams, state],
  );

  const availableTabs = useMemo(() => {
    const tabs: TabData[] = [
      TABS.APPLICATION,
      !!bid.interview?.is_completed && showAIRInterview ? TABS.INTERVIEW : null,
      TABS.RESUME,
      !!bid?.freelancer.user.can_be_messaged ? TABS.MESSAGES : null,
    ].filter((tabData): tabData is TabData => Boolean(tabData));

    return tabs;
  }, [bid, showAIRInterview]);

  const tab = useMemo(() => {
    const defaultParam = availableTabs[0].param;
    if (!currentTabParam) {
      return defaultParam;
    }

    const isValidTab = availableTabs.some(
      (availableTab) => availableTab.param === currentTabParam,
    );
    if (!isValidTab) {
      return defaultParam;
    }

    return currentTabParam;
  }, [currentTabParam, availableTabs]);

  const currentTab = useMemo(
    () => availableTabs.find((i) => i.param === tab),
    [tab, availableTabs],
  );

  return (
    <Box>
      <Box display="flex" columnGap={4} flexWrap="wrap" rowGap={2}>
        {availableTabs.map((option) => {
          const isActive = option.param === tab;

          return (
            <Button
              variant="transparent"
              key={option.param}
              size="small"
              onClick={() => updateTabParam(option.param)}
              style={{
                padding: 0,
              }}
            >
              <Typography
                sx={{
                  color: isActive ? "var(--black)" : "var(--grey-2)",
                  boxShadow: isActive ? "0 2px 0 0 #000000" : undefined,
                }}
                pb={1}
                component="h4"
                variant="label"
                size="medium"
              >
                {option.label}
              </Typography>
            </Button>
          );
        })}
      </Box>
      <Box mt={3}>
        {bid && currentTab?.component ? (
          <currentTab.component bid={bid} job={job} />
        ) : (
          currentTab?.label
        )}
      </Box>
    </Box>
  );
};
