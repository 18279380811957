import type { RefetchBidList } from "@js/apps/jobs/context/refetch-bids-context";
import { ModalInstance } from "@js/components/modal";

import type { AiInterviewRequestMessageProps } from "../interview-request-message";
import { AiInterviewRequestMessage } from "../interview-request-message";

import style from "./style.module.scss";

type OpenAiInterviewRequestMessageArg = Omit<
  AiInterviewRequestMessageProps,
  "closeModal" | "refetchBidList"
> & {
  refetchBidList?: RefetchBidList | null;
};

export const openAiInterviewRequestMessage = ({
  job,
  refetchBidList,
  bids,
}: OpenAiInterviewRequestMessageArg) => {
  if (!refetchBidList) return;

  ModalInstance.open({
    className: style.aiInterviewMessageModal,
    keepOnBackdropClick: true,
    children: (
      <AiInterviewRequestMessage
        refetchBidList={refetchBidList}
        bids={bids}
        job={job}
        closeModal={ModalInstance.close}
      />
    ),
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        marginRight: "-8px",
        top: 0,
        right: "12px",
        padding: "16px",
      },
    },
  });
};
