import cs from "classnames";

import {
  Box,
  Divider,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { openLocationMismatchModal } from "@js/apps/admin/components/location-mismatch-modal";
import { RatingCard } from "@js/apps/common/components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { LocationWarning } from "@js/apps/freelancer/components/location-warning";
import { LimitedFreelancerLinks } from "@js/apps/jobs/apps/bids/components";
import { JobSkillChip } from "@js/apps/skills/components/";
import { RouterLink } from "@js/components/link";
import type { Job, TalentInviteSuggestion } from "@js/types/jobs";

import { InviteTalentMatchScoreBadge } from "../../apps/bids/components/invite-talent-match-score-badge";
import { CannotMakeAction } from "../cannot-make-action";
import { InviteToJobButton } from "../invite-to-job-button";
import { LookingForWorkBadge } from "../looking-for-work-badge";
import { ViewProfileButton } from "../view-profile-button";

import {
  ActionMenu,
  InviteAvatarWithScoreMatch,
  InviteTalentStatusLabel,
} from "./components";

type JobBidListItemProps = {
  freelancer: TalentInviteSuggestion;
  job: Job;
  notificationHandler: () => void;
};

export const InviteTalentCardItem = ({
  freelancer,
  job,
  notificationHandler,
}: JobBidListItemProps) => {
  const isMobile = useMediaQuery(1280);
  const isNodeStaff = useIsNodeStaff();
  const matchedSkills = freelancer.matching_skills;
  const availabilityForWork = freelancer.availability_for_work;
  const isLocationMismatched =
    !!freelancer.location_mismatch || !freelancer.location;
  const displayLocationMismatch = isLocationMismatched && isNodeStaff;
  const handleLocationWarningClick = () => {
    openLocationMismatchModal({ userId: freelancer.id });
  };

  return (
    <Box
      className={cs("invite-talent-card", {
        "invite-talent-card--invited": freelancer.invite_status,
      })}
    >
      <Box className="invite-talent-card__top-section">
        <Box
          sx={{
            display: "flex",
            width: { xs: "100%", sm: "auto" },
            minWidth: 0,
            alignItems: "center",
          }}
        >
          <Box minWidth={56} width={56} height={56}>
            <RouterLink to={freelancer.link} target="_blank">
              <InviteAvatarWithScoreMatch freelancer={freelancer} />
            </RouterLink>
          </Box>
          <Box className="invite-talent-card__name">
            <Stack sx={{ minWidth: 0 }}>
              <Typography
                ellipsis
                variant="label"
                size="medium"
                component="h3"
                noTextDecoration
                to={freelancer.link}
                target="_blank"
                sx={{ display: "block" }}
                RouterLink={RouterLink}
              >
                {freelancer.user.public_name}
              </Typography>
              <Stack direction="row">
                <Typography variant="paragraph" size="small" component="h4">
                  {freelancer?.role?.name}
                </Typography>
                {(freelancer.location || displayLocationMismatch) && (
                  <Typography
                    variant="paragraph"
                    size="small"
                    component="h4"
                    display="flex"
                    gap={0.5}
                  >
                    {freelancer?.role?.name && (
                      <Box component="span" pl={0.5}>
                        -
                      </Box>
                    )}
                    {freelancer.location}
                    {displayLocationMismatch && (
                      <LocationWarning onClick={handleLocationWarningClick} />
                    )}
                  </Typography>
                )}
              </Stack>
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <InviteTalentMatchScoreBadge
                  freelancer={freelancer}
                  job={job}
                  notificationHandler={notificationHandler}
                />
                <LookingForWorkBadge
                  availabilityForWork={availabilityForWork}
                  freelancer={freelancer}
                />
              </Box>
            </Stack>
          </Box>
        </Box>

        {isMobile && !!freelancer.external_profiles.length && (
          <Divider color="beige" style={{ margin: "8px 0" }} />
        )}

        <Box className="invite-talent-card__links-status">
          <InviteTalentStatusLabel status={freelancer.invite_status} />
          <LimitedFreelancerLinks
            externalProfiles={freelancer.external_profiles}
          />
        </Box>
      </Box>

      <Box>
        <Divider color="beige" style={{ marginBottom: "12px" }} />

        <Box className="invite-talent-card__skills">
          {!!matchedSkills.length && (
            <Box display="flex" flexWrap="wrap" mr="auto" gap={1}>
              {matchedSkills.map((item) => {
                return <JobSkillChip key={item.id} skill={item.skill} />;
              })}
            </Box>
          )}
          <Box display="flex" flexWrap="wrap" flexShrink={{ md: 0 }} gap={1}>
            <ViewProfileButton profileLink={freelancer.link} />
            <Box display="flex" gap={1}>
              <CannotMakeAction job={job}>
                <InviteToJobButton
                  freelancer={freelancer}
                  jobId={job.id}
                  disabled={!job.is_open}
                  notificationHandler={notificationHandler}
                />
              </CannotMakeAction>
              <ActionMenu jobId={job.id} freelancer={freelancer} />
            </Box>
          </Box>
        </Box>

        <Box mt={1}>
          <RatingCard freelancer={freelancer} />
        </Box>
      </Box>
    </Box>
  );
};
