import { useState } from "react";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { fetchWithdrawalMethods } from "../actions";

export const useFetchWithdrawalMethods = (options?: { skip?: boolean }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useAppDispatch();
  const isFetchingWithdrawalMethods = useAppSelector(
    (state) => state.withdrawal.fetchingWithdrawalMethods,
  );
  const withdrawalMethods = useAppSelector(
    (state) => state.withdrawal.withdrawalMethods,
  );

  useEffectOnce(() => {
    if (!options?.skip) {
      dispatch(fetchWithdrawalMethods())
        .catch(() => {})
        .finally(() => setFetched(true));
    }
  });

  return { isFetchingWithdrawalMethods, withdrawalMethods, fetched };
};
