import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { SelectProps } from "@braintrust/braintrust-ui-components";
import { Select } from "@braintrust/braintrust-ui-components";

type RhfSelectProps<T extends FieldValues> = SelectProps &
  UseControllerProps<T> & {
    id: SelectProps["id"];
    label: SelectProps["label"];
    control: Control<T>;
  };

export const RhfSelect = <T extends FieldValues>({
  id,
  label,
  helperText,
  ...props
}: RhfSelectProps<T>) => {
  const { field, fieldState } = useController(props);
  const value = field.value ?? "";

  return (
    <Select
      id={id}
      label={label}
      variant="outlined-new"
      {...props}
      {...field}
      value={value}
      ref={undefined}
      error={fieldState.error?.message}
      helperText={fieldState.error?.message || helperText}
    />
  );
};
