import { useMemo } from "react";
import _ from "underscore";

import {
  Box,
  Button,
  Divider,
  RoundedBox,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { openEnableOTPAuthModal } from "@js/apps/settings/components/otp-auth";
import {
  AddW8Modal,
  AddW9Modal,
} from "@js/apps/settings/components/tax-documents";
import { AddWithdrawalMethodModal } from "@js/apps/withdrawal/components";
import { useOpenChooseWithdrawalMethodModal } from "@js/apps/withdrawal/hooks";
import { useFetchWithdrawalMethods } from "@js/apps/withdrawal/hooks";
import { RouterLink } from "@js/components/link";
import { useAppSelector, useNavigate } from "@js/hooks";
import { isNotNullable } from "@js/utils";

import type { ChecklistAction } from "./checklist-container";
import { ChecklistContainer } from "./checklist-container";
import {
  CHECKLIST_ITEMS,
  reviewInvoicingAction,
  reviewPaymentTermsAction,
} from "./const";

type OnboardingChecklistProps = {
  filterActions?: (actions: ChecklistAction[]) => ChecklistAction[];
  skipFetchingWithdrawalMethods?: boolean;
};

export const OnboardingChecklist = ({
  filterActions,
  skipFetchingWithdrawalMethods,
}: OnboardingChecklistProps) => {
  const navigate = useNavigate();

  const {
    defaultWithdrawalMethod,
    isOTPEnabled,
    isW8FormRequired,
    isW9FormRequired,
    address,
    isTaxFormSubmitted,
  } = useOnboardingStatus();

  const { onChooseWithdrawalMethod } = useOpenChooseWithdrawalMethodModal();

  useFetchWithdrawalMethods({ skip: skipFetchingWithdrawalMethods });

  const ACTIONS: ChecklistAction[] = [
    reviewPaymentTermsAction,
    reviewInvoicingAction,
    {
      type: CHECKLIST_ITEMS.TWO_FACTOR,
      header: "Set up 2FA before submitting your first invoice",
      isCompleted: isOTPEnabled,
      action: isOTPEnabled ? false : openEnableOTPAuthModal,
    },
    {
      type: CHECKLIST_ITEMS.BANKING,
      header: "Set up banking information",
      isCompleted: !!defaultWithdrawalMethod,
      action: defaultWithdrawalMethod
        ? false
        : isOTPEnabled
          ? onChooseWithdrawalMethod
          : openEnableOTPAuthModal,
    },
    {
      type: CHECKLIST_ITEMS.TAX_DOCS,
      header: "Add tax documentation to your account",
      isCompleted: isTaxFormSubmitted,
      action: address
        ? isW8FormRequired
          ? AddW8Modal.open
          : isW9FormRequired
            ? AddW9Modal.open
            : undefined
        : () => navigate("/settings/payments/"),
    },
  ];

  const filteredActions = (
    filterActions ? filterActions(ACTIONS) : ACTIONS
  ).filter(isNotNullable);

  return (
    <div>
      <AddWithdrawalMethodModal />
      <ChecklistContainer actions={filteredActions} />
      <AddW8Modal>
        <p className="mb+">
          All residents outside of U.S must provide their W-8 tax information.
          Need a W-8 form?{" "}
          <Button
            style={{
              fontSize: 16,
              margin: "-8px 0",
            }}
            variant="transparent"
            color="secondary"
            target="_blank"
            rel="noopener noreferrer"
            download="fw8ben"
            href={`${SETTINGS.STATIC_URL}pdf/fw8ben.pdf`}
            RouterLink={RouterLink}
          >
            Download a copy of the form
          </Button>
        </p>
      </AddW8Modal>
      <AddW9Modal />
    </div>
  );
};

export const SignUpBYOTStaticChecklist = () => {
  return (
    <RoundedBox sx={{ border: "1px solid var(--soft-beige)" }}>
      <Typography component="h2" variant="label" size="large">
        ✏️ Braintrust checklist
      </Typography>
      <Box py={2}>
        <Typography component="h3" variant="paragraph">
          Review payment terms
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          sx={{ color: "var(--grey-2)" }}
        >
          Review terms and conditions, payment terms, and frequency for this job
        </Typography>
      </Box>
      <Divider color="beige" />
      <Box pt={2}>
        <Typography component="h3" variant="paragraph">
          Review how invoicing works
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          sx={{ color: "var(--grey-2)" }}
        >
          Make sure you have all the info you need to submit your first invoice
        </Typography>
      </Box>
    </RoundedBox>
  );
};

export const useOnboardingStatus = () => {
  const user = useUser();
  const withdrawalMethods = useAppSelector(
    (state) => state.withdrawal.withdrawalMethods,
  );
  const defaultWithdrawalMethod = useMemo(() => {
    return _.findWhere(withdrawalMethods, { is_default: true });
  }, [withdrawalMethods]);

  const isOTPEnabled = user?.is_otp_enabled;
  const isW8FormRequired = user?.is_w8_form_required;
  const isW9FormRequired = user?.is_w9_form_required;
  const address = user?.address;
  const isTaxFormSubmitted =
    !!user?.address &&
    !(user?.is_w8_form_required || user?.is_w9_form_required);

  return {
    defaultWithdrawalMethod,
    isOTPEnabled,
    isW8FormRequired,
    isW9FormRequired,
    address,
    isTaxFormSubmitted,
  };
};
