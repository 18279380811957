import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Decimal from "decimal.js";

import { useUserWalletBalance } from "@js/apps/common/hooks/use-user-wallet-balance";
import type { ModalInstance as ModalInstanceComponent } from "@js/components/modal";

import { INSIGHT_BTRST_COST } from "../application-onramp-modal/constants";

type ModeOfPayment = "stripe" | "btrst";

type ProductCheckoutModalContextType = {
  availableBtrstToken: number;
  formattedRequiredTotalBtrst: number;
  isSufficientBtrst: boolean;
  modeOfPayment: ModeOfPayment;
  onClickBackModal: () => void;
  onCloseProductCheckoutModal: () => void;
  onCloseSuccessPurchaseModal?: () => void;
  setModeOfPayment: (newModeOfPayment: ModeOfPayment) => void;
  requiredTotalBtrstRaw: string;
  upgradeCost: number;
};

const ProductCheckoutModalContext =
  createContext<ProductCheckoutModalContextType | null>(null);

type ProductCheckoutModalContextProviderProps = {
  children: ReactNode;
  onClickBack: () => void;
  onCloseSuccessPurchaseModal?: () => void;
  ModalInstance: typeof ModalInstanceComponent;
};
export const ProductCheckoutModalContextProvider = ({
  children,
  onClickBack,
  onCloseSuccessPurchaseModal,
  ModalInstance,
}: ProductCheckoutModalContextProviderProps) => {
  const { data: balance } = useUserWalletBalance();

  const upgradeCost = new Decimal(INSIGHT_BTRST_COST);
  const parsedBalance = new Decimal(balance?.available_tokens ?? "0");
  const requiredTotalBtrst = upgradeCost.minus(parsedBalance);
  const isSufficientBtrst = requiredTotalBtrst.lessThanOrEqualTo(0);
  const formattedRequiredTotalBtrst = requiredTotalBtrst
    .toDecimalPlaces(2, Decimal.ROUND_UP)
    .toNumber();
  const availableBtrstToken = Number(balance?.available_tokens ?? 0);

  const [modeOfPayment, setModeOfPayment] = useState<
    ProductCheckoutModalContextType["modeOfPayment"]
  >(() => (availableBtrstToken <= 0 ? "stripe" : "btrst"));

  const hanleSetModeOfPayment = useCallback(
    (newModeOfPayment: ProductCheckoutModalContextType["modeOfPayment"]) => {
      setModeOfPayment(newModeOfPayment);
    },
    [],
  );

  const onCloseProductCheckoutModal = useCallback(() => {
    ModalInstance.close();
  }, [ModalInstance]);

  const onClickBackModal = useCallback(() => {
    ModalInstance.close();
    onClickBack();
  }, [ModalInstance, onClickBack]);

  const value = useMemo(() => {
    return {
      availableBtrstToken,
      formattedRequiredTotalBtrst,
      isSufficientBtrst,
      modeOfPayment,
      onClickBackModal,
      onCloseProductCheckoutModal,
      onCloseSuccessPurchaseModal,
      requiredTotalBtrstRaw: requiredTotalBtrst.toString(),
      setModeOfPayment: hanleSetModeOfPayment,
      upgradeCost: INSIGHT_BTRST_COST,
    };
  }, [
    availableBtrstToken,
    formattedRequiredTotalBtrst,
    hanleSetModeOfPayment,
    isSufficientBtrst,
    modeOfPayment,
    onClickBackModal,
    onCloseProductCheckoutModal,
    onCloseSuccessPurchaseModal,
    requiredTotalBtrst,
  ]);

  return (
    <ProductCheckoutModalContext.Provider value={value}>
      {children}
    </ProductCheckoutModalContext.Provider>
  );
};

export const useProductCheckoutModalContext = () => {
  const context = useContext(ProductCheckoutModalContext);

  if (!context) {
    throw new Error("ProductCheckoutModalContext is missing!");
  }

  return context;
};
