import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Button,
  List,
  ListItem,
  Loader,
  Typography,
} from "@braintrust/braintrust-ui-components";
import {
  BraintrustIcon,
  DownloadArrowIcon,
} from "@braintrust/braintrust-ui-components/Icons";
import { useGetPurchasesQuery } from "@js/apps/invoices/api";
import type { UpgradePurchaseInvoice } from "@js/apps/invoices/types";
import {
  Section,
  SectionSubtitleAlignCenterAction,
} from "@js/apps/settings/forms/helpers";

const PAGE_SIZE = 5;
export const BillingSection: React.FC = () => {
  const [page, setPage] = useState(1);
  const [invoicesData, setInvoicesData] = useState<UpgradePurchaseInvoice[]>(
    [],
  );

  const {
    data: invoices,
    isLoading: isLoadingInvoice,
    isFetching,
  } = useGetPurchasesQuery({ page, page_size: PAGE_SIZE });

  useEffect(() => {
    if (invoices) {
      const invocesArr = invoices?.results ? invoices.results : [];
      const data = [...invocesArr];
      const updatedInvoices = [...invoicesData, ...data].filter(
        (invoice, index, self) =>
          index === self.findIndex((i) => i.id === invoice.id),
      );
      setInvoicesData(updatedInvoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  const totalPurchases = invoices?.count ?? 0;
  const currentPurchasesCount = invoicesData.length;
  const hasMore =
    currentPurchasesCount < totalPurchases &&
    page &&
    PAGE_SIZE < totalPurchases;

  return (
    <>
      <Section title="Billing history">
        {invoicesData?.length ? (
          <List sx={{ p: 0 }}>
            {invoicesData?.map((item) => (
              <ListItem key={item?.id} sx={{ p: 0 }}>
                <SectionSubtitleAlignCenterAction
                  containerProps={{ width: "100%" }}
                  title={<ItemTitle invoice={item} />}
                  description={dayjs(item?.created).format("MMMM D, YYYY")}
                  action={
                    item?.id && (
                      <Button
                        size="x-small"
                        startIcon={<DownloadArrowIcon />}
                        shape="squared"
                        variant="black-outlined"
                        href={`/invoices/${item.invoice_id}`}
                        target="__blank"
                      >
                        Invoice
                      </Button>
                    )
                  }
                  divider
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box>
            <Typography
              variant="label"
              size="large"
              component="h4"
              fontWeight={500}
            >
              You have no billing history
            </Typography>
            <Typography
              variant="label"
              size="medium"
              component="h4"
              fontWeight={400}
            >
              Once you purchase an upgrade, they will appear here.
            </Typography>
          </Box>
        )}

        {isLoadingInvoice && (
          <Box display={"flex"} justifyContent={"center"}>
            <Loader />
          </Box>
        )}
        {hasMore && (
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              onClick={() => setPage((prev) => prev + 1)}
              variant="secondary"
              shape="squared"
              disabled={isFetching}
            >
              Load more
            </Button>
          </Box>
        )}
      </Section>
    </>
  );
};

const ItemTitle = ({
  invoice,
}: {
  invoice: UpgradePurchaseInvoice;
}): ReactNode => {
  if (invoice.currency === SETTINGS.DEFAULT_CURRENCY) {
    // USD
    return `${invoice.name} - $${invoice.price} ${invoice.currency}`;
  }

  return (
    <>
      {invoice.name} -{" "}
      <BraintrustIcon sx={{ fontSize: "inherit", verticalAlign: "text-top" }} />{" "}
      {invoice.price} {invoice.currency}
    </>
  );
};
