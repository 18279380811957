export const FETCHING_INVITATION_HISTORY = "FETCHING_INVITATION_HISTORY";
export const FETCHING_INVITATION_HISTORY_SUCCESS =
  "FETCHING_INVITATION_HISTORY_SUCCESS";
export const FETCHING_INVITATION_HISTORY_FAILED =
  "FETCHING_INVITATION_HISTORY_FAILED";
export const FETCHING_PROPOSAL_HISTORY = "FETCHING_PROPOSAL_HISTORY";
export const FETCHING_PROPOSAL_HISTORY_SUCCESS =
  "FETCHING_PROPOSAL_HISTORY_SUCCESS";
export const FETCHING_PROPOSAL_HISTORY_FAILED =
  "FETCHING_PROPOAL_HISTORY_FAILED";
export const SUBMIT_PROPOSAL_CLICKED = "SUBMIT_PROPOSAL_CLICKED";
export const PROPOSAL_SUBMITTED = "PROPOSAL_SUBMITTED";
export const PROPOSAL_CANCEL = "PROPOSAL_CANCEL";
export const UPDATE_PROPOSAL_STATUS = "UPDATE_PROPOSAL_STATUS";
export const FETCHING_JOB_EVENTS = "FETCHING_JOB_EVENTS";
export const FETCHING_JOB_EVENTS_SUCCESS = "FETCHING_JOB_EVENTS_SUCCESS";
export const FETCHING_JOB_EVENTS_FAILED = "FETCHING_JOB_EVENTS_FAILED";
export const VIEW_JOB_CONFIRMATION = "VIEW_JOB_CONFIRMATION";
export const VIEW_JOB_LINK_CLICKED = "VIEW_JOB_LINK_CLICKED";
export const JOB_DETAILS_VISITED = "JOB_DETAILS_VISITED";
export const JOB_ITEM_HAS_BEEN_EXPANDED = "JOB_ITEM_HAS_BEEN_EXPANDED";
export const JOB_FILTER_SAVED = "JOB_FILTER_SAVED";
export const UNAUTHENTICATED_USER_JOB_APPLY_CLICKS =
  "UNAUTHENTICATED_USER_JOB_APPLY_CLICKS";
export const VIEW_JOB_CLICKED_AFTER_SEARCH = "VIEW_JOB_CLICKED_AFTER_SEARCH";
export const JOB_TITLE_CLICKED_AFTER_SEARCH = "JOB_TITLE_CLICKED_AFTER_SEARCH";
export const EXPAND_DETAILS_AFTER_SEARCH = "EXPAND_DETAILS_AFTER_SEARCH";
export const CLICK_APPLY_AFTER_SEARCH = "CLICK_APPLY_AFTER_SEARCH";
export const CLICK_INSIGHTS_TOOLTIP = "CLICK_INSIGHTS_TOOLTIP";
export const VIEW_INSIGHTS_TOOLTIP = "VIEW_INSIGHTS_TOOLTIP";
