import React from "react";
import cs from "classnames";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

const Colors = {
  green: "soft-green",
  blue: "soft-blue",
  red: "soft-red",
  grey: "soft-grey",
} as const;

type OfferStatusProps = {
  label: React.ReactNode;
  color: keyof typeof Colors;
  className?: string;
};

export const OfferStatusChip = ({
  label,
  color,
  className,
  ...props
}: OfferStatusProps) => {
  return (
    <Box
      className={cs("offer-status", className, {
        [`offer-status-color--${Colors[color]}`]: !!color,
      })}
      {...props}
    >
      <span className="offer-status__dot" />
      <Typography component="span" variant="label" ellipsis>
        {label}
      </Typography>
    </Box>
  );
};
