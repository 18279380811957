import { useIsNodeOrImpersonated } from "@js/apps/common/hooks/use-is-node-or-impersonated";
import { Snackbar } from "@js/components/snackbar";
import type { Job } from "@js/types/jobs";
import { pluralize } from "@js/utils";

import { useUpdateEmployerBidInBulkMutation } from "../../apps/bids/api/api";
import { expectModal } from "../../apps/bids/components/air-ai-what-to-expect-modal";
import { openAiInterviewRequestMessage } from "../../apps/bids/components/bid-messages-modals/ai-interview-request-message";
import type { RefetchBidList } from "../../context/refetch-bids-context";

type SendAIInterviewArg = {
  refetchBidList: RefetchBidList | null;
  bids: number[];
  job: Job;
};

export const useHandleSendAIInterviewRequest = () => {
  const isNodeStaffOrImpersonated = useIsNodeOrImpersonated();
  const [updateEmployerBidsInBulk] = useUpdateEmployerBidInBulkMutation();

  const handleSendAIInterviewAsCoreStaff = (arg: SendAIInterviewArg) => {
    openAiInterviewRequestMessage(arg);
  };

  const handleSendAIInterviewAsNonCoreStaff = async ({
    bids,
    job,
    refetchBidList,
  }: SendAIInterviewArg) => {
    try {
      await updateEmployerBidsInBulk({
        status: ENUMS.BidStatus.INTERVIEWING,
        pks: bids,
      }).unwrap();

      Snackbar.success(`AI Interview request${pluralize(bids.length)} sent`, {
        transitionDuration: {
          enter: 200,
          exit: 200,
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      refetchBidList?.();
      if (job.bids_count.interviewing_bids_count === 0) {
        expectModal.openAnnouncement();
      }
    } catch (_error) {
      refetchBidList?.();
      Snackbar.error(
        "Updating status failed. Please refresh the page and try again",
      );
    }
  };

  const handleSendAIInterviewRequest = async (arg: SendAIInterviewArg) => {
    if (isNodeStaffOrImpersonated) {
      handleSendAIInterviewAsCoreStaff(arg);

      return;
    }

    handleSendAIInterviewAsNonCoreStaff(arg);
  };

  return { handleSendAIInterviewRequest };
};
