import { castToInteger } from "@js/utils";

export const getReferralParams = (url: string): number => {
  const queryString = url.split("?")[1];
  const params = new URLSearchParams(queryString);
  const page = params.get("page");

  return castToInteger(page) || 2;
};

const parseAddress = (address: string): string | undefined => {
  if (!address.match(/^(0x)?[0-9a-fA-F]{40}$/)) {
    return;
  }

  if (address.substring(0, 2) !== "0x") {
    return "0x" + address;
  }

  return address;
};

// shorten the address to have 0x + x (4 by default) characters at start and end
export const getShortWalletAddress = (address: string, chars = 4): string => {
  const parsedAddress = parseAddress(address);
  if (!parsedAddress) {
    return address;
  }

  return `${parsedAddress.substring(0, chars + 2)}...${parsedAddress.substring(42 - chars)}`;
};
