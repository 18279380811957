import { API } from "@js/api";
import { prepareDraftResponse } from "@js/apps/jobs/apps/create-job/utils/prepare-data";
import type { RootState } from "@js/store";
import type { NoteItem } from "@js/types/admin";
import type { PaginatedResult } from "@js/types/generic";
import type {
  AIGeneratedJob,
  CompleteJobParams,
  CompleteJobResponse,
  CreateJobData,
  CreateJobDraftPayload,
  CreateJobResponse,
  EmployerJobMinimal,
  EmployerOwnJob,
  GenerateAIJobPayload,
  GetExpandedJobDetailsQueryArgs,
  GetExpandedJobDetailsResponse,
  GetJobApplicationInsightsParams,
  GetJobDraftResponse,
  GetJobDraftsParams,
  GetJobDraftsResponse,
  GetJobParams,
  GetJobsParams,
  GetNewestJobsProps,
  GetNewestJobsResponse,
  GetOwnJobsParams,
  GetRelevantJobsProps,
  GetRelevantJobsResponse,
  GetSavedJobsParams,
  GetSimilarJobsProps,
  GetSimilarJobsResponse,
  GetSmartMatchingStatusArgs,
  GetSmartMatchingStatusResponse,
  GetTotalJobBidsParams,
  GetTotalJobBidsResponse,
  InviteTalentProps,
  Job,
  JobApplicationInsightsResponse,
  JobDraft,
  JobListingJob,
  JobMinimalSearchParams,
  JobMinimalSearchResult,
  JobSubscriber,
  JobSubscriberInvitation,
  ManagedJob,
  PatchJobParams,
  RemoveInviteProps,
  TalentInviteSuggestion,
  TalentInviteSuggestions,
  UpdateJobDraftPayload,
  UpdateJobFeedbackParams,
  UpdateJobParams,
  UpdateJobSubscriberInvitationsParams,
  UpdateJobSubscribersParams,
} from "@js/types/jobs";
import { combineResults } from "@js/utils";

import { buildFiltersURL, formatFilters } from "../common/filters";

import { bidApi } from "./apps/bids/api";
import {
  getAllFetchBidsArgs,
  patchBidNotesCounterOnNoteAdd,
  patchBidNotesCounterOnNoteDelete,
  patchBidsNotesCounterOnNoteAdd,
  patchBidsNotesCounterOnNoteDelete,
} from "./apps/bids/api/helpers";
import { getWorkFromLocationParam } from "./utils";

type TalentInviteSuggestionsProps = {
  jobId: number;
  page?: number;
};

export const inviteApi = API.injectEndpoints({
  endpoints: (build) => ({
    getTalentInviteSuggestions: build.query<
      TalentInviteSuggestions,
      TalentInviteSuggestionsProps
    >({
      query: ({ jobId, page }) => ({
        url: `/talent/talent_invite_suggestion_list/`,
        params: { job: jobId, page },
        method: "GET",
      }),
      providesTags: ["TalentInviteSuggestions"],
    }),
    sendInvitation: build.mutation<TalentInviteSuggestion, InviteTalentProps>({
      query: ({ jobId, freelancersIds }) => ({
        url: `/manage_jobs/${jobId}/invite/`,
        method: "POST",
        data: { freelancers: freelancersIds },
      }),
      invalidatesTags: ["TalentInviteSuggestions"],
    }),

    removeInvitation: build.mutation<void, RemoveInviteProps>({
      query: ({ jobId, freelancerId }) => ({
        url: `/manage_jobs/${jobId}/remove_talent_invite_suggestion/`,
        method: "POST",
        data: { freelancer: freelancerId },
      }),
      invalidatesTags: ["TalentInviteSuggestions"],
    }),
  }),
});

export const jobsApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<PaginatedResult<JobListingJob>, GetJobsParams>({
      query: (jobParams) => {
        const role = jobParams.role?.length
          ? jobParams.role.join(",")
          : undefined;
        const skills = jobParams.skills?.length
          ? jobParams.skills.join(",")
          : undefined;

        const workFromLocation = getWorkFromLocationParam(jobParams);

        return {
          url: `/jobs/`,
          method: "GET",
          params: {
            ...jobParams,
            role: role,
            skills: skills,
            work_from_location: workFromLocation,
          },
        };
      },
      providesTags: [{ type: "Jobs", id: "LIST" }],
    }),
    getJob: builder.query<Job, GetJobParams>({
      query: ({ id, referrer }) => ({
        url: `/jobs/${id}`,
        method: "GET",
        params: { referrer },
      }),
      providesTags: (_res, _err, arg) => [{ type: "Jobs", id: arg.id }],
    }),
    getJobMinimalSearch: builder.query<
      JobMinimalSearchResult[],
      JobMinimalSearchParams
    >({
      query: (params) => ({
        url: `/job_minimal_search/`,
        method: "GET",
        params,
      }),
      transformResponse: (result: JobMinimalSearchResult[]) => {
        /**
         * Backend may return few k items, so we limit response to not choke autocomplete component.
         * In the future some backend/frontend adjustments may be needed.
         */
        const shortened = result.slice(0, 100);
        return shortened;
      },
    }),
    getJobApplicationInsights: builder.query<
      JobApplicationInsightsResponse,
      GetJobApplicationInsightsParams
    >({
      query: ({ id }) => ({
        url: `/jobs/${id}/application_insights`,
        method: "GET",
      }),
      providesTags: (_res, _err, arg) => [{ type: "Jobs", id: arg.id }],
    }),
    getSavedJobs: builder.query<
      PaginatedResult<JobListingJob>,
      GetSavedJobsParams | undefined
    >({
      query: (arg) => ({
        url: "/jobs/saved_jobs",
        method: "GET",
        params: arg,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs: Partial<GetSavedJobsParams> = {
          ...queryArgs,
        };
        delete serializeQueryArgs.page;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        // serialized query arg makes it so we are not fetching on page change ...
        // ... force refetch when any param changes, including page (page must be higher)
        const paramsKeys: Array<keyof GetSavedJobsParams> = ["page"];
        const hasArgChanged = paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );

        return hasArgChanged;
      },
      merge: (currentCache, newItems) => {
        const combinedJobsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedJobsResults,
        };
      },
      providesTags: ["SavedJobs"],
    }),
    /* Response type here should be narrowed but that's needs a refactor of JobListingItem  */
    getEmployerOpenJobs: builder.query<JobListingJob[], { employerId: number }>(
      {
        query: ({ employerId }) => ({
          url: `/employer_open_jobs/?employer=${employerId}`,
          method: "GET",
        }),
        providesTags: ["EmployerOpenJobs"],
      },
    ),
    getRelevantJobs: builder.query<
      GetRelevantJobsResponse,
      GetRelevantJobsProps
    >({
      query: (filters) => ({
        url: `/jobs_talent_home/relevant_jobs/${buildFiltersURL(
          filters,
        ).search()}`,
        method: "GET",
      }),
      providesTags: ["RelevantJobs"],
    }),

    getOwnJobs: builder.query<
      PaginatedResult<EmployerOwnJob>,
      GetOwnJobsParams
    >({
      query: (params) => ({
        url: `/manage_jobs/`,
        method: "GET",
        params,
      }),
      providesTags: ["ManagedJobs"],
    }),

    getJobDrafts: builder.query<GetJobDraftsResponse, GetJobDraftsParams>({
      query: (params) => ({
        url: `/jobs_drafts/`,
        method: "GET",
        params,
      }),
      providesTags: [{ type: "JobDrafts", id: "LIST" }],
    }),

    getJobDraft: builder.query<JobDraft, { id: number }>({
      query: ({ id }) => ({
        url: `/jobs_drafts/${id}/`,
        method: "GET",
      }),
      providesTags: (_res, _err, arg) => [{ type: "JobDrafts", id: arg.id }],
      transformResponse: (response: GetJobDraftResponse): JobDraft => {
        return prepareDraftResponse(response);
      },
    }),

    deleteJobDraft: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/jobs_drafts/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "JobDrafts", id: "LIST" }],
    }),

    createJobDraft: builder.mutation<
      GetJobDraftResponse,
      CreateJobDraftPayload
    >({
      query: (data) => ({
        url: `/jobs_drafts/`,
        method: "POST",
        data,
      }),
    }),

    updateJobDraft: builder.mutation<JobDraft, UpdateJobDraftPayload>({
      query: (data) => ({
        url: `/jobs_drafts/${data.data.id}/`,
        method: "PUT",
        data,
      }),
      transformResponse: (response: GetJobDraftResponse): JobDraft => {
        return prepareDraftResponse(response);
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            jobsApi.util.updateQueryData(
              "getJobDraft",
              { id: arg.data.id },
              () => {
                return data;
              },
            ),
          );
        } catch {
          /* Do nothing. */
        }
      },
    }),

    prepareSmartMatching: builder.query<void, void>({
      query: () => ({
        url: `/manage_jobs/prepare_smart_matching/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getSmartMatchingStatus: builder.query<
      GetSmartMatchingStatusResponse,
      GetSmartMatchingStatusArgs
    >({
      query: (params) => ({
        url: `/jobs/${params.id}/smart_matching_status/`,
        method: "GET",
      }),
    }),
    getSimilarJobs: builder.query<GetSimilarJobsResponse, GetSimilarJobsProps>({
      query: ({ jobId, pageSize }) => ({
        url: `/jobs_similar_jobs/?job_id=${jobId}&page_size=${pageSize}`,
        method: "GET",
      }),
      providesTags: ["SimilarJobs"],
    }),

    getNewestJobs: builder.query<GetNewestJobsResponse, GetNewestJobsProps>({
      query: (filters) => ({
        url: `/jobs_talent_home/newest_jobs/?${new URLSearchParams(
          formatFilters(filters),
        ).toString()}`,
        method: "GET",
      }),
      providesTags: ["NewestJobs"],
    }),
    getExpandedJobDetails: builder.query<
      GetExpandedJobDetailsResponse,
      GetExpandedJobDetailsQueryArgs
    >({
      query: (id) => ({
        url: `/jobs/${id}/expanded_details_job_list/`,
        method: "GET",
        //Response status validation is silenced due to a problem with redirection on 404 errors.
        //https://app.asana.com/0/960700181513369/1203058392812609
        validateStatus: () => true,
      }),
    }),
    getEmployerJobsMinimal: builder.query<EmployerJobMinimal[], void>({
      query: (params) => ({
        url: `/employer_jobs_minimal/`,
        method: "GET",
        params,
      }),
      providesTags: [{ type: "EmployerJobsMinimal", id: "LIST" }],
    }),

    completeJob: builder.mutation<CompleteJobResponse, CompleteJobParams>({
      query: ({ jobId, data }) => ({
        url: `/manage_jobs/${jobId}/complete/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["ManagedEmployers"],
    }),

    getManagedJob: builder.query<ManagedJob, { jobId: number }>({
      query: ({ jobId }) => ({
        url: `/manage_jobs/${jobId}/`,
        method: "GET",
      }),
      providesTags: (_res, _err, arg) => [
        { type: "ManagedJobs", id: arg.jobId },
      ],
    }),

    createJob: builder.mutation<CreateJobResponse, CreateJobData>({
      query: (data) => ({
        url: `/manage_jobs/`,
        method: "POST",
        data,
      }),
      invalidatesTags: [
        "EmployerJobsMinimal",
        "EmployerHasExceededDailyJobsLimit",
      ],
    }),

    updateJob: builder.mutation<ManagedJob, UpdateJobParams>({
      query: ({ jobId, data }) => ({
        url: `/manage_jobs/${jobId}/`,
        method: "PUT",
        data,
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: "Jobs", id: arg.jobId },
        { type: "ManagedJobs", id: arg.jobId },
        { type: "JobBidsLocations", id: arg.jobId },
        { type: "JobSubscribers", id: arg.jobId },
        { type: "JobSubscriberInvitations", id: arg.jobId },
        "EmployerJobsMinimal",
      ],
    }),

    patchJob: builder.mutation<ManagedJob, PatchJobParams>({
      query: ({ jobId, data }) => ({
        url: `/manage_jobs/${jobId}/`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: "Jobs", id: arg.jobId },
        { type: "Jobs", id: "LIST" },
        { type: "ManagedJobs", id: arg.jobId },
        "EmployerOpenJobs",
        "SavedJobs",
      ],
    }),

    getTotalJobBids: builder.query<
      GetTotalJobBidsResponse,
      GetTotalJobBidsParams
    >({
      query: ({ jobId }) => ({
        url: `/jobs/${jobId}/job_bids_count/`,
        method: "GET",
      }),
      providesTags: [{ type: "Jobs", id: "LIST" }],
    }),

    updateJobFeedback: builder.mutation<void, UpdateJobFeedbackParams>({
      query: ({ jobId, data }) => ({
        url: `/manage_jobs/${jobId}/update_feedback/`,
        method: "POST",
        data,
      }),
    }),
  }),
});

const publishJobsAfterEmailVerificationApi = API.injectEndpoints({
  endpoints: (build) => ({
    publishPendingJobs: build.mutation<
      { failed: number; sent_for_approval: number },
      void
    >({
      query: () => {
        return {
          url: `/manage_jobs/send_jobs_for_approval/`,
          method: "POST",
        };
      },
      invalidatesTags: ["JobDrafts"],
    }),
  }),
});

const jobOwnerApi = API.injectEndpoints({
  endpoints: (build) => ({
    changeJobOwner: build.mutation<
      { success: boolean },
      { jobId: number; newOwnerId: number }
    >({
      query: ({ jobId, newOwnerId }) => {
        return {
          url: `/manage_jobs/${jobId}/change_owner/`,
          method: "POST",
          data: { new_owner: newOwnerId },
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "Jobs", id: arg.jobId },
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
    getJobOwners: build.query<
      {
        op_owners: number[];
        sale_owners: number[];
      },
      { jobId: number }
    >({
      query: ({ jobId }) => {
        return {
          url: `/jobs/${jobId}/get_owners/`,
          method: "GET",
        };
      },
      providesTags: (_res, _err, arg) => [{ type: "JobOwners", id: arg.jobId }],
    }),
    changeJobOwners: build.mutation<
      void,
      {
        jobId: number;
        op_owners?: number[];
        sale_owners?: number[];
      }
    >({
      query: ({ jobId, ...data }) => ({
        url: `/jobs/${jobId}/change_owners/`,
        method: "POST",
        data,
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: "JobOwners", id: arg.jobId },
        { type: "Jobs", id: arg.jobId },
        { type: "Jobs", id: "LIST" },
        "EmployerOpenJobs",
        "SavedJobs",
      ],
    }),
  }),
});

const jobSubscribersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getJobSubscribers: build.query<JobSubscriber[], { jobId: number }>({
      query: ({ jobId }) => {
        return {
          url: `/jobs/${jobId}/job_subscribers/`,
          method: "GET",
        };
      },
      providesTags: (_res, _err, arg) => [
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
    getJobSubscriberInvitations: build.query<
      JobSubscriberInvitation[],
      { jobId: number }
    >({
      query: ({ jobId }) => {
        return {
          url: `/jobs/${jobId}/job_subscriber_invitations/`,
          method: "GET",
        };
      },
      providesTags: (_res, _err, arg) => [
        { type: "JobSubscriberInvitations", id: arg.jobId },
      ],
    }),
    updateJobSubscribers: build.mutation<
      JobSubscriber[],
      UpdateJobSubscribersParams
    >({
      query: ({ jobId, jobSubscribers }) => {
        return {
          url: `/jobs/${jobId}/job_subscribers/batch`,
          method: "PUT",
          data: jobSubscribers,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            jobSubscribersApi.util.updateQueryData(
              "getJobSubscribers",
              { jobId: arg.jobId },
              () => {
                return data;
              },
            ),
          );
        } catch (_error) {
          dispatch(
            jobSubscribersApi.util.invalidateTags([
              { type: "JobSubscribers", id: arg.jobId },
              { type: "JobSubscriberInvitations", id: arg.jobId },
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
      invalidatesTags: ["ManagedJobs"],
    }),
    updateJobSubscriberInvitations: build.mutation<
      JobSubscriberInvitation[],
      UpdateJobSubscriberInvitationsParams
    >({
      query: ({ jobId, jobSubscriberInvitations }) => {
        return {
          url: `/jobs/${jobId}/job_subscriber_invitations/batch`,
          method: "PUT",
          data: jobSubscriberInvitations,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            jobSubscribersApi.util.updateQueryData(
              "getJobSubscriberInvitations",
              { jobId: arg.jobId },
              () => {
                return data;
              },
            ),
          );
        } catch (_error) {
          dispatch(
            jobSubscribersApi.util.invalidateTags([
              { type: "JobSubscribers", id: arg.jobId },
              { type: "JobSubscriberInvitations", id: arg.jobId },
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
      invalidatesTags: (_res, _err, arg) => [
        { type: "ManagedJobs", id: arg.jobId },
      ],
    }),
    subscribeToJob: build.mutation<
      {
        job: number;
        id: number;
      },
      { jobId: number; team_member_id: number }
    >({
      query: ({ jobId, ...data }) => ({
        url: `/jobs/${jobId}/job_subscribers/`,
        method: "POST",
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        "ManagedJobs",
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
    unsubscribeFromJob: build.mutation<
      void,
      { jobId: number; team_member_id: number }
    >({
      query: ({ jobId, ...data }) => ({
        url: `/jobs/${jobId}/job_subscribers/`,
        method: "DELETE",
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        "ManagedJobs",
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
  }),
});

const applicationsBoostApi = API.injectEndpoints({
  endpoints: (build) => ({
    getApplicationBoosts: build.mutation({
      query: () => {
        return {
          url: `/user/purchase/boost/`,
          method: "POST",
        };
      },
    }),
  }),
});

const jobAiInterviewQuestionApi = API.injectEndpoints({
  endpoints: (build) => ({
    initializeInterviewQuestionGenerator: build.mutation<void, void>({
      query: () => {
        return {
          url: `/dummy_interview_question_generator/`,
          method: "POST",
        };
      },
    }),
    generateInterviewQuestion: build.mutation<ManagedJob, { jobId: number }>({
      query: ({ jobId }) => {
        return {
          url: `/manage_jobs/${jobId}/create_ai_interview/`,
          method: "POST",
        };
      },
      invalidatesTags: (_res, _err, arg) => [
        { type: "Jobs", id: arg.jobId },
        { type: "ManagedJobs", id: arg.jobId },
        { type: "JobBidsLocations", id: arg.jobId },
        { type: "JobSubscribers", id: arg.jobId },
        { type: "JobSubscriberInvitations", id: arg.jobId },
        "EmployerJobsMinimal",
      ],
    }),
  }),
});

const bidNotesApi = API.injectEndpoints({
  endpoints: (build) => ({
    getBidNotes: build.query<NoteItem[], { id: number }>({
      query: ({ id }) => ({
        url: `/bid_notes`,
        method: "GET",
        params: {
          target_name: ENUMS.NoteContentType.Bid,
          target_id: id,
        },
      }),
      keepUnusedDataFor: 1000000,
      providesTags: (_res, _err, arg) => [{ type: "BidNotes", id: arg.id }],
    }),
    addBidNote: build.mutation<NoteItem, { id: number; content: string }>({
      query: ({ id, content }) => ({
        url: `/bid_notes/`,
        method: "POST",
        data: { content, bid_id: id },
      }),
      async onQueryStarted({ id }, { dispatch, getState, queryFulfilled }) {
        const state = getState() as RootState;
        const patchResults: Array<{ undo: () => void }> = [];

        const allFetchBidsArgs = getAllFetchBidsArgs(state);
        allFetchBidsArgs.forEach((fetchBidsArg) => {
          const bidsPatchResult = dispatch(
            bidApi.util.updateQueryData("fetchBids", fetchBidsArg, (draft) => {
              patchBidsNotesCounterOnNoteAdd({ draft, bidId: id });
            }),
          );

          patchResults.push(bidsPatchResult);
        });

        const bidPatchResult = dispatch(
          bidApi.util.updateQueryData("fetchBid", { bidId: id }, (draft) => {
            patchBidNotesCounterOnNoteAdd({
              bid: draft,
            });
          }),
        );

        patchResults.push(bidPatchResult);

        try {
          await queryFulfilled;
        } catch (_e) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
      invalidatesTags: (_res, _err, arg) => [{ type: "BidNotes", id: arg.id }],
    }),
    deleteBidNote: build.mutation<void, { noteId: number; bidId: number }>({
      query: ({ noteId }) => ({
        url: `/bid_notes/${noteId}/`,
        method: "DELETE",
      }),
      async onQueryStarted({ bidId }, { dispatch, getState, queryFulfilled }) {
        const state = getState() as RootState;
        const patchResults: Array<{ undo: () => void }> = [];

        const allFetchBidsArgs = getAllFetchBidsArgs(state);
        allFetchBidsArgs.forEach((fetchBidsArg) => {
          const bidsPatchResult = dispatch(
            bidApi.util.updateQueryData("fetchBids", fetchBidsArg, (draft) => {
              patchBidsNotesCounterOnNoteDelete({ draft, bidId });
            }),
          );

          patchResults.push(bidsPatchResult);
        });

        const bidPatchResult = dispatch(
          bidApi.util.updateQueryData("fetchBid", { bidId }, (draft) => {
            patchBidNotesCounterOnNoteDelete({
              bid: draft,
            });
          }),
        );

        patchResults.push(bidPatchResult);

        try {
          await queryFulfilled;
        } catch (_e) {
          patchResults.forEach((patchResult) => patchResult.undo());
        }
      },
      invalidatesTags: (_res, _err, arg) => [
        { type: "BidNotes", id: arg.bidId },
      ],
    }),
  }),
});

export const jobAIDescriptionApi = API.injectEndpoints({
  endpoints: (build) => ({
    generateJobDescriptionUsingAIInit: build.mutation<
      void,
      Pick<GenerateAIJobPayload, "employer_id">
    >({
      query: ({ employer_id }) => ({
        url: `/job_post_assistant/initial_request/`,
        method: "POST",
        data: {
          short_description: "",
          employer_id,
        },
      }),
    }),
    generateJobDescriptionUsingAI: build.mutation<
      AIGeneratedJob,
      Pick<GenerateAIJobPayload, "employer_id" | "short_description">
    >({
      query: ({ employer_id, short_description }) => ({
        url: `/job_post_assistant/generate/`,
        method: "POST",
        data: {
          short_description,
          employer_id,
        },
      }),
    }),
  }),
});

export const {
  useGenerateJobDescriptionUsingAIInitMutation,
  useGenerateJobDescriptionUsingAIMutation,
} = jobAIDescriptionApi;

export const {
  useGetTalentInviteSuggestionsQuery,
  useLazyGetTalentInviteSuggestionsQuery,
  useSendInvitationMutation,
  useRemoveInvitationMutation,
} = inviteApi;

export const {
  useGetJobQuery,
  useGetJobsQuery,
  useLazyGetJobsQuery,
  useGetRelevantJobsQuery,
  useGetOwnJobsQuery,
  useLazyGetOwnJobsQuery,
  useGetJobDraftsQuery,
  useLazyGetJobDraftsQuery,
  useGetJobDraftQuery,
  usePrepareSmartMatchingQuery,
  useGetSimilarJobsQuery,
  useGetNewestJobsQuery,
  useGetExpandedJobDetailsQuery,
  useGetSavedJobsQuery,
  useGetEmployerOpenJobsQuery,
  useGetSmartMatchingStatusQuery,
  useLazyGetSmartMatchingStatusQuery,
  useGetEmployerJobsMinimalQuery,
  useUpdateJobDraftMutation,
  useCreateJobDraftMutation,
  useDeleteJobDraftMutation,
  useGetJobApplicationInsightsQuery,
  useCompleteJobMutation,
  useGetManagedJobQuery,
  useUpdateJobMutation,
  usePatchJobMutation,
  useCreateJobMutation,
  useGetJobMinimalSearchQuery,
  useLazyGetTotalJobBidsQuery,
  useUpdateJobFeedbackMutation,
} = jobsApi;

export const { usePublishPendingJobsMutation } =
  publishJobsAfterEmailVerificationApi;

export const {
  useGetJobOwnersQuery,
  useChangeJobOwnersMutation,
  useChangeJobOwnerMutation,
} = jobOwnerApi;

export const {
  useGetJobSubscribersQuery,
  useGetJobSubscriberInvitationsQuery,
  useUpdateJobSubscriberInvitationsMutation,
  useUpdateJobSubscribersMutation,
  useSubscribeToJobMutation,
  useUnsubscribeFromJobMutation,
} = jobSubscribersApi;

export const { useGetApplicationBoostsMutation } = applicationsBoostApi;

export const {
  useGenerateInterviewQuestionMutation,
  useInitializeInterviewQuestionGeneratorMutation,
} = jobAiInterviewQuestionApi;

export const {
  useGetBidNotesQuery,
  useAddBidNoteMutation,
  useDeleteBidNoteMutation,
} = bidNotesApi;
