import type { InvoiceItem } from "@js/types/invoices";

export const INVOICE_FORM_ID = "addInvoiceForm";

export type InvoiceFormData = {
  job: number | undefined;
  number: string | undefined;
  issued_at: string;
  notes_to_receipt?: string | null;
  new_items?: Array<Partial<InvoiceItem>>;
};
