import type { ReactNode } from "react";
import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { CoreLayout } from "@js/layouts/core";

type OfferLayoutProps = {
  children: React.ReactNode;
  topStripeSocket?: React.ReactNode;
  navigationSocket: React.ReactNode;
  talentInfoSocket?: React.ReactNode;
  className?: string;
};

export const OfferLayout = ({
  children,
  className,
  topStripeSocket,
  navigationSocket,
  talentInfoSocket,
}: OfferLayoutProps) => {
  return (
    <CoreLayout className={cs(className, "offers-layout")}>
      <Box className="offers-layout__header">
        {topStripeSocket && (
          <Box className="offers-layout__top-stripe-socket">
            <Box className="offers-layout__top-stripe">{topStripeSocket}</Box>
          </Box>
        )}
        <Box className="offers-layout__navigation-socket">
          <Box className="offers-layout__navigation">{navigationSocket}</Box>
        </Box>
      </Box>
      {talentInfoSocket && (
        <Box className="offers-layout__talent-info-socket">
          <Box className="offers-layout__talent-info">{talentInfoSocket}</Box>
        </Box>
      )}
      <Box className="offers-layout__content">{children}</Box>
    </CoreLayout>
  );
};

type OfferFormContentContainerProps = {
  children: ReactNode;
  twoColumnsLayout?: boolean;
};

export const OfferFormContentContainer = ({
  children,
  twoColumnsLayout,
}: OfferFormContentContainerProps) => {
  const containerWidth = twoColumnsLayout ? 1189 : 787;

  return (
    <Box sx={{ maxWidth: containerWidth, width: "100%", marginInline: "auto" }}>
      {children}
    </Box>
  );
};
