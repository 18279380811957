import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { useHandleSendAIInterviewRequest } from "@js/apps/jobs/hooks/use-handle-send-ai-interview-request";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { isAIInterviewButtonDisabled } from "../../helpers";

type AiInterviewButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  onOptionClick?: () => void;
  closeDrawer?: () => void;
} & Pick<ButtonProps, "sx" | "size" | "className">;

export const AiInterviewButton = ({
  job,
  bid,
  onOptionClick,
  closeDrawer,
  ...buttonProps
}: AiInterviewButtonProps) => {
  const { refetchBidList } = useBidsContext();
  const { handleSendAIInterviewRequest } = useHandleSendAIInterviewRequest();

  const handleSubmit = async () => {
    if (!bid) {
      return;
    }

    handleSendAIInterviewRequest({
      bids: [bid.id],
      refetchBidList,
      job,
    });
  };

  const isDisabled = isAIInterviewButtonDisabled(job);

  return (
    <Button
      variant="primary"
      shape="squared"
      disabled={isDisabled}
      {...buttonProps}
      sx={{ textWrap: "nowrap", ...buttonProps?.sx }}
      onClick={() => {
        onOptionClick?.();
        handleSubmit();
      }}
    >
      Send AI Interview request
    </Button>
  );
};
