import { Box } from "@braintrust/braintrust-ui-components";
import {
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "@js/apps/jobs/components/job-basic-details";
import type { Job } from "@js/types/jobs";

export const JobForYouBasicInfo = ({
  job,
}: {
  job: Pick<
    Job,
    "expected_hours_per_week" | "locations" | "timezones" | "timezone_overlap"
  >;
}) => {
  return (
    <Box display="flex" gap="8px 24px" flexWrap="wrap">
      <WorkTimeDetail
        expectedHoursPerWeek={job.expected_hours_per_week}
        variant="jobs-for-you-card"
        shortened
      />
      <LocationDetail locations={job.locations} variant="jobs-for-you-card" />
      <TimezoneDetail
        timezones={job.timezones}
        timezoneOverlap={job.timezone_overlap}
        variant="jobs-for-you-card"
        shortenedLabel
      />
    </Box>
  );
};
