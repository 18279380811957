import { Button, Stack } from "@braintrust/braintrust-ui-components";
import { useCompletionSequenceContext } from "@js/apps/freelancer/components/profile-completion-sequence/context/completion-sequence-context";

import { RoleModal } from "../../role-module/role-modal";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const ProfileCompletionSequenceRoleStep = () => {
  const { handleStepCompleted } = useCompletionSequenceContext();

  return (
    <Stack sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <RoleModal
        persistModalInstance
        saveButtonText="Save and continue"
        onFinished={handleStepCompleted}
        continueButton={
          <Button
            variant="positive"
            shape="squared"
            onClick={handleStepCompleted}
          >
            Save and continue
          </Button>
        }
      />
    </Stack>
  );
};
