import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@braintrust/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useIntersectionObserver } from "@js/hooks/use-intersection-observer";

import { RouterLink } from "../link";
import { Logo } from "../logo";

import type { NavLink } from "./constants";
import { NAV_LINKS } from "./constants";
import { BOOK_DEMO_URL, getNavLinkToUrl, getSignUpLinkUrl } from "./helpers";
import { PublicTopHeader } from "./public-top-header";

import styles from "./public-top-navigation-desktop.module.scss";

export const PublicTopNavigationDesktop = () => {
  const anchorRef = useRef(null);
  const { search } = useLocation();

  const isNearTopEdge = useIntersectionObserver(
    anchorRef,
    { threshold: 0.75 },
    true,
  );

  return (
    <>
      <Box ref={anchorRef} className={styles.pageOffset}></Box>
      <PublicTopHeader
        className={cs({ [styles.headerShort]: !isNearTopEdge })}
        offsetByScrollbar
        sx={{ px: "15px" }}
      >
        <Stack direction="row" className={cs(styles.navContainer)}>
          <Stack className={styles.logoContainer}>
            <Logo />
          </Stack>
          <Stack direction="row" sx={{ height: "100%", alignItems: "center" }}>
            <Box component="nav" aria-label="Main menu" sx={{ height: "100%" }}>
              <Stack
                component="ul"
                direction="row"
                sx={{ alignItems: "center" }}
                className={styles.navLinks}
              >
                {NAV_LINKS.map(({ subLinks, subLinkGroups, ...linkData }) => {
                  if (subLinks?.length) {
                    return (
                      <NavLinkWithSubLinks
                        key={linkData.label}
                        subLinks={subLinks}
                        {...linkData}
                      />
                    );
                  }
                  if (subLinkGroups?.length) {
                    return (
                      <NavLinkWithSubLinkGroups
                        key={linkData.label}
                        subLinkGroups={subLinkGroups}
                        {...linkData}
                      />
                    );
                  }

                  return <BasicNavLink key={linkData.label} {...linkData} />;
                })}
              </Stack>
            </Box>

            <Stack direction="row" sx={{ gap: "20px", pl: 1 }}>
              <Button
                variant="secondary"
                size="x-small"
                className={cs(styles.button, styles.buttonDemo)}
                to={BOOK_DEMO_URL}
                RouterLink={RouterLink}
              >
                Book Demo
              </Button>
              <Button
                variant="primary"
                size="x-small"
                className={cs(styles.button, styles.buttonSignUp)}
                to={getSignUpLinkUrl(search)}
                RouterLink={RouterLink}
              >
                Talent Sign Up
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </PublicTopHeader>
    </>
  );
};

type NavLinkWithUnderlineProps = Pick<NavLink, "url" | "label">;

const NavLinkWithUnderline = ({ label, url }: NavLinkWithUnderlineProps) => {
  if (!url) {
    return (
      <Typography
        component="label"
        className={styles.navLink}
        fontWeight={500}
        role="button"
      >
        {label}
      </Typography>
    );
  }

  return (
    <Typography
      component="link"
      to={url}
      RouterLink={RouterLink}
      className={styles.navLink}
      fontWeight={500}
    >
      {label}
    </Typography>
  );
};

type NavSubLinkWithUnderlineProps = NavLinkWithUnderlineProps & {
  search: string;
  persistUTMSearchParams?: boolean;
  isActive: boolean;
  bold?: boolean;
};

const NavSubLinkWithUnderline = ({
  label,
  url,
  search,
  persistUTMSearchParams,
  isActive,
  bold,
}: NavSubLinkWithUnderlineProps) => {
  if (!url) {
    return (
      <Typography
        component="label"
        role="button"
        className={cs(styles.navLink, styles.navSubLink, {
          [styles.navLinkActive]: isActive,
        })}
        fontWeight={bold ? 500 : 400}
      >
        {label}
      </Typography>
    );
  }

  return (
    <Typography
      component="link"
      to={getNavLinkToUrl({ url, search, persistUTMSearchParams })}
      RouterLink={RouterLink}
      className={cs(styles.navLink, styles.navSubLink, {
        [styles.navLinkActive]: isActive,
      })}
      fontWeight={bold ? 500 : 400}
    >
      {label}
    </Typography>
  );
};

type BasicNavLinkProps = Pick<
  NavLink,
  "url" | "label" | "persistUTMSearchParams"
>;

const BasicNavLink = ({
  url,
  label,
  persistUTMSearchParams,
}: BasicNavLinkProps) => {
  const { search } = useLocation();
  const toUrl = getNavLinkToUrl({ url, search, persistUTMSearchParams });

  return (
    <Stack className={styles.navLinkContainer}>
      <NavLinkWithUnderline url={toUrl} label={label} />
    </Stack>
  );
};

type NavSublinksProps = {
  subLinks: NonNullable<NavLink["subLinks"]>;
  bold?: boolean;
};

const NavSublinks = ({ subLinks, bold }: NavSublinksProps) => {
  const { pathname, search } = useLocation();
  return (
    <>
      {subLinks.map(({ label, url, persistUTMSearchParams }) => {
        const isActive = pathname.startsWith(url);

        return (
          <Box key={label} component="li" className={styles.menuItem}>
            <NavSubLinkWithUnderline
              label={label}
              url={url}
              isActive={isActive}
              persistUTMSearchParams={persistUTMSearchParams}
              search={search}
              bold={bold}
            />
          </Box>
        );
      })}
    </>
  );
};

type NavSubLinksDropdownProps = {
  subLinks: NonNullable<NavLink["subLinks"]>;
  id: string;
  isOpen: boolean;
};

const NavSubLinksDropdown = ({
  isOpen,
  subLinks,
  id,
}: NavSubLinksDropdownProps) => {
  return (
    <Paper
      className={cs(styles.menu, { [styles.menuOpen]: isOpen })}
      elevation={4}
      id={id}
    >
      <Stack direction="row" component="ul" className={styles.menuList}>
        <NavSublinks subLinks={subLinks} />
      </Stack>
    </Paper>
  );
};

type NavLinkWithSubLinksProps = BasicNavLinkProps &
  Pick<NavSubLinksDropdownProps, "subLinks">;

const NavLinkWithSubLinks = ({
  url,
  label,
  subLinks,
}: NavLinkWithSubLinksProps) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const isOpen = isHover;
  const menuId = `${label}-menu`;

  return (
    <>
      <Stack
        className={styles.navLinkContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        aria-controls={menuId}
      >
        <NavLinkWithUnderline url={url} label={label} />
        <KeyboardArrowDownIcon
          className={cs(styles.arrowIcon, { [styles.arrowIconUp]: isOpen })}
        />
        <NavSubLinksDropdown subLinks={subLinks} id={menuId} isOpen={isOpen} />
      </Stack>
    </>
  );
};

type NavSubLinkGroupsDropdownProps = {
  subLinkGroups: NonNullable<NavLink["subLinkGroups"]>;
  id: string;
  isOpen: boolean;
};

const NavSubLinkGroupsDropdown = ({
  isOpen,
  subLinkGroups,
  id,
}: NavSubLinkGroupsDropdownProps) => {
  const { pathname, search } = useLocation();

  return (
    <Paper
      className={cs(styles.menu, { [styles.menuOpen]: isOpen })}
      elevation={4}
      id={id}
    >
      <Stack direction={"row"} className={styles.sublinkGroups}>
        {subLinkGroups.map((group) => {
          const isGroupActive = !!group.url && pathname.startsWith(group.url);
          return (
            <Stack key={group.label}>
              <Box className={styles.menuItem}>
                <NavSubLinkWithUnderline
                  label={group.label}
                  url={group.url}
                  isActive={isGroupActive}
                  search={search}
                />
              </Box>
              <Stack px={"20px"}>
                <NavSublinks subLinks={group.subLinks} bold />
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Paper>
  );
};

type NavLinkWithSubLinkGroupsProps = BasicNavLinkProps &
  Pick<NavSubLinkGroupsDropdownProps, "subLinkGroups">;

const NavLinkWithSubLinkGroups = ({
  url,
  label,
  subLinkGroups,
}: NavLinkWithSubLinkGroupsProps) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const isOpen = isHover;
  const menuId = `${label}-menu`;

  return (
    <>
      <Stack
        className={styles.navLinkContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        aria-controls={menuId}
      >
        <NavLinkWithUnderline url={url} label={label} />
        <KeyboardArrowDownIcon
          className={cs(styles.arrowIcon, { [styles.arrowIconUp]: isOpen })}
        />
        <NavSubLinkGroupsDropdown
          subLinkGroups={subLinkGroups}
          id={menuId}
          isOpen={isOpen}
        />
      </Stack>
    </>
  );
};
