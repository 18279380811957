import { Variants } from "@braintrust/braintrust-ui-components";
import { navItemClicked } from "@js/apps/freelancer/actions";
import type { NAV_ITEM_IDS } from "@js/constants";
import { useAppDispatch } from "@js/hooks";
import { getLabelFromPath } from "@js/utils";

import type { SidebarNavButtonProps } from "../components/sidebar-nav";
import type { DashboardNavItem } from "../types";
import { checkIfAnySubLinkIsActive } from "../utils/common-navigation";

type UseSidebarNavButtonProps = {
  item: DashboardNavItem;
  itemVariant: SidebarNavButtonProps["itemVariant"];
  pathname: SidebarNavButtonProps["pathname"];
  expandable: SidebarNavButtonProps["expandable"];
  handleExpand: SidebarNavButtonProps["handleExpand"];
};

export const useSidebarNavButton = ({
  item,
  itemVariant,
  pathname,
  expandable,
  handleExpand,
}: UseSidebarNavButtonProps) => {
  const { label, path, icon: Icon, variant } = item;
  const isAnEmployerNavItem = itemVariant === "transparent-with-hover";
  const hasSubLinkActive = checkIfAnySubLinkIsActive(
    item.subLinks || [],
    pathname,
  );
  const btnVariant = variant ? Variants[variant] : itemVariant;
  const dispatch = useAppDispatch();

  const sidebarItemSelect = () => {
    if (expandable && handleExpand) {
      handleExpand();
    }

    const id = (
      typeof label !== "string" ? getLabelFromPath(path) : label
    ) as NAV_ITEM_IDS;

    dispatch(
      navItemClicked({
        nav_item_id: id,
        nav_item_href: path,
        context_url: pathname,
      }),
    );
  };

  return {
    label,
    path,
    Icon,
    isAnEmployerNavItem,
    btnVariant,
    hasSubLinkActive,
    sidebarItemSelect,
  };
};
