import { type Dispatch, type Middleware } from "redux";

import { handleAuthAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/auth";
import { handleBidsAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/bids";
import { handleCommonAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/common";
import { handleEmployerAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/employer";
import { handleFeedAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/feed";
import { handleFreeLancerAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/freelancer";
import { handleFreelancerProfileAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/freelancer-profile";
import { handleJobsAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/jobs";
import { handleLinkedInCsvUploadActions } from "@js/middlewares/analytics/actions-handlers/linkedin-csv-upload";
import { handleOnboardingAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/onboarding";
import { handleUniversalSearchAnalyticsActions } from "@js/middlewares/analytics/actions-handlers/universal-search";
import {
  isTrackableUserAction,
  type TrackableUserAction,
} from "@js/middlewares/analytics/types";
import type { RootState } from "@js/store";

import { handleExperimentExposureActions } from "./actions-handlers/exposure-events";

// unfortunetaly, we have a lot of actions defined manually, hence the need for string & Record<string, never>
// we can improve the types by refactoring those actions

export const analyticsMiddleware: Middleware<
  Record<string, never>,
  RootState,
  Dispatch<TrackableUserAction>
> = () => (next) => (action) => {
  if (isTrackableUserAction(action)) {
    handleAuthAnalyticsActions(action);
    handleBidsAnalyticsActions(action);
    handleCommonAnalyticsActions(action);
    handleEmployerAnalyticsActions(action);
    handleExperimentExposureActions(action);
    handleFeedAnalyticsActions(action);
    handleFreeLancerAnalyticsActions(action);
    handleFreelancerProfileAnalyticsActions(action);
    handleJobsAnalyticsActions(action);
    handleOnboardingAnalyticsActions(action);
    handleUniversalSearchAnalyticsActions(action);
    handleLinkedInCsvUploadActions(action);
  }

  return next(action);
};
