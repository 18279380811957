import { API } from "@js/api";
import type { JobOwner } from "@js/types/admin";
import type { Location, WithRecaptchaCode } from "@js/types/common";
import type {
  CurrentOfferDeposit,
  Employer,
  EmployerMyTalent,
  EmployerPublicProfile,
  EmployerTeamMemberType,
  InvitedTeamMember,
} from "@js/types/employer";
import type { PaginatedResult } from "@js/types/generic";
import { getUseQueryHookWithDefaultOptions } from "@js/utils/store";

import type { AssignOwnersFormDataTypes } from "./components/assign-owners-modal";
import { processGetMyTalentParams } from "./utils/my-talent";

export type EmployerDefaultJobOwners = {
  default_op_owners: JobOwner[];
  default_sale_owners: JobOwner[];
};

export type NewDefaultJobOwnersPayload = AssignOwnersFormDataTypes & {
  employerId: number;
};

export type CreateEmployerInvitationPayload = {
  first_name: string;
  last_name: string;
  email: string;
  organization_name: string;
  company_node: number;
};

type UpdateEmployerInfoQueryArg = {
  employerId: number;
  newData: {
    name?: string;
    organization_size?: EnumType<typeof ENUMS.OrganizationSize>;
    industry_id?: number;
    website?: string;
    location_full?: Location | null;
    show_number_of_jobs?: boolean | null;
  };
  params?: { set_account_address?: boolean };
};

export type ShareBidsWithEmployerTeamMembersParams = {
  bids: number[];
  team_members: number[];
  message?: string;
};

export type ShareBidsWithEmployerTeamMemberInvitationsParams = {
  bids: number[];
  emails: string[];
  message?: string;
};

export type ShareTalentWithEmployerTeamMembersParams = {
  talent: number;
  team_members: number[];
  message?: string;
};

export type ShareTalentWithEmployerTeamMemberInvitationsParams = {
  talent: number;
  emails: string[];
  message?: string;
};

export type GetEmployerByTalentParamsOrderBy = keyof Pick<
  EmployerMyTalent,
  | "email"
  | "country"
  | "expected_hours_per_week"
  | "job_end_date"
  | "job_owner"
  | "job_start_date"
  | "job_title"
  | "last_invoice_amount"
  | "last_invoice_date"
  | "last_name"
  | "name"
  | "rate"
  | "rate_type_label"
  | "role"
  | "state"
  | "status_label"
  | "total_invoiced"
  | "type_label"
  | "ytd_invoice_amount"
  | "bill_rate"
>;

export type GetEmployerByTalentParamsOrderDir = "asc" | "desc";

export type GetEmployerMyTalentParams = Partial<{
  search: string;
  status: Array<EnumType<typeof ENUMS.MyTalentOfferStatus>>;
  jobs: Array<number>;
  job_owners: number[];
  rate_types: Array<EnumType<typeof ENUMS.JobPaymentType>>;
  roles: Array<number>;
  page: number;
  order_by: GetEmployerByTalentParamsOrderBy;
  order_dir: GetEmployerByTalentParamsOrderDir;
}>;

export type GetEmployerMyTalentResponse = {
  has_byot_offers: boolean;
} & PaginatedResult<EmployerMyTalent>;

export type GetEmployerMyTalentStatsResponse = {
  active_talent: number;
  hired_bids_expiring_within_30_days: number;
  open_roles: number;
  total_hired_talent_count: number;
};
export type EmployerSearchMinimalResult = {
  id: number;
  name: string;
};

export type EmployerSearchResult = {
  name_color: string | null;
  link: string;
  full_link: string;
  has_logo_set: boolean;
  logo_background_color: string | null;
  total_jobs: number | null;
  logo: string;
  logo_thumbnail: string;
} & EmployerSearchMinimalResult;

export type EmployerInvoicesTalentSearchResult = {
  id: number;
  talent_name: string;
};

export type EmployerInvoicesTalentSearchParams = {
  search?: string;
};

export type GetEmployerCurrentOfferDepositResponse = {
  current_offer_deposit: CurrentOfferDeposit | null;
};

const assignsJobOwnersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployerDefaultJobOwners: build.query<EmployerDefaultJobOwners, number>({
      query: (employerId) => ({
        url: `/manage_employers/${employerId}/get_default_owners/`,
        method: "GET",
      }),
    }),
    changeDefaultOwners: build.mutation<void, NewDefaultJobOwnersPayload>({
      query: ({ employerId, ...ownersData }) => ({
        url: `/manage_employers/${employerId}/change_job_owners/`,
        method: "POST",
        data: { ...ownersData },
      }),
    }),
    createEmployerInvitation: build.mutation<
      void,
      CreateEmployerInvitationPayload
    >({
      query: ({ ...employerData }) => ({
        url: "/employers/",
        method: "POST",
        data: { ...employerData },
      }),
    }),
  }),
});

export const employerApi = API.injectEndpoints({
  endpoints: (build) => ({
    getManagedEmployerById: build.query<Employer, number>({
      query: (employerId) => ({
        url: `/manage_employers/${employerId}/`,
        method: "GET",
      }),
      providesTags: ["ManagedEmployers"],
    }),
    getEmployerPublicProfile: build.query<EmployerPublicProfile, number>({
      query: (employerId) => ({
        url: `/employers/${employerId}/`,
        method: "GET",
      }),
      providesTags: (_res, _err, arg) => [
        { type: "EmployerPublicProfile", id: arg },
      ],
    }),
    getEmployersSearch: build.query<
      EmployerSearchMinimalResult[],
      Record<string, string>
    >({
      query: (params) => ({
        url: `/employer_search/return_id_and_name_only/`,
        method: "GET",
        params: {
          limit: 100,
          ...params,
        },
      }),
    }),
    getEmployerSearchItem: build.query<EmployerSearchResult, number>({
      query: (id) => ({
        url: `/employer_search/${id}/`,
        method: "GET",
      }),
    }),
    getEmployerInvoicesTalent: build.query<
      EmployerInvoicesTalentSearchResult[],
      EmployerInvoicesTalentSearchParams
    >({
      query: (params) => ({
        url: `/employer_invoices/talents/`,
        method: "GET",
        params,
      }),
    }),
    updateEmployerInfo: build.mutation<Employer, UpdateEmployerInfoQueryArg>({
      query: ({ employerId, newData, params }) => {
        return {
          url: `/manage_employers/${employerId}/`,
          method: "PATCH",
          data: newData,
          params: params,
        };
      },
      invalidatesTags: ["ManagedEmployers"],
    }),

    getHasEmployerExceededDailyJobPostingLimit: build.query<
      { has_exceeded_daily_job_posting_limit: boolean },
      { employerId: number }
    >({
      query: ({ employerId }) => ({
        url: `/manage_employers/${employerId}/has_exceeded_daily_job_posting_limit/`,
        method: "GET",
      }),
      providesTags: ["EmployerHasExceededDailyJobsLimit"],
    }),

    getEmployerCurrentOfferDepositById: build.query<
      GetEmployerCurrentOfferDepositResponse,
      number
    >({
      query: (employerId) => ({
        url: `/manage_employers/${employerId}/current_offer_deposit/`,
        method: "GET",
      }),
      providesTags: ["EmployerCurrentOfferDeposit"],
    }),
  }),
});

const employerTalentApi = API.injectEndpoints({
  endpoints: (build) => {
    return {
      getEmployerMyTalent: build.query<
        GetEmployerMyTalentResponse,
        GetEmployerMyTalentParams | void
      >({
        query: (params) => {
          const processedParams = processGetMyTalentParams(params);

          return {
            url: "/employer_my_talent/",
            method: "GET",
            params: processedParams,
          };
        },
        providesTags: ["EmployerMyTalent"],
        keepUnusedDataFor: 10,
      }),
      getEmployerMyTalentStats: build.query<
        GetEmployerMyTalentStatsResponse,
        void
      >({
        query: () => ({
          url: "/employer_my_talent/stats/",
          method: "GET",
        }),
        providesTags: ["EmployerMyTalentStats"],
      }),
    };
  },
});

export const employerTeamMembersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployerTeamMembers: build.query<EmployerTeamMemberType[], void>({
      query: () => ({
        url: `/employer_team_members/`,
        method: "GET",
      }),
      keepUnusedDataFor: 10000000,
      providesTags: [{ type: "EmployerTeamMembers", id: "LIST" }],
    }),

    getInvitationsToManageEmployer: build.query<InvitedTeamMember[], void>({
      query: () => ({
        url: "/invitations_to_manage_employer/",
        method: "GET",
      }),
      providesTags: ["InvitationsToManageEmployer"],
    }),

    resendEmployerTeamMemberInvitation: build.mutation<
      void,
      WithRecaptchaCode<{ id: number }>
    >({
      query: ({ id, ...data }) => ({
        url: `/invitations_to_manage_employer/${id}/resend/`,
        method: "POST",
        data,
      }),
    }),

    deleteEmployerTeamMemberInvitation: build.mutation<void, number>({
      query: (id) => ({
        url: `/invitations_to_manage_employer/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["InvitationsToManageEmployer"],
    }),

    shareBidsWithEmployerTeamMembers: build.mutation<
      void,
      ShareBidsWithEmployerTeamMembersParams
    >({
      query: (data) => ({
        url: `/employer_share_bids/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (_error) {
          dispatch(employerApi.util.invalidateTags(["EmployerTeamMembers"]));
        }
      },
    }),

    shareBidsWithEmployerTeamMemberInvitations: build.mutation<
      void,
      ShareBidsWithEmployerTeamMemberInvitationsParams
    >({
      query: (data) => ({
        url: `/employer_share_bids_invitations/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (_error) {
          dispatch(employerApi.util.invalidateTags(["EmployerTeamMembers"]));
        }
      },
    }),

    shareTalentWithEmployerTeamMembers: build.mutation<
      void,
      ShareTalentWithEmployerTeamMembersParams
    >({
      query: (data) => ({
        url: `/employer_share_talent/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (_error) {
          dispatch(employerApi.util.invalidateTags(["EmployerTeamMembers"]));
        }
      },
    }),

    shareTalentWithEmployerTeamMemberInvitations: build.mutation<
      void,
      ShareTalentWithEmployerTeamMemberInvitationsParams
    >({
      query: (data) => ({
        url: `/employer_share_talent_invitations/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (_error) {
          dispatch(employerApi.util.invalidateTags(["EmployerTeamMembers"]));
        }
      },
    }),

    addEmployerTeamMember: build.mutation<void, { email: string }>({
      query: (data) => ({
        url: `/manage_employers/invite_new_user/`,
        method: "POST",
        data,
      }),
      invalidatesTags: (_res, err) =>
        err ? [] : ["InvitationsToManageEmployer", "EmployerTeamMembers"],
    }),

    deleteEmployerTeamMember: build.mutation<void, number>({
      query: (id) => ({
        url: `/manage_employers/remove_team_member/`,
        method: "POST",
        data: { user: id },
      }),
      invalidatesTags: [
        "EmployerTeamMembers",
        "JobSubscribers",
        "ManagedEmployers",
      ],
    }),

    preventEmployerTeamMemberInvoiceView: build.mutation<void, number>({
      query: (id) => ({
        url: `/manage_employers/prevent_from_viewing_invoices/`,
        method: "POST",
        data: { user: id },
      }),
      invalidatesTags: ["EmployerTeamMembers", "ManagedEmployers"],
    }),

    allowEmployerTeamMemberInvoiceView: build.mutation<void, number>({
      query: (id) => ({
        url: `/manage_employers/allow_to_view_invoices/`,
        method: "POST",
        data: { user: id },
      }),
      invalidatesTags: ["EmployerTeamMembers", "ManagedEmployers"],
    }),
  }),
});

export const {
  useLazyGetEmployerDefaultJobOwnersQuery,
  useGetEmployerDefaultJobOwnersQuery,
  useChangeDefaultOwnersMutation,
  useCreateEmployerInvitationMutation,
} = assignsJobOwnersApi;

export const {
  useUpdateEmployerInfoMutation,
  useGetEmployerPublicProfileQuery,
  useGetHasEmployerExceededDailyJobPostingLimitQuery,
  useLazyGetHasEmployerExceededDailyJobPostingLimitQuery,
  useGetEmployersSearchQuery,
  useGetEmployerSearchItemQuery,
  useGetEmployerInvoicesTalentQuery,
} = employerApi;

export const {
  useGetEmployerTeamMembersQuery,
  useShareBidsWithEmployerTeamMemberInvitationsMutation,
  useShareBidsWithEmployerTeamMembersMutation,
  useShareTalentWithEmployerTeamMemberInvitationsMutation,
  useShareTalentWithEmployerTeamMembersMutation,
  useGetInvitationsToManageEmployerQuery,
  useAddEmployerTeamMemberMutation,
  usePreventEmployerTeamMemberInvoiceViewMutation,
  useAllowEmployerTeamMemberInvoiceViewMutation,
  useDeleteEmployerTeamMemberMutation,
  useDeleteEmployerTeamMemberInvitationMutation,
  useResendEmployerTeamMemberInvitationMutation,
} = employerTeamMembersApi;

export const { useGetEmployerMyTalentQuery, useGetEmployerMyTalentStatsQuery } =
  employerTalentApi;

/** @description In case you would need to get specific employer. Probably you can use `useGetEmployerCurrentOfferDepositQuery` from `/apps/employer/hooks` instead. */
export const useGetEmployerCurrentOfferDepositByIdQuery =
  getUseQueryHookWithDefaultOptions(
    employerApi.useGetEmployerCurrentOfferDepositByIdQuery,
    { refetchOnMountOrArgChange: false },
  );

/** @description In case you would need to get specific employer. Probably you can use `useGetManagedEmployerQuery` from `/apps/employer/hooks` instead. */
export const useGetManagedEmployerByIdQuery = getUseQueryHookWithDefaultOptions(
  employerApi.useGetManagedEmployerByIdQuery,
  { refetchOnMountOrArgChange: false },
);
