import type { MouseEvent } from "react";

import type { ButtonProps } from "@braintrust/braintrust-ui-components";
import { Button } from "@braintrust/braintrust-ui-components";
import { BoltIcon } from "@braintrust/braintrust-ui-components/Icons";
import { useAccountType } from "@js/apps/common/hooks";
import { boostButtonClicked } from "@js/apps/jobs/actions";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";

import { openBoostModal } from "../job-item/components/open-boost-modal/open-boost-modal";

import style from "./style.module.scss";

export type BoostJobButtonProps = {
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus> | undefined;
  fullWidth?: boolean;
  boostCredit?: number;
  bidId?: number;
  isOpen?: boolean; // is job open
} & Pick<
  ButtonProps,
  "variant" | "sx" | "style" | "className" | "color" | "size" | "shape"
>;

const FREELANCER_STATUSES_TO_SHOW_BOOST_BUTTON: EnumType<
  typeof ENUMS.JobFreelancerStatus
>[] = [
  ENUMS.JobFreelancerStatus.APPLICATION_SENT,
  ENUMS.JobFreelancerStatus.IN_REVIEW,
];

export const BoostJobButton = ({
  fullWidth,
  boostCredit,
  bidId,
  isOpen,
  statusForFreelancer,
  ...props
}: BoostJobButtonProps) => {
  const dispatch = useAppDispatch();
  const { isFreelancerApproved } = useAccountType();

  if (
    !isFreelancerApproved ||
    !statusForFreelancer ||
    !FREELANCER_STATUSES_TO_SHOW_BOOST_BUTTON.includes(statusForFreelancer) ||
    !boostCredit ||
    !isOpen
  ) {
    return null;
  }

  const openBoostConfirmationModal = () => {
    openBoostModal({ boostCredit, bidId: bidId ?? -1 });
    dispatch(boostButtonClicked());
  };

  const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
    if (e.button === 0) return;
  };

  return (
    <Button
      {...props}
      fullWidth={fullWidth}
      variant={"positive"}
      onClick={openBoostConfirmationModal}
      className={style.boostButtonJob}
      onMouseDown={handleMouseDown}
      rel="noopener noreferrer"
      target="_blank"
      RouterLink={RouterLink}
      startIcon={<BoltIcon sx={{ fill: "currentcolor !important" }} />}
    >
      Boost
    </Button>
  );
};
