import { useCallback, useMemo } from "react";

import { useInitStripeCheckoutInsightsSessionMutation } from "@js/apps/auth/api";

import { useProductCheckoutModalContext } from "../../product-checkout-modal-context";

export const useStripeCheckoutForm = () => {
  const { upgradeCost, setModeOfPayment } = useProductCheckoutModalContext();
  const [initStripeCheckoutUpgradeSession] =
    useInitStripeCheckoutInsightsSessionMutation();

  const fetchClientSecret = useCallback(async () => {
    try {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete("purchase_insights");
      const formattedUrl = currentUrl.toString();
      const separator = formattedUrl.includes("?") ? "&" : "?";

      const response = await initStripeCheckoutUpgradeSession({
        return_url: `${currentUrl}${separator}success_fiat_payment=insights`,
      }).unwrap();

      return response.clientSecret;
    } catch {
      return "";
    }
  }, [initStripeCheckoutUpgradeSession]);

  const stripeOptions = useMemo(
    () => ({ fetchClientSecret }),
    [fetchClientSecret],
  );

  const onPayViaBTRST = useCallback(
    () => setModeOfPayment("btrst"),
    [setModeOfPayment],
  );

  return { onPayViaBTRST, stripeOptions, upgradeCost };
};
