function incrementValue(numerator: number) {
  if (numerator === 0) {
    return "0/3";
  }

  const denominator = Math.ceil(numerator / 3) * 3;
  return `${numerator}/${denominator}`;
}

export const getFieldDescription = (
  boost: number,
  isEditPage: boolean | undefined,
  is_boosted: boolean | undefined,
) => {
  if (isEditPage && is_boosted) {
    return "Already boosted.";
  }

  if (!boost) {
    return "";
  }

  return `${incrementValue(boost)} boosts available`;
};
