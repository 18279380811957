import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useNavigate } from "@js/hooks/navigate";

export const useGoBackHistory = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const prevPath = location.state?.prevPath;
  const backParam = searchParams.get("back");

  const enhancedNavigate = React.useCallback(
    (defaultUrl: string) => {
      if (backParam) {
        navigate(backParam);
        return;
      }

      if (prevPath) {
        navigate(-1);
        return;
      }

      navigate(defaultUrl);
    },
    [prevPath, navigate, backParam],
  );

  return enhancedNavigate;
};
