import Decimal from "decimal.js";

import { useUserWalletBalance } from "@js/apps/common/hooks/use-user-wallet-balance";

import { INSIGHT_BTRST_COST } from "./constants";

export const usePreCheckBalanceUpgrade = () => {
  const { data: balance } = useUserWalletBalance();

  const upgradeCost = new Decimal(INSIGHT_BTRST_COST);
  const parsedBalance = new Decimal(balance?.available_tokens ?? "0");
  const requiredTotalBtrst = upgradeCost.minus(parsedBalance);
  const isSufficientBtrst = requiredTotalBtrst.lessThanOrEqualTo(0);
  const formattedRequiredTotalBtrst = requiredTotalBtrst
    .toDecimalPlaces(2, Decimal.ROUND_UP)
    .toNumber();

  return {
    formattedRequiredTotalBtrst,
    isSufficientBtrst,
    requiredTotalBtrstRaw: requiredTotalBtrst.toString(),
    upgradeCost: INSIGHT_BTRST_COST,
  };
};
