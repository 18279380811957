import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@braintrust/braintrust-ui-components";
import {
  ADD_ANOTHER_ITEM,
  INITIAL_WORK_HISTORY_VALUES,
  MODULE_EDIT_MODAL_PROPERTIES,
} from "@js/apps/freelancer/constants";
import { WorkHistoryForm } from "@js/apps/freelancer/forms/work-history-form";
import { useWorkExperience } from "@js/apps/freelancer/hooks/edit-work-history";
import { ModalInstance } from "@js/components/modal";
import type { FreelancerWorkExperience } from "@js/types/freelancer";

import { WorkHistoryCard } from "../card";

import {
  openEditWorkHistoryModal,
  openRemoveWorkHistoryModal,
} from "./edit-and-remove-work-history";

export const openAddWorkHistoryModal = () => {
  return ModalInstance.open({
    children: <AddWorkHistoryModal />,
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });
};

type AddWorkHistoryModalProps = {
  persistModalInstance?: boolean;
  saveButtonText?: string;
  onFinished?: () => void;
};

export const AddWorkHistoryModal = ({
  persistModalInstance = false,
  saveButtonText,
  onFinished,
}: AddWorkHistoryModalProps) => {
  const {
    freelancerWork,
    onSubmit,
    form,
    handleSetValues,
    setSubmitType,
    removeWorkHistory,
    submitType,
    isSaving,
  } = useWorkExperience({ persistModalInstance, onFinished });

  const [showForm, setShowForm] = useState<boolean>(!freelancerWork?.length);

  useEffect(() => {
    if (submitType !== ADD_ANOTHER_ITEM) {
      setShowForm(!freelancerWork?.length);
    }
  }, [freelancerWork, submitType]);

  return (
    <Box>
      <Typography
        variant="title"
        component="h2"
        size="small"
        fontWeight={400}
        mb={4}
      >
        💼 Work history
      </Typography>
      {!!freelancerWork?.length && (
        <Box
          mb={3}
          display="grid"
          gap={4}
          borderBottom="1px solid var(--grey-4)"
        >
          {freelancerWork.map((work: FreelancerWorkExperience) => {
            return (
              <WorkHistoryCard
                editMode
                key={work.id}
                work={work}
                onEdit={() => {
                  handleSetValues(work);
                  openEditWorkHistoryModal({
                    onSubmit,
                    form,
                  });
                  setShowForm(false);
                }}
                onDelete={() =>
                  openRemoveWorkHistoryModal({
                    title: work.title,
                    onDelete: () => removeWorkHistory(work),
                  })
                }
              />
            );
          })}
        </Box>
      )}
      {!showForm ? (
        <Button
          variant="tertiary"
          type="submit"
          onClick={() => {
            form.reset(INITIAL_WORK_HISTORY_VALUES);
            setShowForm(true);
          }}
        >
          Add a job
        </Button>
      ) : (
        <>
          <Typography variant="paragraph" component="p" size="large" mb={3}>
            Add a job
          </Typography>
          <WorkHistoryForm
            onSubmit={onSubmit}
            setSubmitType={setSubmitType}
            saveButtonText={saveButtonText}
            form={form}
            isSaving={isSaving}
          />
        </>
      )}
    </Box>
  );
};
