import { useCallback } from "react";

import { Button } from "@braintrust/braintrust-ui-components";
import { setUpNewJobClicked } from "@js/apps/jobs/actions";
import {
  JOB_FORM_SUBMIT_TYPE,
  useUpdateSubmitType,
} from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useGetJobFlowEntry } from "@js/apps/jobs/hooks/use-get-job-flow-entry";
import { SubscribeSyncErrors } from "@js/forms/components";
import { useAppDispatch, useQueryParams } from "@js/hooks";

export const SetupNewJobButton = () => {
  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const updateSubmitType = useUpdateSubmitType();
  const { flow_entry } = useGetJobFlowEntry();

  const hasDuplicatedJobPost = Boolean(query.copy_job_id);

  const getSubmitType = useCallback(() => {
    if (hasDuplicatedJobPost) {
      return JOB_FORM_SUBMIT_TYPE.job_copy_draft_set_up;
    }

    return JOB_FORM_SUBMIT_TYPE.draft_set_up;
  }, [hasDuplicatedJobPost]);

  const handleButtonClick = () => {
    dispatch(setUpNewJobClicked({ flow_entry }));
    updateSubmitType(getSubmitType());
  };

  return (
    <SubscribeSyncErrors
      fields={[
        "role",
        "other_role_name",
        "title",
        "openings_number",
        "job_type",
      ]}
    >
      {({ isError }) => {
        return (
          <Button
            onClick={handleButtonClick}
            variant="positive"
            disabled={isError}
            shape="squared"
            type="submit"
          >
            Set up new job
          </Button>
        );
      }}
    </SubscribeSyncErrors>
  );
};
