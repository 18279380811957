import React from "react";
import cs from "classnames";

import type { IconButtonProps } from "@braintrust/braintrust-ui-components";
import { Box, Button, IconButton } from "@braintrust/braintrust-ui-components";
import { ShareIcon } from "@braintrust/braintrust-ui-components/Icons";

import type { ReferJobModalContentProps } from "../refer-job-modal-content";
import { openReferJobModal } from "../refer-to-job/button";

import styles from "./styles.module.scss";

export type ShareJobButtonProps = {
  job: ReferJobModalContentProps["job"];
  withText?: boolean;
  iconColor?: string;
} & Partial<IconButtonProps>;

export const ShareJobButton = ({
  job,
  withText,
  iconColor = "var(--black)",
  ...props
}: ShareJobButtonProps) => {
  if (withText) {
    return (
      <Button
        variant="secondary"
        onClick={() => openReferJobModal({ job })}
        inverse
        {...props}
      >
        Share Job
        <Box display="flex" ml={1} alignItems="center">
          <ShareIcon height={22} style={{ stroke: iconColor }} />
        </Box>
      </Button>
    );
  }

  return (
    <IconButton
      variant="transparent"
      size="medium"
      onClick={() => openReferJobModal({ job })}
      aria-label="Share job"
      {...props}
      className={cs(styles.shareIconButton, props?.className)}
    >
      <ShareIcon style={{ stroke: iconColor }} />
    </IconButton>
  );
};
