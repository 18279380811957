import React, { Suspense, useMemo } from "react";

import { Loader } from "@braintrust/braintrust-ui-components";
import { CloseIcon } from "@braintrust/braintrust-ui-components/Icons";
import { Wizard } from "@js/components/wizard";
import type { CrumbProps } from "@js/components/wizard/breadcrumbs";

import { useEmployerId } from "../hooks";

type EditWizardProps = {
  pageTitle: string;
  continueButtonColor?: string;
  onContinue?: () => void;
  continueButtonText?: string;
  className?: string;
  children: React.ReactNode;
  disableSubmit?: boolean;
};

export const EmployerEditWizard = ({
  continueButtonText,
  children,
  ...props
}: EditWizardProps) => {
  const employerId = useEmployerId();
  const backUrl = `/employers/${employerId}/`;

  const wizardItems: CrumbProps[] = useMemo(() => {
    const items = [
      {
        title: "Personal Info",
        path: "/employer/edit/personal_info/",
      },
      {
        title: "Organization Info",
        path: "/employer/edit/organization_info/",
      },
      {
        title: "Team Members",
        path: "/employer/edit/team_members/",
      },
    ];
    return items;
  }, []);

  return (
    <Wizard
      crumbs={wizardItems}
      customBackURL={backUrl}
      customBackIcon={CloseIcon}
      oneActive
      hideSeparator
      continueButtonText={continueButtonText || "Save"}
      {...props}
    >
      <Suspense fallback={<Loader centered />}>{children}</Suspense>
    </Wizard>
  );
};
