import { Box, Stack, Typography } from "@braintrust/braintrust-ui-components";

export const DescriptionSection = () => {
  return (
    <Stack flex={1} py={4} px={4} gap={3}>
      <Box display="flex" gap={3} flexDirection={{ xs: "column", md: "row" }}>
        <Box flex={0.5}>
          <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
            What is Application Insights?
          </Typography>
          <Box>
            <Typography component="p" variant="paragraph" size="small">
              Application Insights provides personalized feedback for each job,
              using Braintrust AI.
            </Typography>
            <Typography
              component="div"
              variant="paragraph"
              size="small"
              sx={{ "& ul": { margin: 0, paddingInlineStart: "20px" } }}
            >
              <ul>
                <li>Identify jobs where you are a great match</li>
                <li>Compare your standing among others</li>
                <li>Discover strengths and areas to improve</li>
              </ul>
            </Typography>
          </Box>
        </Box>
        <Box flex={0.5}>
          <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
            How much is it?
          </Typography>
          <Typography component="p" variant="paragraph" size="small">
            For 20 BTRST tokens or $20.00 USD, unlock 30 days of Application
            Insights. No recurring charges. Renew at your convenience.
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
          What type of Insights can I expect?
        </Typography>
        <Typography component="p" variant="paragraph" size="small">
          For every job, you can uncover critical insights that influence your
          chances of being selected, including:
        </Typography>
        <Typography
          component="div"
          variant="paragraph"
          size="small"
          sx={{ "& ul": { margin: 0, paddingInlineStart: "10px" } }}
        >
          <ul>
            <li>
              <b>Skill alignment:</b>{" "}
              <span>
                How well do your skills match the job's key requirements?
              </span>
            </li>
            <li>
              <b>Competition overview:</b>{" "}
              <span>
                The number of applicants for this position and your ranking
                among them if you've applied.
              </span>
            </li>
            <li>
              <b>Strengths:</b>{" "}
              <span>Positive traits that distinguish you from others.</span>
            </li>
            <li>
              <b>Improvement areas:</b>{" "}
              <span>
                Aspects that could lower your likelihood of securing the job.
              </span>
            </li>
          </ul>
        </Typography>
        <br />
        <Typography component="div" variant="paragraph" size="small">
          Remember that each job is unique, so we present only the most relevant
          insights for you.
        </Typography>
      </Box>
      <Box>
        <Typography component="p" variant="label" fontWeight={500} mb={1.5}>
          What payments methods do you accept?
        </Typography>
        <Typography component="p" variant="paragraph" size="small">
          You can pay with your preferred credit card, or for a more seamless
          experience, use Google Pay or Apple Pay. If you prefer a digital
          wallet, Link is also accepted.
        </Typography>
      </Box>
    </Stack>
  );
};
