import { Box, Typography } from "@braintrust/braintrust-ui-components";

export const HeaderSection = () => {
  return (
    <Box
      paddingTop={{ xs: 5, md: 6 }}
      position="relative"
      bgcolor="var(--soft-green)"
    >
      <Box
        position="absolute"
        top={0}
        left={{ xs: 0, md: -37 }}
        sx={{ "& img": { width: { xs: "390px", md: "372px" } } }}
      >
        <img
          width="auto"
          height="auto"
          src={`${SETTINGS.STATIC_URL}jobs/purchase-insights-bg.svg`}
          alt=""
        />
      </Box>
      <Box
        zIndex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 0.5, md: 3 }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <img
          width="auto"
          height="29px"
          src={`${SETTINGS.STATIC_URL}logo/logo.svg`}
          alt="logo"
        />
        <Box
          display={{ xs: "none", md: "block" }}
          height="32.5px"
          border="0.5px solid var(--black)"
        />
        <Typography
          component="h1"
          variant="title"
          size="small"
          fontWeight={400}
          textTransform="capitalize"
        >
          Application Insights
        </Typography>
      </Box>
      <Box
        zIndex={1}
        position="relative"
        mt={2}
        mx="auto"
        px={{ xs: 1, md: 0 }}
        maxWidth="579px"
        gap={{ xs: 1, md: 0 }}
      >
        <Typography
          component="p"
          variant="label"
          fontWeight={500}
          textAlign="center"
        >
          Identify promising opportunities and refine your job search with
          personalized feedback.
        </Typography>
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          height: "auto",
          width: "auto",
          img: { height: "auto" },
        }}
      >
        <img
          width="693px"
          height="292px"
          src={`${SETTINGS.STATIC_URL}jobs/purchase-insights-description.png`}
          alt="Image of a conversation"
        />
      </Box>
    </Box>
  );
};
